import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import noti from "../../assets/notifications.png";

const emails = [
  "admin",
  "r&d head",
  "delivery experts",
  "course coordinator",
  "product head",
  "mai gq experts",
  "all",
];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, changeevent } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    // alert(value);
    changeevent(value);
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" style={{ padding: "10px 12px" }}>
        Choose Filter
      </DialogTitle>
      <List>
        {emails.map((email) => (
          <ListItem
            button
            onClick={() => handleListItemClick(email)}
            key={email}
          >
            <ListItemText
              primary={email.charAt(0).toUpperCase() + email.slice(1)}
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                marginLeft: "12px",
                lineHeight: "14px",
                display: "flex",
                alignItems: "center",
                color: "#425466",
              }}
            />
          </ListItem>
        ))}
        {/* 
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick("addAccount")}
        >
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Add account" />
        </ListItem> */}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function Filterx({ changeevent }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {/* <button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open simple dialog
      </button> */}
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{
          background: "#FAFCFE",
          boxShadow: "0px 6px 58px rgb(196 203 214 / 10%)",
          padding: "10px 0px",
          marginRight: "15px",
          height: "43px",
        }}
        onClick={handleClickOpen}
      >
        <img src={noti} style={{ width: "40px" }} />
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        changeevent={changeevent}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
