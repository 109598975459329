import React, { useEffect, useState } from "react";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
// import MapsTable from "./MapsTable";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InputBase, fade } from "@material-ui/core";
import ViewTagsTrailTable from "./TagsTrailTable";
import { baseApi } from "../../Utils/config";

const useStyles = makeStyles((theme) => ({
  // container: {
  //   margin: "1% 2%",
  // },
  title: {
    // float: "left",
    marginTop: "10px",
    // marginLeft: "4%",
    marginBottom: "0",
    fontWeight: "500",
    fontSize: "24px",
    color: "#33343D",
  },
  desc: {
    // float: "left",
    marginTop: "10px",
    // marginLeft: "4%",
    marginBottom: "0",
    fontWeight: "500",
    fontSize: "14px",
    color: "#565656",
  },
  button: {
    marginLeft: "39% !important",
    marginTop: "-3%",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",
    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: "fit-content",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(4),
      // width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  select: {
    margin: theme.spacing(1.5),
    minWidth: "100px",
  },
}));

var sortobj = {};
var payload = [];
var pageval = 0;
var payload2 = [];
var start = 0;
var lastlimit = 100;
var newlimit = 1;
var nextlimit = 1;
export default function TagsTrail(props) {
  // console.log(props.location.state.id);
  var ID = props.location.state.id.toString();
  var record = props.location.state.record;
  const classes = useStyles();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const [] = useState("");
  const [mapstable, setMapsTable] = useState();
  const [originalMapsTable, setOriginalMapsTable] = useState();
  const history = useHistory();

  const [pagv, setPagv] = React.useState(0);

  const [] = useState({});
  const [filterData] = useState([
    {
      column: "",
      condition: "Where",
      type: "",
      value: "",
    },
  ]);
  const [] = useState(false);
  const [] = useState([]);
  const [anchorEl] = useState(null);
  const [anchorFilter] = useState(null);
  const [filteritem] = useState([]);
  const [] = React.useState(0);
  let [sortF] = React.useState(0);
  let [bookmarkstate] = React.useState(0);

  //   payload = {
  //     tableId: ID,
  //     start: 0,
  //     limit: 500,
  //   };
  if (record) {
    payload = {
      contentTable: ID,
      start: start,
      limit: lastlimit,
      record: record,
    };
  } else {
    payload = {
      contentTable: ID,
      start: start,
      limit: lastlimit,
    };
  }
  const fetchData = (payload) => {
    const res = axios(
      {
        method: "post",
        url: apiBaseUrl + "v1/content/trail",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          //console.log(response.data.data);
          setMapsTable(response.data.data);
          setOriginalMapsTable(response.data.data);
        } else if (response.data.statusCode === 400) {
          console.log("Username password do not match");
          alert("username password do not match");
        } else {
          alert("Wrong Credentials!! , Please try again");
        }
      })
      .catch((error) => {
        console.log("login error message", error.message);
        //alert("User does not exist");
      });
  };

  useEffect(() => {
    if (pageval > 0) {
      fetchData(payload2);
    } else {
      fetchData(payload);
    }
  }, [pageval, filterData, sortF, bookmarkstate]);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleFilterClick = (event) => {
  //     setAnchorFilter(event.currentTarget);
  //   };

  //   const handlePopover = () => {
  //     setAnchorEl(null);
  //     setAnchorFilter(null);
  //     if (sorting.orderby === "A-Z") {
  //       sortorder = 1;
  //     } else if (sorting.orderby === "Z-A") {
  //       sortorder = -1;
  //     } else {
  //       sortorder = 0;
  //     }

  //     if (sorting.sortby != undefined && sortorder != 0) {
  //       if (pageval > 0) {
  //         Object.assign(
  //           sortobj,
  //           {
  //             sorting: {
  //               [sorting.sortby]: sortorder,
  //             },
  //           },
  //           payload2
  //         );

  //         history.push({
  //           pathname: "/mapstableitem",
  //           state: {
  //             id: ID,
  //             payload2: sortobj,
  //             tablename: props.location.state.tablename,
  //             tabledesc: props.location.state.tabledesc,
  //             entries: props.location.state.entries,
  //           },
  //         });
  //       } else {
  //         Object.assign(
  //           sortobj,
  //           {
  //             sorting: {
  //               [sorting.sortby]: sortorder,
  //             },
  //           },
  //           payload
  //         );

  //         history.push({
  //           pathname: "/mapstableitem",
  //           state: {
  //             id: ID,
  //             payload: sortobj,
  //             tablename: props.location.state.tablename,
  //             tabledesc: props.location.state.tabledesc,
  //             entries: props.location.state.entries,
  //           },
  //         });
  //       }
  //       setSortF(sortF + 2);
  //     }
  //   };

  //   const handleClosePopover = () => {
  //     setAnchorEl(null);
  //     setAnchorFilter(null);
  //   };

  //   const handleSortItems = (sort, name) => {
  //     setSorting(
  //       Object.assign(sorting, {
  //         [sort]: name,
  //       })
  //     );
  //     setPersonName([...personName, name]);
  //   };

  //   const handleFilterMenu = () => {
  //     setFilterMenu(!filterMenu);
  //   };

  const openPopover = Boolean(anchorEl);
  const openPopFilter = Boolean(anchorFilter);

  if (mapstable) {
  }
  //   const handleFilterData = (event, index) => {
  //     let data = [...filterData];
  //     data[index][event.target.name] = event.target.value;
  //     setFilterData((prevState) => {
  //       return data;
  //     });
  //   };

  //   const handleAddFilter = () => {
  //     setFilterData((prevState) => {
  //       return [
  //         ...prevState,
  //         {
  //           column: "",
  //           condition: prevState.length > 1 ? prevState[1]["condition"] : "",
  //           type: "",
  //           value: "",
  //         },
  //       ];
  //     });
  //   };
  //   const handleFilterApply = () => {
  //     let data = {};
  //     if (pageval > 0) {
  //       data = Object.assign(
  //         {},
  //         {
  //           filter: filterData,
  //         },
  //         payload2
  //       );
  //     } else {
  //       data = Object.assign(
  //         {},
  //         {
  //           filter: filterData,
  //         },
  //         payload
  //       );
  //     }

  //     Object.assign(sortobj, data);
  //     setSortF(sortF + 2);
  //     handleClosePopover();
  //   };
  //   const handleClearFilter = () => {
  //     setFilterData([
  //       {
  //         column: "",
  //         condition: "Where",
  //         type: "",
  //         value: "",
  //       },
  //     ]);
  //     setSortF(sortF + 2);
  //   };

  const handlepagechange = (value) => {
    pageval = value;
    newlimit = lastlimit * pageval;
    nextlimit = lastlimit + newlimit;

    // if()
    // payload2 = {
    //   tableId: ID,
    //   start: newlimit,
    //   limit: lastlimit,
    // };

    // if (pageval > 0) {
    //   history.push({
    //     pathname: "/tags-trail",
    //     state: {
    //       id: ID,
    //       tablename: props.tablename,
    //       tabledesc: props.tabledesc,
    //       record: row._id,
    //     },
    //   });
    // } else {
    //   history.push({
    //     pathname: "/tags-trail",
    //     state: {
    //       id: ID,
    //       tablename: props.tablename,
    //       tabledesc: props.tabledesc,
    //       record: row._id,
    //     },
    //   });
    // }
    setPagv(pageval);
  };

  //   const handleFilterClear = (index) => {
  //     let data = [...filterData];
  //     let cleared = data.splice(index, 1);
  //     if (cleared) {
  //       setFilterData(data);
  //     }
  //   };
  //   const handleDownloadCsv = () => {
  //     let payload = {
  //       tableId: ID,
  //       start: start,
  //       limit: 100,
  //       // filter: filterData,
  //       // sorting: sorting,
  //       download: true,
  //     };
  //     axios({
  //       method: "post",
  //       url: apiBaseUrl + "v1/content/list",
  //       data: {
  //         download: true,
  //         limit: 100,
  //         start: 0,
  //         tableId: "5ef0931b8206097c270b25b3",
  //       },
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-auth-token": user.apiToken,
  //       },
  //     })
  //       .then((response) => {
  //         console.log(response);
  //         // if (response.data.statusCode === 201) {
  //         //   console.log(response);
  //         // } else if (response.data.statusCode === 400) {
  //         //   console.log("Username password do not match");
  //         //   alert("username password do not match");
  //         // } else {
  //         //   alert("Wrong Credentials!! , Please try again");
  //         // }
  //       })
  //       .catch((error) => {
  //         console.log("login error message", error.message);
  //         //alert("User does not exist");
  //       });
  //   };

  //   const handleBookmark = (event) => {
  //     var bookmarkpayloadtrue = {
  //       contentTable: ID,
  //       isBookmark: true,
  //     };
  //     var bookmarkpayloadfalse = {
  //       contentTable: ID,
  //       isBookmark: false,
  //     };

  //     if (mapstable) {
  //       if (mapstable.isBookmark === false) {
  //         setBookmarkFlag(bookmarkpayloadtrue);
  //         alert("Table Bookmark Added successfully");
  //       } else {
  //         setBookmarkFlag(bookmarkpayloadfalse);
  //         alert("Table Bookmark Removed successfully");
  //       }
  //     }
  //   };

  const handleSearch = (event) => {
    let data = Object.assign({}, originalMapsTable);
    let searchList = data.data.filter((obj) => {
      if (
        Object.values(obj).filter((item) => {
          if (Array.isArray(item)) {
            return item.filter((age) =>
              age.name.toLowerCase().includes(event.target.value.toLowerCase())
            ).length;
          } else if (typeof item === "object") {
            return item
              ? item.name
                  .toString()
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase())
              : false;
          } else {
            return item
              ? item
                  .toString()
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase())
              : false;
          }
        }).length
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (searchList) {
      data.data = searchList;
      setMapsTable(data);
    }
  };
  //   const LightTooltip = withStyles((theme) => ({
  //     tooltip: {
  //       backgroundColor: theme.palette.common.white,
  //       color: "rgba(0, 0, 0, 0.87)",
  //       boxShadow: theme.shadows[1],
  //       fontSize: 14,
  //       padding: 5,
  //     },
  //   }))(Tooltip);

  return (
    <div className={classes.container} style={{ margin: "4px 21px" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "100%",
          }}
        >
          <h6 className={classes.title}> {props.location.state.tablename}</h6>
          <h6 className={classes.desc}> {props.location.state.tabledesc}</h6>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              onChange={(e) => handleSearch(e)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {/* <div
            id="sort-container"
            style={{ position: "relative", margin: "1em" }}
            className={classes.formControl}
          >
            {mapstable && (
              <LightTooltip title="Bookmark" placement="top-end">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    float: "right",
                    width: "47px",
                    height: "36px",
                    margin: "1em 0em",
                    background: "#00A3FF",
                    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                    borderRadius: "3px",
                  }}
                  variant="contained"
                  onClick={handleBookmark}
                >
                  {mapstable.isBookmark === false ? (
                    <BookmarkBorderIcon style={{ color: "#ffffff" }} />
                  ) : (
                    <BookmarkIcon style={{ color: "#ffffff" }} />
                  )}
                </div>
              </LightTooltip>
            )}
          </div> */}
          {/* ------------------SORTING BUTTON-----------*/}

          {/* <div
            id="filter-container"
            style={{ position: "relative", margin: "1em" }}
            className={classes.formControl}
          >
            <LightTooltip title="Sort" placement="top">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  float: "right",
                  width: "47px",
                  height: "36px",
                  margin: "1em 0em",
                  background: "#00A3FF",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "3px",
                }}
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                <img src={SortIcon} alt="filter" />
              </div>
            </LightTooltip>
            <Popover
              className={classes.formControl}
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ top: "30%", left: "80%" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleFilterMenu}
              >
                <h3 style={{ marginLeft: "1em" }}>Pick field to sort</h3>
                <ArrowDropDownIcon />
              </div>
              {filterMenu &&
                columns.length > 0 &&
                columns.map((column) => (
                  <MenuItem
                    onClick={() => handleSortItems("sortby", column.value)}
                    key={column.displayName}
                    value={column.value}
                    style={getStyles(column.value, sorting.sortby, theme)}
                  >
                    {column.displayName.charAt(0).toUpperCase() +
                      column.displayName.slice(1)}
                  </MenuItem>
                ))}
              {alphabets.map((name) => (
                <MenuItem
                  onClick={() => handleSortItems("orderby", name)}
                  key={name}
                  value={name}
                  style={getStyles(name, sorting.orderby, theme)}
                >
                  {name}
                </MenuItem>
              ))}
              <Button
                style={{
                  marginLeft: "1em",
                  marginBottom: "1em",
                  marginTop: "14px",
                  background: "#1B804F",
                  color: "white",
                }}
                variant="contained"
                size="small"
                onClick={handlePopover}
              >
                Apply
              </Button>
            </Popover>
          </div> */}
          {/* ------------------FILTER BUTTON-----------*/}
          {/* <div
            id="sort-container"
            style={{ position: "relative", margin: "1em" }}
            className={classes.formControl}
          >
            <LightTooltip title="Filter" placement="top">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  float: "right",
                  width: "47px",
                  height: "36px",
                  margin: "1em 0em",
                  background: "rgba(0, 87, 255, 0.8)",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "3px",
                }}
                aria-describedby={filterId}
                variant="contained"
                color="primary"
                onClick={handleFilterClick}
              >
                <img src={filterIcon} alt="sort" />
              </div>
            </LightTooltip>
            <Popover
              className={classes.formControl}
              id={filterId}
              open={openPopFilter}
              anchorEl={anchorFilter}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ top: "31%", left: "60%" }}
            >
              {filterData.map((filObj, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {index === 0 ? (
                    <p style={{ marginLeft: "1em" }}>{filObj["condition"]}</p>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ClearIcon
                        fontSize="small"
                        onClick={() => handleFilterClear(index)}
                        style={{ marginTop: "1em", marginLeft: "5px" }}
                      />
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-customized-select-label">
                          Condition
                        </InputLabel>
                        <Select
                          name="condition"
                          disabled={index > 1}
                          labelId="demo-customized-select-label"
                          id="demo-customized-select"
                          value={filObj["condition"]}
                          onChange={(event) => handleFilterData(event, index)}
                          input={<BootstrapInput />}
                        >
                          {[
                            { name: "AND", value: "and" },
                            { name: "OR", value: "or" },
                          ].map((condition, i) => (
                            <MenuItem key={i} value={condition.value}>
                              {condition.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  <FormControl className={classes.select}>
                    <InputLabel id="demo-customized-select-label">
                      Column
                    </InputLabel>
                    <Select
                      name="column"
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={filObj["column"]}
                      style={{ maxHeight: "200px" }}
                      onChange={(event) => handleFilterData(event, index)}
                      input={<BootstrapInput />}
                    >
                      {columns.length > 0 &&
                        columns.map((column, i) => (
                          <MenuItem
                            key={column.displayName + i}
                            value={column.value}
                          >
                            {column.displayName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.select}>
                    <InputLabel id="demo-customized-select-label">
                      type
                    </InputLabel>
                    <Select
                      name="type"
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={filObj["type"]}
                      style={{ maxHeight: "200px !important" }}
                      onChange={(event) => handleFilterData(event, index)}
                      input={<BootstrapInput />}
                    >
                      {typeList.map((type, i) => (
                        <MenuItem
                          key={i}
                          value={type.value}
                          style={getStyles(type.name, filter.sortby, theme)}
                        >
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.select}>
                    <InputLabel htmlFor="demo-customized-textbox">
                      Search
                    </InputLabel>
                    <BootstrapInput
                      name="value"
                      value={filObj["value"]}
                      id="demo-customized-textbox"
                      onChange={(event) => handleFilterData(event, index)}
                    />
                  </FormControl>
                </div>
              ))}
              <div
                onClick={() => handleAddFilter()}
                style={{ cursor: "pointer" }}
              >
                <p style={{ marginLeft: "1em" }}>+Add Filter</p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{
                    marginLeft: "1em",
                    marginBottom: "1em",
                    background: "#1B804F",
                    color: "white",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => handleFilterApply()}
                >
                  Apply
                </Button>
                <div
                  onClick={() => handleClearFilter()}
                  style={{ cursor: "pointer" }}
                >
                  <p style={{ marginTop: 0, marginLeft: "1em" }}>Clear</p>
                </div>
              </div>
            </Popover>
          </div> */}

          {/* <Button
            variant="contained"
            style={{
              marginBottom: "28px",
              color: "#ffffff",
              background: "rgba(0, 0, 0, 0.5)",
              boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
              borderRadius: "3px",
            }}
            onClick={handleDownloadCsv}
          >
            Download CSV
          </Button> */}
        </div>
      </div>
      <div>
        {mapstable && filteritem.length === 0 ? (
          <ViewTagsTrailTable
            // entries={props.location.state.entries}
            //handlepagechange={handlepagechange}
            rows={mapstable.data.slice(0, 200)}
            headcells={mapstable.columns}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}
