import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseApi } from "../../Utils/config";
import { Typography } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssessmentReport from "../UserInteraction/AssessmentReport";
import ActivityProgress from "../UserInteraction/ActivityProgress";
import LevelAnalytics from "../UserInteraction/LevelAnalytics";
import ViewSubmissions from "../UserInteraction/ViewSubmission";
import UserLeveAnalytics from "../UserInteraction/UserLevelAnalytics";

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",

    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginRight: "2%",
    width: "260px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
let AUTH_ERROR_STATUS_CODES = [401, 402, 403];
export default function UserInteraction() {
  var apiBaseUrl = baseApi;

  const [dashboardData, setDashboardData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState();
  var user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const history = useHistory();

  //   useEffect(() => {
  //     fetchData();
  //   }, []);
  //   function fetchData() {
  //     const res = axios({
  //       method: "get",
  //       url: apiBaseUrl + "v1/user/dashboard",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-auth-token": user.apiToken,
  //       },
  //     });
  //     res
  //       .then((res) => {
  //         setDashboardData(res.data.data);
  //         setFilteredData(res.data.data);
  //         let userData = user;
  //         userData.menu = res.data.data.menus;
  //         window.localStorage.setItem("user", JSON.stringify(userData));
  //       })
  //       .catch((err) => {
  //         if (AUTH_ERROR_STATUS_CODES.includes(err.statusCode) != -1) {
  //           window.localStorage.removeItem("user");
  //           window.location = "/login";
  //         } else {
  //           alert(err.message);
  //         }
  //       });
  //   }
  const handleChange = (event) => {
    let dashboardList = JSON.parse(JSON.stringify(dashboardData));
    let searchList = dashboardList.data.map((content) => {
      let filter = content.list.filter((item) =>
        item.displayName
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      content.list = filter;
      return content;
    });
    dashboardList.data = searchList;
    setFilteredData(dashboardList);
  };
  return (
    <div className={classes.container}>
      <div
        style={{
          margin: "2em",
        }}
      >
        <div
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        // }}
        >
          <Typography
            variant="h4"
            align="left"
            style={{
              fontSize: "26px",
              fontWeight: "bold",
              color: "#242F57",
              marginBottom: "10px",
            }}
          >
            User Interaction
          </Typography>
          <Typography
            variant="h6"
            align="left"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#636E95",
              marginBottom: "3%",
            }}
          >
            Conduct sessions, reply to chats, schedule content or view user
            transactions
            {/* <span style={{ color: "#56AAFF" }}>27 Feb - 3 Apr</span> */}
          </Typography>
        </div>
        <div className={classes.gridList}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            <UserLeveAnalytics />
            <ActivityProgress />
            <LevelAnalytics name={"Feature"} />
            <AssessmentReport />
            <ViewSubmissions />
            <LevelAnalytics name={"Parameter"} />
          </div>
          {/* {!filteredData && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
