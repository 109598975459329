import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import progressIcon from "../../assets/progressIcon.png";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#242F57",
  },
  container: {
    width: "330px",
    height: "120px",
    border: "1px solid #EAEDF7",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    padding: "20px 25px",
    margin: "20px",
  },
  pointer: {
    height: "16px",
    width: "16px",
    background: "#FFC800",
    borderRadius: "4px",
    marginRight: "20px",
  },
  progressText: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
    color: "#9390F3",
  },
  progressBar: {
    marginTop: "2em",
    position: "relative",
    width: "100%",
    background: "#EAEDF7",
    borderRadius: "4px",
    height: "16px",
  },
}));

export default function ActivityProgress() {
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [data, setData] = useState(["I", "II", "III", "IV"]);

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className={classes.title}>Assesment Report</div>
        <span className={classes.progressText}>67%</span>
      </div>
      <div className={classes.progressBar}>
        <span
          style={{
            position: "absolute",
            backgroundColor: "#9390F3",
            borderRadius: "4px",
            width: "67%",
            height: "16px",
          }}
        ></span>
      </div>
    </div>
  );
}
