const config = {
  api: {
    dev: "https://dev.mai.family/cms-api/",
    test: "https://arc.parentof.com/api/",
    prod: "https://mai.family/cms-api/",
  },
  google:
    "143278732650-pobf3sup5r6n838387ogk17ftd5ufgiq.apps.googleusercontent.com",
};

export const baseApi = config.api.test;
export const googleToken = config.google;
