import React, { useEffect } from "react";
// import axios from "axios";
// import moment from "moment";
import { useHistory } from "react-router-dom";
import { baseApi } from "../../Utils/config";
import AdminLevelDetails from "./AdminLevelDetails";
import UserLevelDetails from "./UserLevelDetails";

let AUTH_ERROR_STATUS_CODES = [401, 402, 403];
export default function Dashobard(props) {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <div>
      <AdminLevelDetails />
      <UserLevelDetails />
    </div>
  );
}
