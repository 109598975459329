import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { blue } from "@material-ui/core/colors";
import { Button, Collapse } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { baseApi } from "../../Utils/config";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, rowCount, onRequestSort, headcells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  // const col = props.headcells;

  return (
    <TableHead>
      <TableRow>
        {headcells.map((headCell, index) => (
          <TableCell
            key={index}
            align="center"
            className={classes.tablehead}
            // padding={'default'}
            sortDirection={orderBy === headCell.name ? order : false}
            style={{ fontWeight: "600" }}
          >
            {headCell.displayName.charAt(0).toUpperCase() +
              headCell.displayName.slice(1)}
          </TableCell>
        ))}
        <TableCell
          align="center"
          className={classes.tablehead}
          // padding={'default'}
          // sortDirection={orderBy === headCell.name ? order : false}
          style={{ fontWeight: "600" }}
        >
          Trail
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tablehead: {
    padding: "10px",
    fontSize: "13px",
  },
  tablerowstr: {
    wordWrap: "break-word",
    textAlign: "left",
    maxWidth: "170px",
  },
  tablecell: {
    border: "1px solid rgba(0,0,0,0.08)",
    padding: "5px 5px",
    fontSize: "13px",
    whiteSpace: "pre-line",
  },
  tablebutton: {
    background: "#D0D9FF",
    borderRadius: "2px",
    border: "0px",
    color: "#1D4FB1",
    padding: "5px 15px",
    cursor: "pointer",
    "&:disabled": {
      backgroundColor: "rgba(0,0,0,0.08)",
      color: "rgba(0,0,0,0.2)",
    },
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "#0E8050",
    "&$checked": {
      color: "#0E8050",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

var rows = [];
var headcells = [];
var edit = true;

export default function TagsTable(props) {
  rows = props.rows;
  headcells = props.headcells;
  edit = props.edit && true;
  console.log(edit);
  var ID = props.tableID;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rowsChanges, setRowsChanges] = React.useState({});
  const [updateRow, setUpdateRow] = React.useState([]);
  const [propsRows, setpropsRows] = React.useState(rows);
  const [rIndex, setRIndex] = React.useState("");
  const [rKey, setRkey] = React.useState("");
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMsg, settoastMsg] = React.useState();
  const columns = headcells.map((cell) => cell.displayName);
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  // useEffect(() => {
  //   //console.log(rows);
  // }, [rows]);

  const handleCheckBox = (e, index, fieldName, fieldValue) => {
    console.log(e.target.checked, index, fieldName);

    let data = [...rows];
    data.reduce((acc, cv, ind) => {
      if (index === ind) {
        cv[fieldName] = e.target.checked;
      }
      return acc;
    }, []);

    var colIndex = Object.values(columns).findIndex((item) => {
      return item === fieldName;
    });
    console.log(
      "<------TABLEID  " +
        ID +
        "\n\nindex--" +
        index +
        " \n\nRow ID--" +
        rows[index]._id +
        " \n\nColumn-- " +
        fieldName +
        " \n\nCOL ID--" +
        headcells[colIndex]._id +
        " \n\nValue--" +
        e.target.value +
        "  ---->"
    );

    let payload = {
      contentTable: ID,
      column: headcells[colIndex]._id,
      _id: rows[index]._id,
      value: e.target.checked,
    };
    setRkey();

    if (e.target.checked !== fieldValue) {
      editData(payload);
    }
    console.log(data);
  };
  const history = useHistory();

  const editData = (payload) => {
    const res = axios(
      {
        method: "post",
        url: apiBaseUrl + "v1/content/record/update",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        settoastMsg("Successfully Updated");
        setOpenToast(true);
        setTimeout(() => {
          setOpenToast(false);
          settoastMsg(null);
        }, 1500);
      })
      .catch((err) => {
        alert("Unable to Update at the moment");
        console.log(err);
      });
  };

  const EditableTableCell = ({
    row,
    fieldName,
    index,
    fieldValue,
    onCellValueChange,
    type,
  }) => {
    const handleEnter = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();

        handleBlur(e);
        //console.log(e.target.value);
      }
      if (type === "Number") {
        var key = e.keyCode ? e.keyCode : e.which;

        if (
          !(
            [8, 46, 190, 110].indexOf(key) !== -1 ||
            (key === 65 && (e.ctrlKey || e.metaKey)) ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
            (key >= 96 && key <= 105)
          )
        )
          e.preventDefault();
      }
      // if (type === "String") {
      //   var charCode = e.keyCode;

      //   if (
      //     !(
      //       (charCode > 64 && charCode < 91) ||
      //       (charCode > 96 && charCode < 123) ||
      //       parseInt(charCode) === 46 ||
      //       charCode === 8 /*BCKSPC*/ ||
      //       charCode === 37 /*LFT ARROW*/ ||
      //       charCode === 39 /*RGT ARROW*/ ||
      //       charCode === 32 /*space*/ ||
      //       (charCode === 13 && e.shiftKey)
      //     )
      //   )
      //     e.preventDefault();
      // }
    };

    const handleBlur = (e) => {
      //console.log(e.target.value);
      // alert(e.target.value);
      var colIndex = Object.values(columns).findIndex((item) => {
        return item === fieldName;
      });
      console.log(
        "<------TABLEID  " +
          ID +
          "\n\nindex--" +
          index +
          " \n\nRow ID--" +
          rows[index]._id +
          " \n\nColumn-- " +
          fieldName +
          " \n\nCOL ID--" +
          headcells[colIndex]._id +
          " \n\nValue--" +
          e.target.value +
          "  ---->"
      );

      let payload = {
        contentTable: ID,
        column: headcells[colIndex]._id,
        _id: rows[index]._id,
        value: e.target.value,
      };
      setRkey();
      if (e.target.value !== fieldValue) {
        editData(payload);
      }
    };

    const handleTextFieldChange = (e) => {
      onCellValueChange({
        fieldValue: e.target.value,
        fieldName: fieldName,
      });
      // console.log(
      //   "<------TABLEID  " +
      //     ID +
      //     "\nindex " +
      //     index +
      //     " \nColumn " +
      //     fieldName +
      //     " \nValue " +
      //     e.target.value +
      //     "  ---->"
      // );
      let data = [...rows];
      data.reduce((acc, cv, ind) => {
        if (index === ind) {
          cv[fieldName] = e.target.value;
        }
        return acc;
      }, []);

      // const re = /[a-fA-F]+/g;
      // if (!re.test(e.target.value)) {
      //   return;
      // }

      // let data = [...rows];
      // data.reduce((acc, cv, ind) => {
      //   if (index === ind) {
      //     cv[fieldName] = e.target.value;
      //   }
      //   return acc;
      // }, []);
    };

    return (
      <TableCell>
        {/* {type === "Number" ? (
          <TextField
            // style={{ fontSize: "10px", width: "100%" }}
            onChange={handleTextFieldChange}
            type="number"
            onKeyDown={handleEnter}
            onBlur={handleBlur}
            // onFocus={handleTextFieldChange}
            id={fieldName}
            defaultValue={fieldValue}
            margin="normal"
            className={classes.tablecell}
            style={{
              fontSize: "13px",
              minWidth: "-webkit-fill-available",
              cursor: "pointer",
            }}
            autoFocus
          />
        ) : ( */}
        <TextareaAutosize
          onChange={handleTextFieldChange}
          onKeyDown={handleEnter}
          aria-label="minimum height"
          // fieldValue.toString().split(/\s/).length < 3 ||
          rowsMin={fieldValue && fieldValue.toString().length < 20 ? 1 : 3}
          // rowsMax={4}
          // aria-label="maximum height"
          placeholder="Enter the Value for this field"
          onBlur={handleBlur}
          id={fieldName}
          defaultValue={fieldValue}
          margin="normal"
          className={classes.tablecell}
          style={{
            fontSize: "13px",
            minWidth: "-webkit-fill-available",
            cursor: "pointer",
          }}
          autoFocus
        />
        {/* <TextField
          // style={{ fontSize: "10px", width: "100%" }}
          onChange={handleTextFieldChange}
          // onFocus={handleTextFieldChange}
          onBlur={(e) => console.log(e.target.value)}
          id={fieldName}
          defaultValue={fieldValue}
          margin="normal"
        /> */}
      </TableCell>
    );
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleTextFieldChange = (rowIndex, key, value, type) => {
    setRIndex(rowIndex);
    setRkey(key);

    // setUpdateRow((prevState) => ({
    //   updateRow: {
    //     ...prevState.updateRow,
    //     [rowIndex]: {
    //       ...prevState.updateRow[rowIndex],
    //       [key.fieldName]: key.fieldValue,
    //     },
    //   },
    // }));
  };
  const handleChangePage = (event, newPage) => {
    props.handlepagechange(newPage);
    setPage(newPage);
  };

  const handleClick = (event) => {
    console.log(event.target.value);

    history.push({
      pathname: `/mapstableitem`,
      state: { id: event.target.value },
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage;
  // console.log(rows);

  // rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ position: "fixed", top: "70px", left: "45%" }}>
          <Collapse in={openToast}>
            {toastMsg === "Successfully Updated" && (
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    // color="red"
                    size="small"
                    onClick={() => {
                      setOpenToast(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {toastMsg}
              </Alert>
            )}

            {toastMsg === "Not Editable Field" && (
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    // color="red"
                    size="small"
                    onClick={() => {
                      setOpenToast(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {toastMsg}
              </Alert>
            )}
          </Collapse>
        </div>
        <TableContainer style={{ maxWidth: "100%" /* maxHeight:"90vh"*/ }}>
          <Table
            // stickyHeader
            // aria-label="sticky table"
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headcells={headcells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {/* <TableCell align="center"></TableCell> */}

                      {/* {console.log(row)} */}

                      {Object.values(columns).map((data, ind) => {
                        //   {console.log(row[columns[ind]])}

                        if (
                          typeof row[columns[ind]] === "object" &&
                          row[columns[ind]] != null
                        ) {
                          if (
                            row[columns[ind]].length > 1 &&
                            row[columns[ind]][0].name
                          ) {
                            //   console.log(row[columns[ind]][index].name );
                            let ar = row[columns[ind]].map((item) => item.name);
                            return (
                              <TableCell
                                onDoubleClick={() => {
                                  settoastMsg("Not Editable Field");
                                  setOpenToast(true);
                                  setTimeout(() => {
                                    setOpenToast(false);
                                    settoastMsg(null);
                                  }, 1500);
                                }}
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                                style={{ cursor: "not-allowed" }}
                              >
                                {ar.join(" , ")}
                              </TableCell>
                            );
                          } else if (
                            row[columns[ind]].length > 1 &&
                            !row[columns[ind]][0].name
                          ) {
                            return (
                              <TableCell
                                onDoubleClick={() => {
                                  settoastMsg("Not Editable Field");
                                  setOpenToast(true);
                                  setTimeout(() => {
                                    setOpenToast(false);
                                    settoastMsg(null);
                                  }, 1500);
                                }}
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                                style={{ cursor: "not-allowed" }}
                              >
                                {row[columns[ind]]}
                              </TableCell>
                            );
                          } else if (
                            row[columns[ind]].length < 2 &&
                            row[columns[ind]].length > 0
                          ) {
                            if (row[columns[ind]][0].name) {
                              return (
                                <TableCell
                                  onDoubleClick={() => {
                                    settoastMsg("Not Editable Field");
                                    setOpenToast(true);
                                    setTimeout(() => {
                                      setOpenToast(false);
                                      settoastMsg(null);
                                    }, 1500);
                                  }}
                                  className={classes.tablecell}
                                  key={ind}
                                  align="center"
                                  style={{ cursor: "not-allowed" }}
                                >
                                  {row[columns[ind]][0].name}
                                </TableCell>
                              );
                              // } else if (
                              //   !row[columns[ind]][0].name &&
                              //   columns[ind] === "images"
                              // ) {
                              //   return (
                              //     <TableCell
                              //       className={classes.tablecell}
                              //       key={ind}
                              //       align="center"
                              //     >
                              //       <img
                              //         src={row[columns[ind]]}
                              //         style={{
                              //           height: "75px",
                              //           width: "75px",
                              //           borderRadius: "50%",
                              //         }}
                              //         alt=""
                              //       />
                              //     </TableCell>
                              //   );
                              // }
                            } else if (row[columns[ind]][0].key) {
                              return (
                                <TableCell
                                  onDoubleClick={() => {
                                    settoastMsg("Not Editable Field");
                                    setOpenToast(true);
                                    setTimeout(() => {
                                      setOpenToast(false);
                                      settoastMsg(null);
                                    }, 1500);
                                  }}
                                  className={classes.tablecell}
                                  key={ind}
                                  align="center"
                                  style={{ cursor: "not-allowed" }}
                                >
                                  {row[columns[ind]][0].key}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  onDoubleClick={() => {
                                    settoastMsg("Not Editable Field");
                                    setOpenToast(true);
                                    setTimeout(() => {
                                      setOpenToast(false);
                                      settoastMsg(null);
                                    }, 1500);
                                  }}
                                  className={classes.tablecell}
                                  key={ind}
                                  align="center"
                                  style={{ cursor: "not-allowed" }}
                                >
                                  {row[columns[ind]]}
                                </TableCell>
                              );
                            }
                          } else
                            return (
                              <TableCell
                                onDoubleClick={() => {
                                  settoastMsg("Not Editable Field");
                                  setOpenToast(true);
                                  setTimeout(() => {
                                    setOpenToast(false);
                                    settoastMsg(null);
                                  }, 1500);
                                  // handleTextFieldChange(
                                  //   index,
                                  //   columns[ind],
                                  //   row[columns[ind]]
                                  // );
                                }}
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                                style={{ cursor: "not-allowed" }}
                              >
                                {row[columns[ind]].name}
                              </TableCell>
                            );
                        } else if (row[columns[ind]] === null) {
                          if (
                            (headcells[ind].name === columns[ind] &&
                              headcells[ind].dataType === "ObjectId") ||
                            (headcells[ind].name === columns[ind] &&
                              headcells[ind].dataType === "Object") ||
                            (headcells[ind].name === columns[ind] &&
                              headcells[ind].dataType === "Array") ||
                            (headcells[ind].name === columns[ind] &&
                              headcells[ind].dataType === "StringArray") ||
                            (headcells[ind].name === columns[ind] &&
                              headcells[ind].dataType === "Auto") ||
                            (headcells[ind].name === columns[ind] &&
                              headcells[ind].dataType === "Date")
                          ) {
                            return (
                              <TableCell
                                onDoubleClick={() => {
                                  settoastMsg("Not Editable Field");
                                  setOpenToast(true);
                                  setTimeout(() => {
                                    setOpenToast(false);
                                    settoastMsg(null);
                                  }, 1500);
                                  // handleTextFieldChange(
                                  //   index,
                                  //   columns[ind],
                                  //   row[columns[ind]]
                                  // );
                                }}
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                                style={{ cursor: "not-allowed" }}
                              >
                                {row[columns[ind]]}
                              </TableCell>
                            );
                          } else if (
                            headcells[ind].name === columns[ind] &&
                            headcells[ind].dataType === "Boolean"
                          ) {
                            return (
                              <TableCell
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                              >
                                <GreenCheckbox
                                  checked={row[columns[ind]]}
                                  color="primary"
                                  inputProps={{
                                    "aria-label": "secondary checkbox",
                                  }}
                                  style={
                                    edit
                                      ? { cursor: "pointer" }
                                      : { cursor: "not-allowed" }
                                  }
                                  onChange={
                                    edit
                                      ? (e) => {
                                          handleCheckBox(
                                            e,
                                            index,
                                            columns[ind],
                                            row[columns[ind]]
                                          );
                                        }
                                      : () => {
                                          settoastMsg("Not Editable Field");
                                          setOpenToast(true);
                                          setTimeout(() => {
                                            setOpenToast(false);
                                            settoastMsg(null);
                                          }, 1500);
                                          // handleTextFieldChange(
                                          //   index,
                                          //   columns[ind],
                                          //   row[columns[ind]]
                                          // );
                                        }
                                  }
                                />
                              </TableCell>
                            );
                          }
                          // else if (
                          //   headcells[ind].name === columns[ind] &&
                          //   headcells[ind].dataType === "Date"
                          // ) {
                          //   return (
                          //     <TableCell
                          //       className={classes.tablecell}
                          //       key={ind}
                          //       align="center"
                          //     >
                          //       <TextField
                          //         id="date"
                          //         type="date"
                          //         defaultValue=""
                          //         onChange={(e) => {
                          //           console.log(e.target.value);
                          //         }}
                          //         // className={classes.textField}
                          //         // InputLabelProps={{
                          //         //   shrink: true,
                          //         // }}
                          //       />
                          //     </TableCell>
                          //   );
                          // }
                          else if (
                            headcells[ind].name === columns[ind] &&
                            headcells[ind].dataType === "Number"
                          ) {
                            return rIndex === index && rKey === columns[ind] ? (
                              // <TableCell>
                              //   <TextField
                              //     // style={{ fontSize: "10px", width: "100%" }}
                              //     onChange={handleTextFieldChange}
                              //     // onFocus={handleTextFieldChange}
                              //     onBlur={(e) => console.log(e.target.value)}
                              //     id={fieldName}
                              //     defaultValue={fieldValue}
                              //     margin="normal"
                              //   />
                              // </TableCell>
                              <EditableTableCell
                                row={row}
                                key={ind}
                                index={index}
                                type={headcells[ind].dataType}
                                fieldName={[columns[ind]]}
                                onCellValueChange={() =>
                                  handleTextFieldChange(index, columns[ind])
                                }
                                fieldValue={""}
                              />
                            ) : (
                              <TableCell
                                onDoubleClick={
                                  edit
                                    ? () => {
                                        handleTextFieldChange(
                                          index,
                                          columns[ind],
                                          row[columns[ind]]
                                        );
                                      }
                                    : () => {
                                        settoastMsg("Not Editable Field");
                                        setOpenToast(true);
                                        setTimeout(() => {
                                          setOpenToast(false);
                                          settoastMsg(null);
                                        }, 1500);
                                        // handleTextFieldChange(
                                        //   index,
                                        //   columns[ind],
                                        //   row[columns[ind]]
                                        // );
                                      }
                                }
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                                style={
                                  edit
                                    ? { cursor: "context-menu" }
                                    : { cursor: "not-allowed" }
                                }
                              >
                                {row[columns[ind]]}
                              </TableCell>
                            );
                          } else {
                            return rIndex === index && rKey === columns[ind] ? (
                              <EditableTableCell
                                row={row}
                                key={ind}
                                index={index}
                                type={headcells[ind].dataType}
                                fieldName={[columns[ind]]}
                                onCellValueChange={() =>
                                  handleTextFieldChange(index, columns[ind])
                                }
                                fieldValue={""}
                              />
                            ) : (
                              <TableCell
                                onDoubleClick={
                                  edit
                                    ? () => {
                                        handleTextFieldChange(
                                          index,
                                          columns[ind],
                                          row[columns[ind]]
                                        );
                                      }
                                    : () => {
                                        settoastMsg("Not Editable Field");
                                        setOpenToast(true);
                                        setTimeout(() => {
                                          setOpenToast(false);
                                          settoastMsg(null);
                                        }, 1500);
                                        // handleTextFieldChange(
                                        //   index,
                                        //   columns[ind],
                                        //   row[columns[ind]]
                                        // );
                                      }
                                }
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                                style={
                                  edit
                                    ? { cursor: "context-menu" }
                                    : { cursor: "not-allowed" }
                                }
                              >
                                {row[columns[ind]]}
                              </TableCell>
                            );
                          }
                        } else if (
                          row[columns[ind]] &&
                          //   (columns[ind] === "image" ||
                          //     columns[ind] === "icon" ||
                          //     columns[ind] === "thumbnailImage")
                          // )
                          (row[columns[ind]]
                            .toString()
                            .toLowerCase()
                            .includes(".jpg") ||
                            row[columns[ind]]
                              .toString()
                              .toLowerCase()
                              .includes(".jpeg") ||
                            row[columns[ind]]
                              .toString()
                              .toLowerCase()
                              .includes(".png"))
                        ) {
                          return (
                            <TableCell
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            >
                              <img
                                src={row[columns[ind]]}
                                style={{
                                  height: "75px",
                                  width: "75px",
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                            </TableCell>
                          );
                        } else if (typeof row[columns[ind]] === "boolean") {
                          return (
                            <TableCell
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            >
                              <GreenCheckbox
                                checked={row[columns[ind]]}
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                style={
                                  edit
                                    ? { cursor: "pointer" }
                                    : { cursor: "not-allowed" }
                                }
                                onChange={
                                  edit
                                    ? (e) => {
                                        handleCheckBox(
                                          e,
                                          index,
                                          columns[ind],
                                          row[columns[ind]]
                                        );
                                      }
                                    : () => {
                                        settoastMsg("Not Editable Field");
                                        setOpenToast(true);
                                        setTimeout(() => {
                                          setOpenToast(false);
                                          settoastMsg(null);
                                        }, 1500);
                                        // handleTextFieldChange(
                                        //   index,
                                        //   columns[ind],
                                        //   row[columns[ind]]
                                        // );
                                      }
                                }
                              />
                            </TableCell>
                          );
                        } else {
                          return rIndex === index && rKey === columns[ind] ? (
                            <EditableTableCell
                              row={row}
                              key={ind}
                              index={index}
                              fieldName={[columns[ind]]}
                              type={headcells[ind].dataType}
                              onCellValueChange={() =>
                                handleTextFieldChange(
                                  index,
                                  columns[ind],
                                  headcells[ind].dataType
                                )
                              }
                              fieldValue={row[columns[ind]]}
                            />
                          ) : (
                            <TableCell
                              onDoubleClick={
                                edit
                                  ? () => {
                                      handleTextFieldChange(
                                        index,
                                        columns[ind],
                                        row[columns[ind]],
                                        headcells[ind].dataType
                                      );
                                    }
                                  : () => {
                                      settoastMsg("Not Editable Field");
                                      setOpenToast(true);
                                      setTimeout(() => {
                                        setOpenToast(false);
                                        settoastMsg(null);
                                      }, 1500);
                                      // handleTextFieldChange(
                                      //   index,
                                      //   columns[ind],
                                      //   row[columns[ind]]
                                      // );
                                    }
                              }
                              style={
                                edit
                                  ? { cursor: "context-menu" }
                                  : { cursor: "not-allowed" }
                              }
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            >
                              {row[columns[ind]]}
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell
                        style={{ cursor: "pointer" }}
                        className={classes.tablecell}
                        // key={ind}
                        align="center"
                      >
                        <button
                          variant="contained"
                          onClick={(event) => {
                            history.push({
                              pathname: "/views-trail",
                              state: {
                                id: ID,
                                tablename: props.tablename,
                                tabledesc: props.tabledesc,
                                record: row._id,
                              },
                            });
                          }}
                          value={row._id}
                          color="primary"
                          name={row.displayName}
                          desc={row.description}
                          entries={row.recordCount}
                          className={classes.tablebutton}
                        >
                          click
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length === 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>No Data Found</h4>
          </div>
        )}
        {rows.length != 0 && (
          <TablePagination
            rowsPerPageOptions={[100]}
            component="div"
            count={
              rows.length > 0 && props.search ? rows.length : props.entries
            }
            rowsPerPage={rowsPerPage}
            page={props.page ? props.page : page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
