import React from "react";
import { API_URL } from "../../constants";

export default function CurationCmp() {
  return (
    <div className="iframe-container">
      <iframe
        title="Embedded Website"
        src={`${API_URL}/curation`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}
