import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { baseApi } from "../../Utils/config";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import usimg from "../../assets/usimg.png";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Button, Typography } from "@material-ui/core";
import AddEmpModal from "./AddEmpModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditEmpModal from "./EditEmpModal";
import back_icon from "../../assets/back_icon.png";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "1%",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#33343D",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listname: {
    margin: "10px 20px",
  },
  dataitem: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0A1629",
  },
  butonz: {
    "&:hover": {
      background: "#ccc",
    },
    background: "#F4F9FD",
  },
}));
var userx = [];

export default function UserProfile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  var apiBaseUrl = baseApi;
  console.log("props-location-", props.location);
  var employeeID = props.location.state._id.toString();
  var user = JSON.parse(localStorage.getItem("user"));
  const [pageData, setPageData] = React.useState("");
  const [editedPermissionsData, setEditedPermissionsData] = React.useState("");
  const [editedPermissions, setEditedPermissions] = React.useState(false);
  const [updatePageData, setUpdatePageData] = useState(false);
  const [load, setload] = useState(true);
  const [userData, setUserData] = useState("");
  const [errors, setErrors] = useState("");

  const fetchData = (params) => {
    const modulesList = axios({
      method: "get",
      url: apiBaseUrl + `permission/${employeeID}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    });
    modulesList
      .then((response, error) => {
        //console.log(response.data);
        setPageData(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const calculate_age = (dob) => {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    return Math.abs(age.getUTCFullYear() - 1970);
  };
  function UserDetails() {
    const res = axios(
      {
        method: "get",
        url: apiBaseUrl + `users/userDetails/${employeeID}?query=today`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        console.log("userDetails", res.data.data);
        setUserData(res.data.data.userDetails.userDetails);
        // setuserscol(res.data.data.columns);
        // setorgusers(res.data.data.data);
        setload(false);
      })
      .catch((err) => setErrors(err));
  }
  const UpdatePermissions = (postData) => {
    const modulesList = axios({
      method: "post",
      url: apiBaseUrl + `permission`,
      data: postData,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    });
    modulesList
      .then((response, error) => {
        // console.log(response.data);
        setPageData(response.data.message);
        setUpdatePageData(!updatePageData);
        setEditedPermissions(false);
        alert("Permissions Saved Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
    UserDetails();
    // return () => {
    //   cleanup;
    // };
  }, []);

  const handleChange = (event, functionality, module, subModule) => {
    const data = Object.assign({}, pageData);
    data.permission.forEach((item) => {
      item.module.forEach((moduleDetails) => {
        moduleDetails.subModule.forEach((subModuleDetails) => {
          if (subModuleDetails.moduleTitle === subModule) {
            console.log(subModuleDetails);
            subModuleDetails[event.target.name] = event.target.checked;
            return subModuleDetails;
          }
        });
      });
    });
    setEditedPermissionsData(data);
    setEditedPermissions(true);
    // setPageData(data, () => {
    // UpdatePermissions(data);
    // });
    // console.log(data);
  };
  const selectAllFromModule = (event, functionality, module) => {
    const data = Object.assign({}, pageData);
    data.permission.forEach((item) => {
      if (item.functionality === functionality) {
        item.module.forEach((moduleDetails) => {
          if (moduleDetails.moduleHeader === module) {
            moduleDetails.subModule.forEach((subModuleDetails) => {
              subModuleDetails.isEdit = event.target.checked;
              subModuleDetails.isView = event.target.checked;
              return subModuleDetails;
            });
          }
        });
      }
    });
    setEditedPermissionsData(data);
    setEditedPermissions(true);
    // setPageData(data, () => {
    // UpdatePermissions(data);
    // });
    // console.log(data);
  };

  const handleSavePermissions = () => {
    UpdatePermissions(editedPermissionsData);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{ margin: "0 40px 40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "1em",
          marginTop: "1%",
          marginBottom: "3%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={back_icon}
              onClick={() => {
                history.push("/manageusers");
              }}
              style={{
                cursor: "pointer",
                height: "22px",
                width: "39px",
                marginRight: "12px",
              }}
              alt="back"
            />
            <Typography variant="h4" className={classes.title}>
              Staff Management
            </Typography>
          </div>
          <Typography
            variant="subtitle1"
            style={{
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "19px",
              letterSpacing: "0.5px",
              color: "#636E95",
              marginBottom: "3%",
            }}
          >
            Add users to this platform and manage what they have access to
          </Typography>
        </div>
      </div>
      {!load && userData ? (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem button>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px 10px",
                }}
              >
                <ListItemIcon>
                  <img
                    alt=""
                    src={userData.profilePic}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 10px",
                }}
              >
                <ListItemText
                  // primary={userData.firstName}
                  className={classes.listname}
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#0A1629",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#0A1629",
                      margin: 0,
                    }}
                  >
                    {userData.firstName?.charAt(0).toUpperCase() +
                      userData.firstName?.slice(1)}
                  </p>
                </ListItemText>

                <ListItemText>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "19px",

                      color: "#91929E",
                      margin: "-8px 19px 0px",
                    }}
                  >
                    {userData.email}
                  </p>
                </ListItemText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 10px",
                  width: "10%",
                }}
              >
                <ListItemText
                  primary="Gender"
                  className={classes.listname}
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#91929E",
                  }}
                />
                <ListItemText>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#0A1629",
                      margin: "-8px 19px 0px",
                    }}
                  >
                    {userData.gender.charAt(0).toUpperCase() +
                      userData.gender.slice(1)}
                  </p>
                </ListItemText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 10px",
                  width: "13%",
                }}
              >
                <ListItemText
                  primary="Date of joining"
                  className={classes.listname}
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#91929E",
                  }}
                />
                <ListItemText>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#0A1629",
                      margin: "-8px 19px 0px",
                    }}
                  >
                    {moment(userData.dob).format("DD MMM YYYY")}
                  </p>
                </ListItemText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 10px",
                  width: "15%",
                }}
              >
                <ListItemText
                  primary="Position"
                  className={classes.listname}
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#91929E",
                  }}
                ></ListItemText>
                <ListItemText>
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#0A1629",
                      margin: "-8px 19px 0px",
                    }}
                  >
                    {userData.role.charAt(0).toUpperCase() +
                      userData.role.slice(1)}
                  </p>
                </ListItemText>
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "0 10px",
                  width: "23%",
                  marginLeft: "7%",
                }}
              >
                <ListItemText>
                  <button
                    className={classes.butonz}
                    onClick={() => {
                      history.push({
                        pathname: `/manageusers/profile/audit`,
                        state: userData,
                      });
                    }}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#242F57",
                      borderRadius: "14px",
                      border: "none",
                      cursor: "pointer",
                      padding: "10px 20px",
                    }}
                  >
                    Audit Trail
                  </button>
                </ListItemText>
                <ListItemText>
                  {/* <button
                    style={{
                      background: "#F4F9FD",
                      border: "none",
                      borderRadius: "14px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#242F57",
                      height: "32px",
                      minWidth: "128px",
                      marginTop: "30px",
                      marginRight: "20px",
                    }}
                  >
                    Edit
                  </button> */}
                  <EditEmpModal datax={userData} />
                </ListItemText>
              </div>
            </div>
          </ListItem>
        </List>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}

      <div
        className={classes.root}
        // style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={!editedPermissions}
            type="submit"
            onClick={() => handleSavePermissions()}
            variant="contained"
            color="primary"
            style={{ margin: "20px", marginRight: "40px" }}
          >
            Save
          </Button>
        </div>
        <ResponsiveMasonry>
          <Masonry>
            {!load && pageData && pageData.permission ? (
              pageData.permission.map((permission, ind) => {
                return (
                  <div
                    key={ind + "p"}
                    style={{
                      width: "320px",
                      padding: "20px",
                      marginLeft: "20px",
                      marginTop: "20px",
                      background: "#FFFFFF",
                      border: "1px solid rgba(0, 0, 0, 0.05)",
                      boxSizing: "border-box",
                      borderRadius: "8px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0",
                        background: "#FFFFFF",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "18px",
                        lineHeight: "22px",
                        color: "#636E95",
                      }}
                    >
                      {permission.functionality.charAt(0).toUpperCase() +
                        permission.functionality.slice(1)}
                    </p>
                    {permission.module.map((item, i) => {
                      if (item.moduleHeader !== "staff management") {
                        return (
                          <div
                            key={i + "m"}
                            style={{
                              marginTop: "20px",
                              borderTop: "1px solid rgba(0, 0, 0, 0.05)",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "#636E95",
                                }}
                              >
                                {item.moduleHeader.charAt(0).toUpperCase() +
                                  item.moduleHeader.slice(1)}
                              </p>
                              <span
                                style={{
                                  width: "130px",
                                  display: "flex",
                                  placeContent: "flex-end",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    color: "#2F80ED",
                                    marginRight: "7%",
                                  }}
                                >
                                  Select All
                                </p>
                                <FormControl>
                                  <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                      // value="view"
                                      style={{ margin: "0" }}
                                      control={
                                        <Checkbox
                                          // name="isView"
                                          // checked={subModule.isView}
                                          onChange={(event) =>
                                            selectAllFromModule(
                                              event,
                                              permission.functionality,
                                              item.moduleHeader
                                            )
                                          }
                                          color="primary"
                                        />
                                      }
                                      labelPlacement="top"
                                    />
                                  </FormGroup>
                                </FormControl>
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "23px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  color: "#636E95",
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  color: "#636E95",
                                  margin: "0 10px",
                                }}
                              >
                                View
                              </p>
                              <p
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  color: "#636E95",
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                  color: "#636E95",
                                  margin: "0 10px",
                                }}
                              >
                                Edit
                              </p>
                            </div>

                            {item.subModule.map((subModule, index) => {
                              return (
                                <div
                                  key={index + "sm"}
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    color: "#636E95",
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{
                                        width: "170px",
                                        fontSize: "14px",
                                        lineHeight: "17px",
                                        color: "#636E95",
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: "normal",
                                        fontSize: "14px",
                                        lineHeight: "17px",
                                        color: "#636E95",
                                      }}
                                    >
                                      {subModule.moduleTitle
                                        .charAt(0)
                                        .toUpperCase() +
                                        subModule.moduleTitle.slice(1)}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginLeft: "68%",
                                      marginTop: "-15%",
                                    }}
                                  >
                                    <FormControl>
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="view"
                                          style={{ margin: "0" }}
                                          control={
                                            <Checkbox
                                              name="isView"
                                              checked={subModule.isView}
                                              onChange={(event) =>
                                                handleChange(
                                                  event,
                                                  permission.functionality,
                                                  item.moduleHeader,
                                                  subModule.moduleTitle
                                                )
                                              }
                                              color="primary"
                                            />
                                          }
                                          labelPlacement="top"
                                        />
                                        <FormControlLabel
                                          value="edit"
                                          style={{ margin: "0" }}
                                          control={
                                            <Checkbox
                                              name="isEdit"
                                              checked={subModule.isEdit}
                                              onChange={(event) =>
                                                handleChange(
                                                  event,
                                                  permission.functionality,
                                                  item.moduleHeader,
                                                  subModule.moduleTitle
                                                )
                                              }
                                              color="primary"
                                            />
                                          }
                                          labelPlacement="top"
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })
            ) : (
              <></>
              // <div style={{ display: "flex", justifyContent: "center" }}>
              //   <CircularProgress />
              // </div>
            )}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
}
