import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function Card5(props) {
  const classes = useStyles();
  var ID = props.location.state;
  console.log("data-idle", ID);
  return (
    <div style={{ margin: "30px" }}>
      <h1
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "26px",
          lineHeight: "36px",
          letterSpacing: "0.5px",
          color: "#242F57",
        }}
      >
        Sessions Done / Scheduled
      </h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Task Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="center">From Time</TableCell>
              <TableCell align="center">To Time</TableCell>
              <TableCell align="center">Tool</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Priority</TableCell>

              <TableCell align="center">Created By</TableCell>
              <TableCell align="center">Task Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ID &&
              ID.map((item) => {
                return (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {item.taskName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(item.dateTime).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      {moment(item.timeFrom).format("h:mm a")}
                    </TableCell>
                    <TableCell align="center">
                      {moment(item.timeTo).format("h:mm a")}
                    </TableCell>
                    <TableCell align="center">{item.via}</TableCell>{" "}
                    <TableCell align="center">{item.description}</TableCell>{" "}
                    <TableCell align="center">{item.priority}</TableCell>
                    <TableCell align="center">
                      {item._createdBy && item._createdBy?.firstName}
                    </TableCell>
                    <TableCell align="center">{item.taskStatus}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
