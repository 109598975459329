import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import progressIcon from "../../assets/progressIcon.png";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#242F57",
    marginBottom: "10px",
  },
  container: {
    width: "330px",
    border: "1px solid #EAEDF7",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    padding: "20px 25px",
    margin: "20px",
  },
  pointer: {
    height: "16px",
    width: "16px",
    background: "#FFC800",
    borderRadius: "4px",
    marginRight: "20px",
  },
  listText: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
    color: "#242F57",
  },
}));

export default function ViewSubmissions() {
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [data, setData] = useState(["Assignment", "Polls", "Survey"]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>View Submissions</div>
      {data.map((item) => {
        return (
          <div
            style={{
              borderTop: "1px solid #EAEDF7",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "15px 0",
            }}
          >
            <p className={classes.listText}>{item}</p>
            <div
              style={{
                border: "1px solid rgba(36, 47, 87, 0.5)",
                boxSizing: "border-box",
                borderRadius: "8px",
                padding: "6px 25px",
                cursor: "pointer",
              }}
            >
              <p
                className={classes.listText}
                style={{ color: "#636D96", margin: 0 }}
              >
                VIEW
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
