import "date-fns";
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
export default function Datep({
  changeevent,
  changeevent2,
  data,
  error,
  value,
}) {
  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState(new Date());

  useEffect(() => {
    function setData() {
      if (data) {
        setSelectedDate(moment(data).format("DD MMM YYYY"));
      }
    }
    setData();
  }, []);
  const handleDateChange = (date) => {
    if (!pale_username(date)) {
      setSelectedDate(date);
    }

    // console.log(date.toLocaleString());
    // console.log(moment(date));
    // console.log(date.toLocaleString());
    if (data) {
      changeevent(date.toISOString());
    } else {
      changeevent2(moment(date).format("DD MMM YYYY h:mm"));
      // changeevent2(moment(date).format("DD MMM YYYY HH:MM:SS"));
    }
  };

  const pale_username = (key) => {
    if (
      (key.charCode < 48 || key.charCode > 57) && //numbers
      (key.charCode < 65 || key.charCode > 90) && // AB
      (key.charCode < 97 || key.charCode > 122) && // ab
      key.charCode !== 36 && // $
      key.charCode !== 38 && // &
      (key.charCode < 40 || key.charCode > 41) && // ()
      (key.charCode < 45 || key.charCode > 46) && // -.
      key.charCode !== 95 && // _
      key.charCode !== 127 && // del
      key.charCode !== 8 && // BackSpace
      key.charCode !== 46
    )
      return false;
  };
  const handleTimeChange = (date) => {
    setSelectedTime(date);
    if (data) {
      changeevent(moment(date).format("DD MMM YYYY"));
    } else {
      changeevent2(moment(date).format("DD MMM YYYY h:mm"));
    }
    console.log(date);
    //changeevent(date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <KeyboardDatePicker
          margin="normal"
          error={!error}
          onKeyPress={(e) => pale_username(e)}
          name="dob"
          helperText={""}
          label="Date of joining"
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "20px",
            // fontFeatureSettings: "'salt' on",
            color: "#7D8592",
            width: "220px",
            borderRadius: "14px",
          }}
          variant="dialog"
          inputVariant="outlined"
          id="date-picker-dialog"
          placeholder="Select Date"
          // inputFormat={(date) => moment(date).format("MM-DD-YYYY")}
          value={selectedDate}
          onChange={(e) => handleDateChange(e)}
          // KeyboardButtonProps={{
          //   "aria-label": "change date",
          // }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
