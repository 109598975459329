import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import rootReducer from './reducers';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import promise from "redux-promise-middleware";
import logger from 'redux-logger';



// const middlewareRouter = routerMiddleware(browserHistory);
// const middleware = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_(applyMiddleware(  thunkMiddleWare));
const middleware = (applyMiddleware(thunkMiddleWare));

var initialState = {

}

const localState = localStorage.getItem('appState')
const persistedState = Object.assign(localState ? JSON.parse(localState):initialState);

const store = createStore(
    rootReducer,
    persistedState,
    middleware
);

store.subscribe(() => {
    localStorage.setItem('appState', JSON.stringify(store.getState()))
});

window.CMSStore = store;

export default store;
