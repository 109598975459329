import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import progressIcon from "../../assets/progressIcon.png";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#242F57",
    padding: "20px 25px 0 25px",
  },
  container: {
    width: "330px",
    border: "1px solid #EAEDF7",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    // padding: "20px 25px",
    margin: "20px",
  },
  pointer: {
    height: "16px",
    width: "16px",
    background: "#FFC800",
    borderRadius: "4px",
    marginRight: "20px",
  },
  listText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#636D96",
  },
}));

export default function UserLeveAnalytics() {
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [data, setData] = useState(["48", "78", "48", "68", "39", "106"]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>User Level Analytics</div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          height: "135px",
          padding: "0 25px",
          background: " #FFFFFF",
          textAlign: "center",
          borderBottom: "1px solid #EAEDF7",
          boxShadow: "0px 2px 4px rgba(28, 41, 90, 0.0367952)",
        }}
      >
        {data.map((item) => {
          return (
            <div
              style={{
                width: "14px",
                height: item + "px",
                background: "#56AAFF",
                borderRadius: "4px 4px 0px 0px",
              }}
            ></div>
          );
        })}
      </div>
      <div
        style={{
          padding: "0 25px 0 25px",
        }}
      >
        <p
          style={{
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "26px",
            letterSpacing: "0.5px",
            color: "#97A0C3",
          }}
        >
          Users
        </p>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "22px",
            lineHeight: "28px",
            letterSpacing: "0.5px",
            color: "#242F57",
          }}
        >
          3241
        </span>
      </div>
    </div>
  );
}
