import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CheckboxList from "./ListItems";
import ScheduleList from "./ScheduleList";
import { baseApi } from "../../Utils/config";
import { CircularProgress } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  section: {
    flexGrow: 1,
    padding: "1.5em",
    borderRadius: "10px",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "3em",
  },
  taskTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#242F57",
    marginBottom: "1em",
  },
  tabStyle: {
    boxShadow: "unset",
    backgroundColor: "#F4F7FC",
    color: "#1A202C",
  },
}));

export default function UserTaskLists({
  handleTaskUpdate,
  dayWiseActive,
  updateList,
  selectedDate,
  userDetails,
  taskDetails,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  var user = JSON.parse(localStorage.getItem("user"));
  const [taskList, setTaskList] = useState("");
  const [loading, setLoading] = useState(true);
  const [scheduleList, setScheduleList] = useState("");
  var apiBaseUrl = baseApi;

  useEffect(() => {
    setTaskList(taskDetails.task);
    setScheduleList(taskDetails.schedule);
    setLoading(false);
  }, [updateList, taskDetails]);

  function handleTasksUpdate(update = false) {
    handleTaskUpdate(update);
  }

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div
        className={classes.section}
        style={{
          marginTop: "1em",
        }}
      >
        <Typography
          className={classes.taskTitle}
          component="div"
          variant="subtitle1"
        >
          Tasks
        </Typography>
        <CheckboxList
          handleTaskUpdate={handleTasksUpdate}
          userDetails={userDetails}
          taskList={taskList}
        />
      </div>
      <div className={classes.section}>
        <Typography
          style={{ marginTop: "1em" }}
          className={classes.taskTitle}
          component="div"
          variant="subtitle1"
        >
          Schedule
        </Typography>
        <ScheduleList userDetails={userDetails} scheduleList={scheduleList} />
      </div>
    </div>
  );
}
