export const logout = () => {
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("apiBaseUrl");
  window.localStorage.removeItem("timestamp");
  window.localStorage.removeItem("appState");
};

export const isLogin = () => {
  if (window.localStorage.getItem("user")) {
    return true;
  }

  return false;
};
