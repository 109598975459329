import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import progressIcon from "../../assets/progressIcon.png";
import paidusersprogressicon from "../../assets/paidusersprogressicon.png";
import noofassessicon from "../../assets/noofassessicon.png";
import idletimeprogressicon from "../../assets/idletimeprogressicon.png";
import { baseApi } from "../../Utils/config";

import PropTypes from "prop-types";
import clsx from "clsx";
import TablePagination from "@material-ui/core/TablePagination";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
  { id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
  { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
  { id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    // width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    maxWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    // float: "left",
    marginTop: "1%",
    marginLeft: "2%",
    lineHeight: "48px",
    fontSize: "24px",
  },
  button: {
    marginLeft: "39% !important",
    marginTop: "-3%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    background: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: "0px 5px 8px -1px rgba(50, 50, 71, 0.1)",
    padding: "20px 30px",
    margin: "20px",
    marginLeft: "0px",
  },
  taskContainer: {
    width: "auto",
    background: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: "0px 5px 8px -1px rgba(50, 50, 71, 0.1)",
    padding: "20px 30px",
    margin: "20px",
    marginLeft: "0px",
  },
  flexContainer: {
    display: "flex",
    // width: "260px",
    cursor: "pointer",
    alignItems: "center",
    background: "#FFFFFF",
    // borderRadius: "16px",
    // boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    // padding: "20px 30px",
    // marginBottom: "20px",
  },
  progressColor: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFEEF1",
    borderRadius: "8px",
    height: "46px",
    width: "46px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#38CB89",
  },
}))(LinearProgress);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    position: "absolute",
    padding: "20px",
    paddingRight: "25px",
    top: `50%`,
    left: `50%`,
    maxHeight: "500px",
    overflow: "auto",
    background: "#ffffff",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

async function getWidgetDetails(path, selectedDate, dayWiseActive = "today") {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  var result = "";
  await axios({
    method: "get",
    url:
      apiBaseUrl +
      path +
      `?${
        selectedDate
          ? `from=${selectedDate.fromDate}&to=${
              selectedDate.toDate ? selectedDate.toDate : selectedDate.fromDate
            }`
          : "query=" + dayWiseActive
      }`,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": user.jT,
    },
  })
    .then((response, error) => {
      console.log("result", response.data.data);
      result = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return result;
}

function UserAttendanceReport({
  admin,
  analyticsData,
  selectedDate,
  userDetails,
  dayWiseActive,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [dense, setDense] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = async () => {
    if (admin) {
      let data = await getWidgetDetails("users/loggedIn/all", selectedDate);
      console.log("user-att", data);
      // history.push("/dashboard/card-details");
      history.push({
        pathname: `/dashboard/userattendance`,
        state: data,
      });
      // setTaskDetails(data);
    } else {
      let data = await getWidgetDetails(
        `users/loggedIn/${userDetails._id}`,
        selectedDate,
        dayWiseActive
      );
      console.log("user-att", data);
      // history.push("/dashboard/card-details");
      history.push({
        pathname: `/dashboard/userattendance`,
        state: data,
      });
    }
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTaskDetails("");
    setOpen(false);
  };

  useEffect(() => {
    if (taskDetails) {
      handleOpenModal();
    }
  }, [taskDetails]);

  return (
    <div className={classes.container} style={{ height: "120px" }}>
      <div onClick={handleOpen} className={classes.flexContainer}>
        <div className={classes.progressColor}>
          <img src={progressIcon} alt="" />
        </div>
        <div>
          <p
            style={{
              color: "#7A7A9D",
              margin: "5px",
              marginLeft: "1em",
              fontWeight: "bold",
            }}
          >
            {"Staff Attendance"}
          </p>
          <h4 style={{ margin: "0", marginLeft: "1em" }}>
            {analyticsData.userCount
              ? analyticsData.loggedinDays + "/" + analyticsData.userCount
              : analyticsData.loggedinDays}
          </h4>
        </div>
        {/* <span
          style={{
            margin: "0 10px",
            alignSelf: "flex-end",
            color: "#66CB9F",
            fontWeight: "bold",
          }}
        >
          (+5%)
        </span> */}
      </div>
      <Modal open={open} onClose={handleClose}>
        <Paper className={classes.paper}>
          <div style={modalStyle} className={classes.paper}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Staff Attendance
            </Typography>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align={"left"} padding={"default"}>
                      User Name
                    </TableCell>
                    <TableCell align={"left"} padding={"default"}>
                      Email
                    </TableCell>
                    <TableCell align={"left"} padding={"default"}>
                      Role
                    </TableCell>
                    <TableCell align={"right"} padding={"default"}>
                      Logged In ime
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskDetails && taskDetails.length ? (
                    taskDetails.map((row, i) => (
                      <TableRow hover tabIndex={-1} key={i + "row"}>
                        <TableCell align={"left"}>
                          {row?._userId?.firstName}
                        </TableCell>
                        <TableCell align={"left"}>
                          {row?._userId?.email}
                        </TableCell>
                        <TableCell align={"left"}>
                          {row?._userId?.role}
                        </TableCell>
                        <TableCell align={"right"}>
                          {moment(row.loggedInTime).format("DD MMM YYYY h:mm")}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TableCell
                        align={"center"}
                        style={{
                          border: "none",
                        }}
                      >
                        <h2
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "30px",
                          }}
                        >
                          No data found
                        </h2>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
function OverAllIdleTimeReport({
  admin,
  analyticsData,
  selectedDate,
  userDetails,
  dayWiseActive,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = async () => {
    if (admin) {
      let data = await getWidgetDetails("users/idleTime/all", selectedDate);
      console.log("responseIDLE-------", data);
      // history.push("/dashboard/idletime");
      history.push({
        pathname: `/dashboard/idletime`,
        state: data,
        idle: Math.floor(analyticsData.ideltimeCount[0]?.count / 60),
      });

      setTaskDetails(data);
    } else {
      let data = await getWidgetDetails(
        `users/idleTime/${userDetails._id}`,
        selectedDate,
        dayWiseActive
      );
      console.log("responseIDLE-------", data);
      // history.push("/dashboard/idletime");
      history.push({
        pathname: `/dashboard/idletime`,
        state: data,
        idle: Math.floor(analyticsData.ideltimeCount[0]?.count / 60),
      });

      setTaskDetails(data);
    }
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTaskDetails("");
    setOpen(false);
  };

  useEffect(() => {
    if (taskDetails) {
      handleOpenModal();
    }
  }, [taskDetails]);

  return (
    <div className={classes.container} style={{ height: "120px" }}>
      <div onClick={handleOpen} className={classes.flexContainer}>
        <div
          className={classes.progressColor}
          style={{
            backgroundColor: "#E1E8FF",
          }}
        >
          <img src={idletimeprogressicon} alt="" />
        </div>
        <div>
          <p
            style={{
              color: "#7A7A9D",
              margin: "5px",
              marginLeft: "1em",
              fontWeight: "bold",
            }}
          >
            {"Staff Idle Time"}
          </p>
          <h4 style={{ margin: "0", marginLeft: "1em" }}>
            {analyticsData.ideltimeCount[0]
              ? Math.floor(analyticsData.ideltimeCount[0]?.count / 60) + " mins"
              : 0 + " mins"}
          </h4>
        </div>
        {/* <span
          style={{
            margin: "0 10px",
            alignSelf: "flex-end",
            color: "#66CB9F",
            fontWeight: "bold",
          }}
        >
          (+5%)
        </span> */}
      </div>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Staff Idle Time
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Idle Time</TableCell>
                  <TableCell>From Time</TableCell>
                  <TableCell>To Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskDetails && taskDetails.length ? (
                  taskDetails
                    .filter((item) => Math.floor(item.idleTime / 60) > 30)
                    .map((row, i) => (
                      <TableRow key={i + "row"}>
                        <TableCell>{row?._userId?.firstName}</TableCell>
                        <TableCell>{row?._userId?.role}</TableCell>
                        <TableCell>{Math.floor(row.idleTime / 60)}</TableCell>
                        <TableCell>
                          {moment(row.fromTime).format("DD MMM YYYY h:mm a")}
                        </TableCell>
                        <TableCell>
                          {moment(row.toTime).format("DD MMM YYYY h:mm a")}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#0A1629",
                          margin: "30px",
                        }}
                      >
                        No data found
                      </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
}
function PaidUsersReport({ admin, analyticsData, selectedDate }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const history = useHistory();

  const handleOpen = async () => {
    if (admin) {
      let data = await getWidgetDetails("users/paid/all", selectedDate);
      console.log("response", data);
      history.push({
        pathname: `/dashboard/paiduser`,
        state: data,
      });
      // setTaskDetails(data);
      // <PrivateRoute path="/dashboard/paiduser" exact component={Card3} />
      // <PrivateRoute path="/dashboard/taskdone" exact component={Card4} />
    } else return;
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTaskDetails("");
    setOpen(false);
  };

  useEffect(() => {
    if (taskDetails) {
      handleOpenModal();
    }
  }, [taskDetails]);

  return (
    <div className={classes.container} style={{ height: "120px" }}>
      <div onClick={handleOpen} className={classes.flexContainer}>
        <div
          className={classes.progressColor}
          style={{
            backgroundColor: "#FFEDE3",
          }}
        >
          <img src={paidusersprogressicon} alt="" />
        </div>
        <div>
          <p
            style={{
              color: "#7A7A9D",
              margin: "5px",
              marginLeft: "1em",
              fontWeight: "bold",
            }}
          >
            {"Number of Paid Users"}
          </p>
          <h4 style={{ margin: "0", marginLeft: "1em" }}>
            {analyticsData.paidCout}
          </h4>
        </div>
        {/* <span
          style={{
            margin: "0 10px",
            alignSelf: "flex-end",
            color: "#66CB9F",
            fontWeight: "bold",
          }}
        >
          (+5%)
        </span> */}
      </div>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Number of Paid Users
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>User name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>Child Name</TableCell>
                  <TableCell>Child Age</TableCell>
                  <TableCell>Amount Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskDetails && taskDetails.length ? (
                  taskDetails.map((row, i) => (
                    <TableRow key={i + "row"}>
                      <TableCell>{row.notes.your_name}</TableCell>
                      <TableCell>{row.notes.email}</TableCell>
                      <TableCell>{row.notes.whatsapp_phone_number}</TableCell>
                      <TableCell>{row.notes.childs_name}</TableCell>
                      <TableCell>{row.notes.childs_age}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#0A1629",
                          margin: "30px",
                        }}
                      >
                        No data found
                      </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
}
function NoofAsCards({
  admin,
  analyticsData,
  selectedDate,
  dayWiseActive,
  userDetails,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const history = useHistory();

  const handleOpen = async () => {
    if (admin) {
      let data = await getWidgetDetails("users/assessments", selectedDate);
      console.log("response", data);
      history.push({
        pathname: `/dashboard/noofas`,
        state: data,
      });
      // setTaskDetails(data);

      // <PrivateRoute path="/dashboard/paiduser" exact component={Card3} />
      // <PrivateRoute path="/dashboard/taskdone" exact component={Card4} />
    } else {
      let data = await getWidgetDetails(
        `users/assessments/${userDetails._id}`,
        selectedDate,
        dayWiseActive
      );
      console.log("response", data);
      history.push({
        pathname: `/dashboard/noofas`,
        state: data.schedule,
      });
    }
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTaskDetails("");
    setOpen(false);
  };

  useEffect(() => {
    if (taskDetails) {
      handleOpenModal();
    }
  }, [taskDetails]);

  return (
    <div className={classes.container} style={{ height: "120px" }}>
      <div onClick={handleOpen} className={classes.flexContainer}>
        <div
          className={classes.progressColor}
          style={{
            backgroundColor: "#FFEDE3",
          }}
        >
          <img src={noofassessicon} alt="" />
        </div>
        <div>
          <p
            style={{
              color: "#7A7A9D",
              margin: "5px",
              marginLeft: "1em",
              fontWeight: "bold",
            }}
          >
            {"Number of Assesments Taken"}
          </p>
          <h4 style={{ margin: "0", marginLeft: "1em" }}>
            {analyticsData.assessmentCount}
          </h4>
        </div>

        {/* <span
          style={{
            margin: "0 10px",
            alignSelf: "flex-end",
            color: "#66CB9F",
            fontWeight: "bold",
          }}
        >
          (+5%)
        </span> */}
      </div>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Number of Assessments
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Parameter Type</TableCell>
                  <TableCell>progress</TableCell>
                  <TableCell>To Time</TableCell>
                  <TableCell>Tool</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Task Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskDetails && taskDetails.length ? (
                  taskDetails.map((row, i) => (
                    <TableRow key={i + "row"}>
                      <TableCell>{row.displayName}</TableCell>
                      <TableCell>{row.parameterType}</TableCell>
                      <TableCell>{row.progress}</TableCell>
                      <TableCell>
                        {moment(row.timeTo).format("h:mm a")}
                      </TableCell>
                      <TableCell>
                        <a href={row.url} target="_blank">
                          {row.via}
                        </a>
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.priority}</TableCell>
                      <TableCell>{row._createdBy?.firstName}</TableCell>
                      <TableCell>{row.taskStatus}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#0A1629",
                          margin: "30px",
                        }}
                      >
                        No data found
                      </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
}
function TasksReportCard({
  admin,
  analyticsData,
  selectedDate,
  userDetails,
  dayWiseActive,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const history = useHistory();

  const handleOpen = async () => {
    if (admin) {
      let data = await getWidgetDetails("task/all", selectedDate);
      console.log("response", data);
      history.push({
        pathname: `/dashboard/taskdone`,
        state: data,
      });
      // setTaskDetails(data);
      // <PrivateRoute path="/dashboard/paiduser" exact component={Card3} />
      // <PrivateRoute path="/dashboard/taskdone" exact component={Card4} />
    } else {
      let data = await getWidgetDetails(
        `task/list/users/${userDetails._id}`,
        selectedDate,
        dayWiseActive
      );
      console.log("response", data);
      history.push({
        pathname: `/dashboard/taskdone`,
        state: data.task,
      });
    }
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTaskDetails("");
    setOpen(false);
  };

  useEffect(() => {
    if (taskDetails) {
      handleOpenModal();
    }
  }, [taskDetails]);

  return (
    <div className={classes.taskContainer}>
      <div onClick={handleOpen} className={classes.flexContainer}>
        <div>
          <p
            style={{
              color: "#7A7A9D",
              margin: "5px",
              marginLeft: "1em",
              fontWeight: "bold",
            }}
          >
            Tasks Done / Allocated
            {/* {analyticsData.TasksAllocated} */}
          </p>
          <h4 style={{ margin: "0", marginLeft: "1em" }}>
            {(analyticsData.taskCount[0]?.taskCompleted.length
              ? analyticsData.taskCount[0]?.taskCompleted[0]?.completed
              : 0) +
              "/" +
              (analyticsData.taskCount[0]?.totalTask[0]
                ? analyticsData.taskCount[0]?.totalTask[0]["Total Assigned"]
                : 0)}
          </h4>
        </div>
        {/* <span
          style={{
            margin: "0 10px",
            alignSelf: "flex-end",
            color: "#66CB9F",
            fontWeight: "bold",
          }}
        >
          (+5%)
        </span> */}
      </div>
      <BorderLinearProgress
        style={{ marginTop: "20px" }}
        variant="determinate"
        value={
          analyticsData.taskCount[0]?.totalTask.length === 0
            ? 0
            : ((analyticsData.taskCount[0]?.taskCompleted.length
                ? analyticsData.taskCount[0]?.taskCompleted[0]?.completed
                : 0) /
                (analyticsData.taskCount[0]?.totalTask[0]
                  ? analyticsData.taskCount[0]?.totalTask[0]["Total Assigned"]
                  : 0)) *
              100
        }
      />
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Tasks Done / Allocated
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Task Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskDetails && taskDetails.length ? (
                  taskDetails.map((row, i) => (
                    <TableRow key={i + "row"}>
                      <TableCell>{row.taskName}</TableCell>
                      <TableCell>
                        {moment(row.dateTime).format("DD MMM")}
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.priority}</TableCell>
                      <TableCell>{row._createdBy?.firstName}</TableCell>
                      <TableCell>{row._assignedTo[0]?.firstName}</TableCell>
                      <TableCell>{row.taskStatus}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#0A1629",
                          margin: "30px",
                        }}
                      >
                        No data found
                      </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
}

function SessionsReportCard({
  admin,
  analyticsData,
  selectedDate,
  dayWiseActive,
  userDetails,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [taskDetails, setTaskDetails] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const history = useHistory();

  const handleOpen = async () => {
    if (admin) {
      let data = await getWidgetDetails("task/schedule", selectedDate);
      console.log("response", data);
      history.push({
        pathname: `/dashboard/sched`,
        state: data,
      });
      // setTaskDetails(data);

      // <PrivateRoute path="/dashboard/paiduser" exact component={Card3} />
      // <PrivateRoute path="/dashboard/taskdone" exact component={Card4} />
    } else {
      let data = await getWidgetDetails(
        `task/list/users/${userDetails._id}`,
        selectedDate,
        dayWiseActive
      );
      console.log("response", data);
      history.push({
        pathname: `/dashboard/sched`,
        state: data.schedule,
      });
    }
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTaskDetails("");
    setOpen(false);
  };

  useEffect(() => {
    if (taskDetails) {
      handleOpenModal();
    }
  }, [taskDetails]);

  return (
    <div className={classes.taskContainer}>
      <div onClick={handleOpen} className={classes.flexContainer}>
        <div>
          <p
            style={{
              color: "#7A7A9D",
              margin: "5px",
              marginLeft: "1em",
              fontWeight: "bold",
            }}
          >
            {"Sessions Done / Scheduled"}
          </p>
          <h4 style={{ margin: "0", marginLeft: "1em" }}>
            {(analyticsData.taskCount[0]?.completedSession[0]
              ? analyticsData.taskCount[0]?.completedSession[0]
                  ?.completedsession
              : 0) +
              "/" +
              (analyticsData.taskCount[0]?.totalSession[0]
                ? analyticsData.taskCount[0]?.totalSession[0]?.totalsession
                : 0)}
          </h4>
        </div>
        {/* <span
          style={{
            margin: "0 10px",
            alignSelf: "flex-end",
            color: "#66CB9F",
            fontWeight: "bold",
          }}
        >
          (+5%)
        </span> */}
      </div>
      <BorderLinearProgress
        variant="determinate"
        style={{ marginTop: "20px" }}
        value={
          analyticsData.taskCount[0]?.totalSession.length === 0
            ? 0
            : ((analyticsData.taskCount[0]?.completedSession[0]
                ? analyticsData.taskCount[0]?.completedSession[0]
                    ?.completedsession
                : 0) /
                (analyticsData.taskCount[0]?.totalSession[0]
                  ? analyticsData.taskCount[0]?.totalSession[0]?.totalsession
                  : 0)) *
              100
        }
      />
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Number of Assessments
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>From Time</TableCell>
                  <TableCell>To Time</TableCell>
                  <TableCell>Tool</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Task Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskDetails && taskDetails.length ? (
                  taskDetails.map((row, i) => (
                    <TableRow key={i + "row"}>
                      <TableCell>{row.taskName}</TableCell>
                      <TableCell>
                        {moment(row.dateTime).format("DD MMM")}
                      </TableCell>
                      <TableCell>
                        {moment(row.timeFrom).format("h:mm a")}
                      </TableCell>
                      <TableCell>
                        {moment(row.timeTo).format("h:mm a")}
                      </TableCell>
                      <TableCell>
                        <a href={row.url} target="_blank">
                          {row.via}
                        </a>
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.priority}</TableCell>
                      <TableCell>{row._createdBy?.firstName}</TableCell>
                      <TableCell>{row.taskStatus}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#0A1629",
                          margin: "30px",
                        }}
                      >
                        No data found
                      </h2>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Modal>
    </div>
  );
}

export {
  UserAttendanceReport,
  OverAllIdleTimeReport,
  PaidUsersReport,
  TasksReportCard,
  SessionsReportCard,
  NoofAsCards,
};
