import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EnhancedTable from "../Table/Table";
import SortIcon from "../../assets/Sort.png";
import filterIcon from "../../assets/filter.png";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: 240,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const alphabets = ["A-Z", "Z-A"];
const names = [
  "Realtion",
  "expert score",
  "Adjusted Score",
  "Cumulative score",
];

function getStyles(name, filter, theme) {
  return {
    fontWeight:
      filter === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    background: filter === name ? "rgba(0, 87, 255, 0.8)" : "",
    color: filter === name ? "#ffffff" : "",
  };
}

const TagsTable = () => {
  const classes = useStyles();
  const theme = useTheme();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [filter, setFilter] = React.useState({});
  const [filterMenu, setFilterMenu] = React.useState(false);
  const [personName, setPersonName] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorFilter, setAnchorFilter] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handlePopover = () => {
    setAnchorEl(null);
    setAnchorFilter(null);
  };

  const handleFilterItems = (sort, name) => {
    setFilter(
      Object.assign(filter, {
        [sort]: name,
      })
    );
    setPersonName([...personName, name]);
  };
  const handleFilterMenu = () => {
    setFilterMenu(!filterMenu);
  };

  const openPopover = Boolean(anchorEl);
  const openPopFilter = Boolean(anchorFilter);

  const id = openPopover ? "simple-popover" : undefined;
  const filterId = openPopFilter ? "simple-popover" : undefined;
  return (
    <Container maxWidth="lg">
      <main className={classes.content}>
        <div
          id="filter-container"
          style={{ position: "relative", margin: "1em" }}
          className={classes.formControl}
        >
          <Button
            style={{ float: "right", margin: "1em" }}
            aria-describedby={id}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            <img src={filterIcon} alt="filter" />
          </Button>
          <Popover
            className={classes.formControl}
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleFilterMenu}
            >
              <h3 style={{ marginLeft: "1em" }}>Pick field to sort</h3>
              <ArrowDropDownIcon />
            </div>
            {filterMenu &&
              names.map((name) => (
                <MenuItem
                  onClick={() => handleFilterItems("sortby", name)}
                  key={name}
                  value={name}
                  style={getStyles(name, filter.sortby, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            {alphabets.map((name) => (
              <MenuItem
                onClick={() => handleFilterItems("orderby", name)}
                key={name}
                value={name}
                style={getStyles(name, filter.orderby, theme)}
              >
                {name}
              </MenuItem>
            ))}
            <Button
              style={{ marginLeft: "1em", marginBottom: "1em" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handlePopover}
            >
              Apply
            </Button>
          </Popover>
        </div>
        <div
          id="sort-container"
          style={{ position: "relative", margin: "1em" }}
          className={classes.formControl}
        >
          <Button
            style={{ float: "right", margin: "1em" }}
            aria-describedby={filterId}
            variant="contained"
            color="primary"
            onClick={handleFilterClick}
          >
            <img src={SortIcon} alt="sort" />
          </Button>
          <Popover
            className={classes.formControl}
            id={filterId}
            open={openPopFilter}
            anchorEl={anchorFilter}
            onClose={handlePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleFilterMenu}
            >
              <p style={{ marginLeft: "1em" }}>where</p>
              <h3 style={{ marginLeft: "1em" }}>location</h3>
              <ArrowDropDownIcon />
              <h3 style={{ marginLeft: "1em" }}>contains</h3>
              <ArrowDropDownIcon />
            </div>
            {filterMenu &&
              names.map((name) => (
                <MenuItem
                  onClick={() => handleFilterItems("sortby", name)}
                  key={name}
                  value={name}
                  style={getStyles(name, filter.sortby, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            <p style={{ marginLeft: "1em" }}>+Add Filter</p>
          </Popover>
        </div>
        <div className={classes.appBarSpacer} />
        {/* <EnhancedTable /> */}
      </main>
    </Container>
  );
};

export default TagsTable;
