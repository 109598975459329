import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ContentTable from "./ContentTable";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { baseApi } from "../../Utils/config";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const useStyles = makeStyles((theme) => ({
  gridList: {
    textTransform: "capitalize",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",

    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginRight: "2%",
    width: "260px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
let AUTH_ERROR_STATUS_CODES = [401, 402, 403];
export default function ProductDocumentation() {
  var apiBaseUrl = baseApi;
  const [contentX, setcontentX] = useState();

  const [dashboardData, setDashboardData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState();
  var user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    //  document.body.style.zoom = "70%";
    fetchData();
  }, []);
  function fetchData() {
    const res = axios({
      method: "get",
      url: apiBaseUrl + "documentation" + "/productlevel/" + user.id,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    });
    res
      .then((res) => {
        setDashboardData(res.data.data);
        setFilteredData(res.data.data);
        console.log("doc", res.data);
        setcontentX(res.data.message);

        let userData = user;
        userData.menu = res.data.data.menus;
        window.localStorage.setItem("user", JSON.stringify(userData));
      })
      .catch((err) => {
        if (AUTH_ERROR_STATUS_CODES.includes(err.statusCode) != -1) {
          window.localStorage.removeItem("user");
          window.location = "/login";
        } else {
          alert(err.message);
        }
      });
  }
  const handleChange = (event) => {
    let dashboardList = JSON.parse(JSON.stringify(dashboardData));
    let searchList = dashboardList.data.map((content) => {
      let filter = content.list.filter((item) =>
        item.displayName
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      content.list = filter;
      return content;
    });
    dashboardList.data = searchList;
    setFilteredData(dashboardList);
  };
  return (
    <div className={classes.container}>
      <div
        style={{
          margin: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "4%",
          }}
        >
          <div>
            <Typography
              variant="h4"
              align="left"
              style={{
                fontSize: "24px",
                fontWeight: "500",
                color: "#33343D",
                marginBottom: "10px",
              }}
            >
              Product Documentation
            </Typography>
            <Typography
              variant="p"
              align="left"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "19px",
                textAlign: "center",
                letterSpacing: "0.5px",
                color: "#636E95",
                marginBottom: "3%",
              }}
            >
              Documentation of Product development, business logics and code
            </Typography>
          </div>

          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={handleChange}
            />
          </div> */}
        </div>
        <div className={classes.gridList}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              {
                filteredData &&
                  filteredData &&
                  filteredData.length > 0 &&
                  filteredData
                    .reverse()
                    .map((content, i) => (
                      <ContentTable
                        key={i}
                        content={content}
                        contentX={contentX}
                      />
                    ))
                // : dashboard &&
                //   dashboard.data.length > 0 &&
                //   dashboard.data.map((content, i) => (
                //     <ContentTable key={i} content={content} />
              }
            </Masonry>
          </ResponsiveMasonry>

          {!filteredData && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
