import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    // borderRadius: "3px",
    background: "#ffffff",
    boxShadow: " 4px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  formInput: {},
  divContainer: {
    display: "flex",
    alignItems: "baseline",
  },
}));

export default function GroupedSelect() {
  const classes = useStyles();

  return (
    <div className={classes.divContainer}>
      <FormControl className={classes.formControl}>
        {/* <InputLabel htmlFor="grouped-select">Grouping</InputLabel> */}
        <Select defaultValue="" id="grouped-select">
          <MenuItem value="">
            <em>Nothing Selected</em>
          </MenuItem>
          <ListSubheader>Category 1</ListSubheader>
          <MenuItem value={1}>Option 1</MenuItem>
          <MenuItem value={2}>Option 2</MenuItem>
          <ListSubheader>Category 2</ListSubheader>
          <MenuItem value={3}>Option 3</MenuItem>
          <MenuItem value={4}>Option 4</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        {/* <InputLabel htmlFor="grouped-select">Grouping</InputLabel> */}
        <Select defaultValue="" id="grouped-select">
          <MenuItem value="">
            <em>Nothing Selected</em>
          </MenuItem>
          <ListSubheader>Category 1</ListSubheader>
          <MenuItem value={1}>Option 1</MenuItem>
          <MenuItem value={2}>Option 2</MenuItem>
          <ListSubheader>Category 2</ListSubheader>
          <MenuItem value={3}>Option 3</MenuItem>
          <MenuItem value={4}>Option 4</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formInput}>
        <InputLabel htmlFor="demo-customized-textbox">Text Input</InputLabel>
        <BootstrapInput id="demo-customized-textbox" />
      </FormControl>
    </div>
  );
}

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
