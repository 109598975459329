import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { fade, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const useStyles = makeStyles((theme) => ({
  search: {
    position: "absolute",
    borderRadius: theme.shape.borderRadius,
    border:"2px solid #E7E8F2",
    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: "15% !important",
    marginTop: "47px",
    width: "100%",
    height:"45px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(40),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "primary",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Searchbar = (props) => {
  const { users, filtereduser } = props;
  const history = useHistory();
  const [age, setAge] = React.useState("");
  const [errors, setErrors] = useState();
  const classes = useStyles();

  var currentList = {};
  var newList = {};

  const filterlist = (e) => {
    if (e.target.value !== "") {
      currentList = users;
      newList = currentList.filter((item) => {
        const lc = item.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = users;
    }
    filtereduser(newList);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      {users && (
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={filterlist}
          inputProps={{ "aria-label": "search" }}
        />
      )}
    </div>
  );
};
export default Searchbar;
