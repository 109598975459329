import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { baseApi } from "./Utils/config";
import axios from "axios";
import moment from "moment";
import { logout, isLogin } from "./Utils/logincheck";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
var today;
export default function IdleTimeOutModal({
  showModal,
  handleClose,
  payload,
  todayInit,
}) {
  const classes = useStyles();
  console.log("todayInit", todayInit);

  var user = "";
  if (JSON.parse(localStorage.getItem("user"))) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  var apiBaseUrl = baseApi;
  const [idletime, setidletime] = React.useState(0);
  const [fromtime, setfromtime] = React.useState(0);
  const [totime, settotime] = React.useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handlelogout = () => {
    today = moment(new Date(), "hh:mm:ss a");
    var idleT = today.diff(todayInit, "seconds");
    payload = {
      idleTime: idleT,
      fromTime: todayInit.toLocaleString(),
      toTime: today.toLocaleString(),
      _userId: user.id,
    };
    console.log("payload", payload);
    IdleFunc(payload);
    handleClose();
    setOpen(false);
    logout();
  };
  const handleClosex = () => {
    today = moment(new Date(), "hh:mm:ss a");
    var idleT = today.diff(todayInit, "seconds");
    payload = {
      idleTime: idleT,
      fromTime: todayInit.toLocaleString(),
      toTime: today.toLocaleString(),
      _userId: user.id,
    };
    console.log("payload", payload);
    IdleFunc(payload);
    setOpen(false);
    handleClose();
  };

  const IdleFunc = (payload) => {
    var apiBaseUrl = baseApi;

    axios({
      method: "post",
      url: apiBaseUrl + "users/idleTime",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    })
      .then((response) => {
        handleClose();
        console.log("response", response);
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Hi {user.name ? user.name : user},</h2>
      <p id="simple-modal-description">
        Looks like you are working outside this platform. Please choose an
        option to continue
      </p>
      <button
        style={{
          background: "#4C6FFF",
          borderRadius: "8px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "14px",
          margin: "5px 10px",
          color: "#FFFFFF",
          cursor: "pointer",
          padding: "10px",
          marginLeft: "-1px",
        }}
        onClick={handleClosex}
      >
        Stay
      </button>
      <button
        style={{
          background: "#4C6FFF",
          borderRadius: "8px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "14px",
          color: "#FFFFFF",
          margin: "5px 10px",
          cursor: "pointer",
          padding: "10px",
        }}
        onClick={handlelogout}
      >
        Logout
      </button>

      <IdleTimeOutModal />
    </div>
  );

  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleClosex}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
