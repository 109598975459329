import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Typography, Button, Menu, TextField } from "@material-ui/core";
import shareIcon from "../../assets/share.png";
import closeShare from "../../assets/closeShare.png";
import Add from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import SearchIcon from "@material-ui/icons/Search";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { InputBase, fade } from "@material-ui/core";
import filterIcon from "../../assets/filter.png";
import Popover from "@material-ui/core/Popover";
import SortIcon from "../../assets/Sort.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";
import ViewViewsTable from "./ViewViewsTable";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import NativeSelect from "@material-ui/core/NativeSelect";
import { baseApi } from "../../Utils/config";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1% 2%",
  },
  title: {
    // float: "left",
    marginTop: "10px",
    // marginLeft: "4%",
    marginBottom: "0",
    fontWeight: "500",
    fontSize: "24px",
    color: "#33343D",
  },
  desc: {
    // float: "left",
    marginTop: "10px",
    // marginLeft: "4%",
    marginBottom: "0",
    fontWeight: "500",
    fontSize: "14px",
    color: "#565656",
  },
  button: {
    marginLeft: "39% !important",
    marginTop: "-3%",
  },
  shareButton: {
    background: "#FF6100",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    color: "#FFFFFF",
    margin: "1em 1em 2em 1em",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",
    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: "fit-content",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(4),
      // width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  select: {
    margin: theme.spacing(1.5),
    minWidth: "100px",
  },
  sortbutton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    width: "47px",
    height: "36px",
    margin: "1em 0em",
    background: "#00A3FF",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",

    "& span": {
      opacity: "0",
      width: "80px",
      background: "white",
      color: "rgba(0, 0, 0, 0.87)",
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: "1",
      bottom: "100%",
      left: "75%",
      marginLeft: "-50px",
      transition: "opacity 0.3s",
      display: "none",
    },

    "&:hover span": {
      opacity: "1",
      display: "block",
    },
  },

  filterbutton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    width: "47px",
    height: "36px",
    margin: "1em 0em",
    background: "rgba(0, 87, 255, 0.8)",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    "& span": {
      visibilty: "hidden",
      opacity: "0",
      width: "80px",
      background: "white",
      color: "rgba(0, 0, 0, 0.87)",
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: "1",
      bottom: "100%",
      left: "75%",
      marginLeft: "-50px",
      transition: "opacity 0.3s",
    },
    "&:hover span": {
      visibilty: "visible",
      opacity: "1",
    },
    "& span::after": {
      top: "100%",
      position: "absolute",
      left: "50%",
      content: "",
      marginLeft: "-5px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "#555 transparent transparent transparent",
    },
  },
  bookmarkbutton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    width: "47px",
    height: "36px",
    margin: "1em 0em",
    background: "#7F5694",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    "& span": {
      visibilty: "hidden",
      opacity: "0",
      width: "80px",
      background: "white",
      color: "rgba(0, 0, 0, 0.87)",
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: "1",
      bottom: "100%",
      left: "75%",
      marginLeft: "-50px",
      transition: "opacity 0.3s",
    },
    "&:hover span": {
      visibilty: "visible",
      opacity: "1",
    },
    "& span::after": {
      top: "100%",
      position: "absolute",
      left: "50%",
      content: "",
      marginLeft: "-5px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "#555 transparent transparent transparent",
    },
  },
  paper: {
    position: "absolute",
    width: 779,
    maxHeight: 781,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "4px 4px 50px rgba(0, 0, 0, 0.25)",
    padding: theme.spacing(2, 2, 3, 4),
  },
  heading: {
    marginTop: 0,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#33343D",
  },
  subHeading: {
    fontSize: "14px",
    lineHeight: "17px",
    color: "#565656",
  },
  searchEmail: {
    display: "flex",
    flexFlow: "wrap",
    padding: "5px",
    background: "#FFFFFF",
    border: "1px solid #E7E8F2",
    boxSizing: "border-box",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
  },
  chip: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    background: "#D0D9FF",
    borderRadius: " 200px",
    marginRight: "5px",
  },
  roleSelect: {
    margin: "0 1em",
    minWidth: "167px",
  },
  loaderComp: {
    position: "absolute",
    border: "none",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    maxHeight: "300px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

// const alphabets = [
//   { name: "A-Z", value: 1 },
//   { name: "Z-A", value: -1 },
// ];
const alphabets = ["A-Z", "Z-A"];

const typeList = [
  { name: "contains", value: "contains" },
  { name: "does not contain", value: "does not contain" },
  { name: "is", value: "is" },
  { name: "is not", value: "is not" },
  { name: "is empty", value: "is empty" },
  { name: "is not empty", value: "is not empty" },
];
function getStyles(name, filter, theme) {
  return {
    fontWeight:
      filter === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    background: filter === name ? "rgba(0, 87, 255, 0.8)" : "",
    color: filter === name ? "#ffffff" : "",
  };
}
var sortobj = {};
var payload = [];
var pageval = 0;
var start = 0;
var lastlimit = 100;
var newlimit = 1;
var nextlimit = 1;
var payload2 = [];

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function TagsTableItem(props) {
  var ID = props.location.state.id.toString();
  const classes = useStyles();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [age, setAge] = useState("");
  let [tagstable, setTagsTable] = useState();
  let [originalTagsTable, setOriginalTagsTable] = useState();
  let [load, setload] = useState(false);
  let [search, setsearch] = React.useState(false);

  const theme = useTheme();
  const [filter, setFilter] = useState([]);
  const [sorting, setSorting] = useState({});
  const [filterData, setFilterData] = useState([
    {
      column: "",
      condition: "Where",
      type: "",
      value: "",
    },
  ]);
  const [filterMenu, setFilterMenu] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [filteritem, setFilteritem] = useState([]);
  const [pagv, setPagv] = React.useState(0);
  let [sortF, setSortF] = React.useState(0);
  let [bookmarkstate, setbookmarkstate] = React.useState(0);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [searchEmailList, setSearchEmailList] = useState([]);
  const [columnsPermission, setColumnsPermission] = useState({});
  const [selectedChips, setSelectedChips] = useState([]);
  const [rolePermission, setRolePermission] = React.useState("Role");
  const [collaborators, setCollaborators] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loader, setLoader] = useState(false);
  const handleRolePermission = (event) => {
    setRolePermission(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
    fetchShareList({ table: ID });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleColumnsPermission = (event) => {
    setColumnsPermission({
      ...columnsPermission,
      [event.target.name]: event.target.checked,
    });
  };

  const handleListItemClick = (chip) => {
    setSelectedChips([...selectedChips, chip]);
    let data = filterChipValues(searchEmailList, chip);
    setSearchEmailList(data);
    setSearchValue("");
  };
  function filterChipValues(a, b) {
    return a.filter((value) => b._id !== value._id);
  }
  function filterValues(a, b) {
    return a.filter((value) => b.indexOf(value._id) === -1);
  }

  const handleClearChips = (chip) => {
    let data = selectedChips.filter(
      (item) => item.firstName !== chip.firstName
    );
    setSearchEmailList([...searchEmailList, chip]);
    setSelectedChips([...data]);
  };
  const handleSearchEmailList = (event) => {
    let payload = { q: event.target.value };
    setSearchValue(event.target.value);
    const res = axios({
      method: "post",
      url: apiBaseUrl + "v1/user/search",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    });
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log(response.data.data);
          let data = filterValues(
            response.data.data,
            selectedChips.map((item) => item._id)
          );
          setSearchEmailList(data);
        } else if (response.data.statusCode === 400) {
          setSearchEmailList([]);
          console.log("Something went wrong");
          alert("Something went wrong,");
        } else {
          alert("Something went wrong, Please try again");
        }
      })
      .catch((error) => {
        setSearchEmailList([]);
        console.log("Something went wrong", error.message);
        //alert("User does not exist");
      });
  };
  console.log(rolePermission);
  const handleViewShare = () => {
    let payload = {
      table: ID,
      users: selectedChips && selectedChips.map((chip) => chip._id),
      columns:
        Object.keys(columnsPermission).length > 0
          ? Object.keys(columnsPermission).filter(
              (column) => columnsPermission[column]
            )
          : [],
      isEdit: rolePermission === "Edit",
      isView: rolePermission === "View",
    };
    setOpen(false);
    setLoader(true);
    console.log(payload);
    axios({
      method: "post",
      url: apiBaseUrl + "v1/view/share",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log(response.data.data);
          setLoader(false);
          alert("Shared Successfully");
        } else {
          alert("Please try again");
        }
      })
      .catch((error) => {
        alert("Something Went Wrong");
        console.log("login error message:", error);
        //alert("User does not exist");
      });
  };
  const ShareComponent = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          cursor: "pointer",
        }}
      >
        <img alt="c" src={closeShare} onClick={() => setOpen(false)} />
      </div>
      <h2 className={classes.heading} id="simple-modal-title">
        Share with team
      </h2>
      <p className={classes.subHeading} id="simple-modal-description">
        The catalog contains tables and views defined in your CMS project.
      </p>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div style={{ width: "100%" }}>
          <div className={classes.searchEmail}>
            {selectedChips.map((chip, i) => (
              <span key={i} className={classes.chip}>
                <div
                  style={{
                    background: "#fff",
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                    borderRadius: "50%",
                  }}
                ></div>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#1D4FB1",
                  }}
                >
                  {chip.firstName}
                </p>
                <HighlightOffIcon
                  color="primary"
                  style={{ margin: "0 5px", cursor: "poniter" }}
                  onClick={() => handleClearChips(chip)}
                />
              </span>
            ))}
            <TextField
              autoFocus
              onChange={handleSearchEmailList}
              value={searchValue}
              margin="dense"
              id="name"
              type="text"
            ></TextField>
          </div>
          {searchEmailList.length > 0 && (
            <List
              style={{
                maxHeight: "200px",
                overflow: "auto",
                background: "#FFFFFF",
                border: "1px solid #E7E8F2",
                boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                borderRadius: "3px",
              }}
            >
              {searchEmailList.map((list, i) => (
                <ListItem
                  key={i}
                  button
                  onClick={() => handleListItemClick(list)}
                >
                  <div
                    style={{
                      background: "#C4C4C4",
                      width: "30px",
                      height: "30px",
                      margin: "5px",
                      marginzRight: "10px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      lineHeight: "49px",
                      color: "#565656",
                      margin: 0,
                    }}
                  >
                    {list.firstName}
                  </p>
                  <p
                    style={{
                      fontSize: "13px",
                      lineHeight: "49px",
                      color: "#0057FF",
                      margin: 0,
                      marginLeft: "10px",
                    }}
                  >
                    {"(" + list.email + ")"}
                  </p>
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <FormControl className={classes.roleSelect}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={rolePermission}
            // style={{ height: "29px" }}
            onChange={handleRolePermission}
            className={classes.selectEmpty}
            input={<BootstrapInput />}
          >
            <MenuItem value="Role">Role</MenuItem>
            <MenuItem value={"Edit"}>Edit</MenuItem>
            <MenuItem value={"View"}>View</MenuItem>
          </Select>
        </FormControl>
      </div>

      <p
        style={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "48px",
          color: "#33343D",
          marginBottom: "0",
        }}
      >
        Collaborators
      </p>
      <List
        style={{
          maxHeight: "142px",
          overflow: "auto",
          // background: "#FFFFFF",
          // border: "1px solid #E7E8F2",
          // boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
          // borderRadius: "3px",
        }}
      >
        {collaborators.length > 0 &&
          collaborators.map((list, i) => (
            <ListItem
              key={i}
              button
              style={{ display: "flex", justifyContent: "space-between" }}
              onClick={() => handleListItemClick(list.user)}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    background: "#C4C4C4",
                    width: "30px",
                    height: "30px",
                    margin: "5px",
                    marginzRight: "10px",
                    borderRadius: "50%",
                  }}
                ></div>
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "49px",
                    color: "#565656",
                    margin: 0,
                  }}
                >
                  {list.user.firstName}
                </p>
                <p
                  style={{
                    fontSize: "13px",
                    lineHeight: "49px",
                    color: "#0057FF",
                    margin: 0,
                    marginLeft: "10px",
                  }}
                >
                  {"(" + list.user.email + ")"}
                </p>
              </div>
              <FormControl
                className={classes.roleSelect}
                style={{ margin: 0 }}
                disabled
              >
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={list.isEdit ? "Edit" : "View"}
                  // style={{ height: "29px" }}
                  onChange={handleRolePermission}
                  className={classes.selectEmpty}
                  input={<BootstrapInput />}
                >
                  {list.isEdit ? (
                    <MenuItem value="Edit">Edit</MenuItem>
                  ) : (
                    <MenuItem value="View">View</MenuItem>
                  )}
                </Select>
              </FormControl>
            </ListItem>
          ))}
      </List>
      <p
        style={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "48px",
          color: "#33343D",
          marginBottom: "0",
          marginTop: "0",
        }}
      >
        Columns to share
      </p>
      <FormGroup row>
        {tagstable &&
          tagstable.columns.map((column, i) => (
            <FormControlLabel
              key={i}
              control={
                <GreenCheckbox
                  checked={columnsPermission[column._id]}
                  onChange={handleColumnsPermission}
                  name={column._id}
                />
              }
              label={column.displayName}
            />
          ))}
      </FormGroup>
      <Button
        variant="contained"
        onClick={() => handleViewShare()}
        style={{
          color: "#ffffff",
          background: "#0E8050",
          boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
          borderRadius: "3px",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        Submit
      </Button>
    </div>
  );
  var sortorder;
  const fetchShareList = (payload) => {
    const res = axios({
      method: "post",
      url: apiBaseUrl + "v1/view/share/list",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    });
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log(response.data.data);
          if (response.data.data.length) {
            setCollaborators(response.data.data);
          }
        } else if (response.data.statusCode === 400) {
          console.log("Something went wrong");
          alert("Something went wrong,");
        } else {
          alert("Something went wrong, Please try again");
        }
      })
      .catch((error) => {
        console.log("Something went wrong", error.message);
        //alert("User does not exist");
      });
  };
  if (Object.keys(props.location.state.sortobj).length === 0) {
    sortobj = {};
    pageval = 0;
  }
  const fetchData = (payload) => {
    const res = axios(
      {
        method: "post",
        url: apiBaseUrl + "v1/content/list",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          //console.log(response.data.data);
          // setTimeout(function(){
          setTagsTable(response.data.data);
          setOriginalTagsTable(response.data.data);
          setload(false);

          // },1000)
        } else if (response.data.statusCode === 400) {
          console.log("Username password do not match");
          alert("username password do not match");
        } else {
          alert("Wrong Credentials!! , Please try again");
        }
      })
      .catch((error) => {
        console.log("login error message", error.message);
        //alert("User does not exist");
      });
  };

  const setBookmarkFlag = (payload) => {
    const res = axios(
      {
        method: "post",
        url: apiBaseUrl + "v1/user/table/bookmark",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          //console.log(response.data.data);
          setbookmarkstate(bookmarkstate + 1);
        } else if (response.data.statusCode === 400) {
          console.log("Username password do not match");
          alert("username password do not match");
        } else {
          alert("Wrong Credentials!! , Please try again");
        }
      })
      .catch((error) => {
        console.log("login error message", error.message);
        //alert("User does not exist");
      });
  };

  useEffect(() => {
    setload(true);
    if (Object.keys(sortobj).length > 0) {
      fetchData(sortobj);
    }
    if (Object.keys(sortobj).length === 0) {
      if (pageval > 0) {
        fetchData(payload2);
      } else {
        payload = {
          tableId: ID,
          start: start,
          limit: 10000000,
        };

        fetchData(payload);
      }
    }
  }, [pageval, sortF, bookmarkstate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClick = (event) => {
    setAnchorFilter(event.currentTarget);
  };

  const handlePopover = () => {
    setAnchorEl(null);
    setAnchorFilter(null);
    if (sorting.orderby === "A-Z") {
      sortorder = 1;
    } else if (sorting.orderby === "Z-A") {
      sortorder = -1;
    } else {
      sortorder = 0;
    }
    //fetchData(sortobj);
    if (sorting.sortby != undefined && sortorder != 0) {
      if (pageval > 0) {
        Object.assign(
          sortobj,
          {
            sorting: {
              [sorting.sortby]: sortorder,
            },
          },
          payload2
        );
      } else {
        Object.assign(
          sortobj,
          {
            sorting: {
              [sorting.sortby]: sortorder,
            },
          },
          payload
        );
      }

      history.push({
        pathname: "/viewstableitem",
        state: {
          id: ID,
          sortobj: sortobj,
          tablename: props.location.state.tablename,
          tabledesc: props.location.state.tabledesc,
          entries: props.location.state.entries,
        },
      });
      setSortF(sortF + 2);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchorFilter(null);
  };

  const handleSortItems = (sort, name) => {
    setSorting(
      Object.assign(sorting, {
        [sort]: name,
      })
    );
    setPersonName([...personName, name]);
  };

  const handleFilterMenu = () => {
    setFilterMenu(!filterMenu);
  };

  const openPopover = Boolean(anchorEl);
  const openPopFilter = Boolean(anchorFilter);

  const id = openPopover ? "simple-popover" : undefined;
  const filterId = openPopFilter ? "simple-popover" : undefined;

  var columns = [];
  if (tagstable) {
    columns = tagstable.columns.map((cell) => {
      if (cell.dataType === "ObjectId") {
        return {
          displayName: cell.displayName,
          value: cell.displayName + ".name",
        };
      } else if (cell.dataType === "Array") {
        return {
          displayName: cell.displayName,
          value: cell.displayName + ".name",
        };
      } else {
        return { displayName: cell.displayName, value: cell.displayName };
      }
    });
  }
  const handleFilterData = (event, index) => {
    let data = [...filterData];
    data[index][event.target.name] = event.target.value;
    setFilterData((prevState) => {
      return data;
    });
  };

  const handleAddFilter = () => {
    setFilterData((prevState) => {
      return [
        ...prevState,
        {
          column: "",
          condition: prevState.length > 1 ? prevState[1]["condition"] : "",
          type: "",
          value: "",
        },
      ];
    });
  };
  const handleFilterApply = () => {
    let data = {};
    if (pageval > 0) {
      Object.assign(
        data,
        {
          filter: filterData,
        },
        payload2
      );
    } else {
      Object.assign(
        data,
        {
          filter: filterData,
        },
        payload
      );
    }

    Object.assign(sortobj, data);

    history.push({
      pathname: "/viewstableitem",
      state: {
        id: ID,
        sortobj: sortobj,
        tablename: props.location.state.tablename,
        tabledesc: props.location.state.tabledesc,
        entries: props.location.state.entries,
      },
    });

    setSortF(sortF + 2);
    setsearch(true);
    handleClosePopover();
  };
  const handleClearFilter = () => {
    setFilterData([
      {
        column: "",
        condition: "Where",
        type: "",
        value: "",
      },
    ]);

    let data = {};
    if (pageval > 0) {
      data = Object.assign(
        {},
        {
          filter: {
            column: "",
            condition: "Where",
            type: "",
            value: "",
          },
        },
        payload2
      );
    } else {
      data = Object.assign(
        {},
        {
          filter: {
            column: "",
            condition: "Where",
            type: "",
            value: "",
          },
        },
        payload
      );
    }

    Object.assign(sortobj, data);

    history.push({
      pathname: "/viewstableitem",
      state: {
        id: ID,
        sortobj: sortobj,
        tablename: props.location.state.tablename,
        tabledesc: props.location.state.tabledesc,
        entries: props.location.state.entries,
      },
    });

    setSortF(sortF + 2);
    setsearch(false);
    handleClosePopover();
  };

  const handlepagechange = (value) => {
    pageval = value;
    newlimit = lastlimit * pageval;
    nextlimit = lastlimit + newlimit;

    payload2 = {
      tableId: ID,
      start: newlimit,
      limit: 10000000,
    };
    if (pageval > 0) {
      Object.assign(sortobj, payload2);
    } else {
      Object.assign(sortobj, payload);
    }

    history.push({
      pathname: "/viewstableitem",
      state: {
        id: ID,
        sortobj: sortobj,
        tablename: props.location.state.tablename,
        tabledesc: props.location.state.tabledesc,
        entries: props.location.state.entries,
      },
    });
    setPagv(pageval);
  };

  const handleFilterClear = (index) => {
    let data = [...filterData];
    let cleared = data.splice(index, 1);
    if (cleared) {
      setFilterData(data);
    }
  };

  const handleDownloadCsv = () => {
    let payload = {
      tableId: ID,
      start: start,
      limit: 10000000,
      // filter: filterData,
      // sorting: sorting,
      download: true,
    };
    axios({
      method: "post",
      url: apiBaseUrl + "v1/content/list",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    })
      .then(function (response) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href =
          apiBaseUrl + "v1/content/csv/" + response.data.data;
        // hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        // hiddenElement.target = "_blank";
        hiddenElement.download = `${props.location.state.tablename}.csv`;
        hiddenElement.click();
      })
      .catch((error) => {
        console.log("login error message", error.message);
      });
  };

  const handleBookmark = (event) => {
    var bookmarkpayloadtrue = {
      contentTable: ID,
      isBookmark: true,
    };
    var bookmarkpayloadfalse = {
      contentTable: ID,
      isBookmark: false,
    };

    if (tagstable) {
      if (tagstable.isBookmark === false) {
        setBookmarkFlag(bookmarkpayloadtrue);
        alert("Bookmark Added successfully");
      } else {
        setBookmarkFlag(bookmarkpayloadfalse);
        alert("Bookmark Removed successfully");
      }
    }
  };
  const isEqual = (dataValue, searchValue) => {
    if (dataValue)
      return dataValue
        .toString()
        .toLowerCase()
        .includes(searchValue.toString().toLowerCase());
  };

  const handleSearch = (event) => {
    let data = Object.assign({}, originalTagsTable);
    let searchList = data.data.filter((obj) => {
      if (
        Object.values(obj).filter((item) => {
          if (Array.isArray(item)) {
            return item.filter((age) => {
              if (typeof age === "object") {
                return age.name
                  ? isEqual(age.name, event.target.value)
                  : isEqual(age.key, event.target.value)
                  ? true
                  : false;
              } else return isEqual(age, event.target.value);
            }).length;
          } else if (typeof item === "object") {
            return item ? isEqual(item.name, event.target.value) : false;
          } else {
            return item ? isEqual(item, event.target.value) : false;
          }
        }).length
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (searchList) {
      data.data = searchList;
      setTagsTable(data);
      setsearch(true);
    }
    if (searchList.length === 100) {
      setsearch(false);
      setSortF(sortF + 1);
    }
  };

  return (
    <div className={classes.container} style={{ margin: "4px 21px" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "100%",
          }}
        >
          <h6 className={classes.title}> {props.location.state.tablename}</h6>
          <h6 className={classes.desc}> {props.location.state.tabledesc}</h6>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              onChange={(e) => handleSearch(e)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {tagstable && (
            <Button
              variant="contained"
              style={{
                marginBottom: "28px",
                marginRight: "1em",
                color: "#ffffff",
                background: "rgba(0, 0, 0, 0.5)",
                boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                borderRadius: "3px",
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "none",
              }}
              onClick={handleDownloadCsv}
            >
              Download CSV
            </Button>
          )}
          <div
            id="sort-container"
            style={{ position: "relative", margin: "1em" }}
            className={classes.formControl}
          >
            {tagstable && (
              <div
                className={classes.bookmarkbutton}
                variant="contained"
                onClick={handleBookmark}
              >
                {tagstable.isBookmark === false ? (
                  <BookmarkBorderIcon style={{ color: "#ffffff" }} />
                ) : (
                  <BookmarkIcon style={{ color: "#ffffff" }} />
                )}
                <span className={classes.tooltiptext}>Bookmark</span>
              </div>
            )}
          </div>
          {/* ------------------SORTING BUTTON-----------*/}

          {tagstable && (
            <div
              id="filter-container"
              style={{ position: "relative", margin: "1em" }}
              className={classes.formControl}
            >
              <div
                className={classes.sortbutton}
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                <img src={SortIcon} alt="filter" />
                <span className={classes.tooltiptext}>Sort</span>
              </div>
              <Popover
                className={classes.formControl}
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ top: "2%", left: "10px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleFilterMenu}
                >
                  <h3 style={{ marginLeft: "1em" }}>Pick field to sort</h3>
                  <ArrowDropDownIcon />
                </div>
                {/* { columns.length>0  &&           console.log(columns)} */}
                {filterMenu &&
                  columns.length > 0 &&
                  columns.map((column) => (
                    <MenuItem
                      onClick={() => handleSortItems("sortby", column.value)}
                      key={column.displayName}
                      value={column.value}
                      style={getStyles(column.value, sorting.sortby, theme)}
                    >
                      {column.displayName.charAt(0).toUpperCase() +
                        column.displayName.slice(1)}
                    </MenuItem>
                  ))}

                {alphabets.map((name) => (
                  <MenuItem
                    onClick={() => handleSortItems("orderby", name)}
                    key={name}
                    value={name}
                    style={getStyles(name, sorting.orderby, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
                <Button
                  style={{
                    marginLeft: "1em",
                    marginBottom: "1em",
                    marginTop: "14px",
                    background: "#1B804F",
                    color: "white",
                  }}
                  variant="contained"
                  size="small"
                  onClick={handlePopover}
                >
                  Apply
                </Button>
              </Popover>
            </div>
          )}
          {/* ------------------FILTER BUTTON-----------*/}
          {tagstable && (
            <div
              id="sort-container"
              style={{ position: "relative", margin: "1em" }}
              className={classes.formControl}
            >
              <div
                className={classes.filterbutton}
                aria-describedby={filterId}
                variant="contained"
                color="primary"
                onClick={handleFilterClick}
              >
                <img src={filterIcon} alt="sort" />
                <span className={classes.tooltiptext}>Filter</span>
              </div>
              <Popover
                className={classes.formControl}
                id={filterId}
                open={openPopFilter}
                anchorEl={anchorFilter}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                style={{ top: "2%", left: "8%" }}
              >
                {filterData.map((filObj, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {index === 0 ? (
                      <p style={{ marginLeft: "1em" }}>{filObj["condition"]}</p>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ClearIcon
                          fontSize="small"
                          onClick={() => handleFilterClear(index)}
                          style={{ marginTop: "1em", marginLeft: "5px" }}
                        />
                        <FormControl className={classes.select}>
                          <InputLabel id="demo-customized-select-label">
                            Condition
                          </InputLabel>
                          <Select
                            name="condition"
                            disabled={index > 1}
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={filObj["condition"]}
                            onChange={(event) => handleFilterData(event, index)}
                            input={<BootstrapInput />}
                          >
                            {[
                              { name: "AND", value: "and" },
                              { name: "OR", value: "or" },
                            ].map((condition, i) => (
                              <MenuItem key={i} value={condition.value}>
                                {condition.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}

                    <FormControl className={classes.select}>
                      <InputLabel id="demo-customized-select-label">
                        Column
                      </InputLabel>
                      <Select
                        name="column"
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={filObj["column"]}
                        style={{ maxHeight: "200px" }}
                        onChange={(event) => handleFilterData(event, index)}
                        input={<BootstrapInput />}
                      >
                        {columns.length > 0 &&
                          columns.map((column, i) => (
                            <MenuItem
                              key={column.displayName + i}
                              value={column.value}
                            >
                              {column.displayName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.select}>
                      <InputLabel id="demo-customized-select-label">
                        type
                      </InputLabel>
                      <Select
                        name="type"
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={filObj["type"]}
                        style={{ maxHeight: "200px !important" }}
                        onChange={(event) => handleFilterData(event, index)}
                        input={<BootstrapInput />}
                      >
                        {typeList.map((type, i) => (
                          <MenuItem
                            key={i}
                            value={type.value}
                            style={getStyles(type.name, filter.sortby, theme)}
                          >
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.select}>
                      <InputLabel htmlFor="demo-customized-textbox">
                        Search
                      </InputLabel>
                      <BootstrapInput
                        name="value"
                        value={filObj["value"]}
                        id="demo-customized-textbox"
                        onChange={(event) => handleFilterData(event, index)}
                      />
                    </FormControl>
                  </div>
                ))}
                <div
                  onClick={() => handleAddFilter()}
                  style={{ cursor: "pointer" }}
                >
                  <p style={{ marginLeft: "1em" }}>+Add Filter</p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{
                      marginLeft: "1em",
                      marginBottom: "1em",
                      background: "#1B804F",
                      color: "white",
                    }}
                    variant="contained"
                    size="small"
                    onClick={() => handleFilterApply()}
                  >
                    Apply
                  </Button>
                  <div
                    onClick={() => handleClearFilter()}
                    style={{ cursor: "pointer" }}
                  >
                    <p style={{ marginTop: 0, marginLeft: "1em" }}>Clear</p>
                  </div>
                </div>
              </Popover>
            </div>
          )}

          {tagstable && tagstable.isShare && (
            <Button
              variant="contained"
              className={classes.shareButton}
              onClick={handleOpen}
            >
              <img alt="s" src={shareIcon} style={{ marginRight: "5px" }} />{" "}
              Share
            </Button>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {ShareComponent}
      </Modal>
      <div>
        {!load && tagstable && filteritem.length === 0 ? (
          <ViewViewsTable
            entries={props.location.state.entries}
            handlepagechange={handlepagechange}
            rows={tagstable.data}
            headcells={tagstable.columns}
            page={pageval}
            search={search}
            tableID={ID}
            tablename={props.location.state.tablename}
            tabledesc={props.location.state.tabledesc}
            edit={tagstable.isPermission.isEdit}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}
