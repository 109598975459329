import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import { baseApi } from "../../Utils/config";
import { Redirect, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  submit: {
    marginTop: "10%",
    marginLeft: "6%",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
var payload = {};
export default function ResetPassword() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [oldpassword, setoldpassword] = React.useState("");
  const [newpassword, setnewpassword] = React.useState("");
  const [confirmpassword, setconfirmpassword] = React.useState("");
  var user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Submitpassword = (props) => {
    var apiBaseUrl = baseApi;

    axios({
      method: "post",
      url: apiBaseUrl + "v1/user/password/reset",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    })
      .then((response, error) => {
        if (response.data.statusCode === 201) {
          history.push("/view-profile");
          alert("Password changed successfully");
          handleClose();
        } else if (response.data.statusCode === 400) {
          alert("problem with POST API with code 400  " + error.message);
        } else {
          alert("problem with Post API  " + error.message);
        }
      })
      .catch((error) => {
        alert("Password update couldnt happen - Incorrect Old Password");
      });
  };
  const handleoldpassword = (event) => {
    setoldpassword(event.target.value);
  };
  const handlenewpassword = (event) => {
    setnewpassword(event.target.value);
  };
  const handleconfirmpassword = (event) => {
    setconfirmpassword(event.target.value);
  };
  const handlesubmit = (event) => {
    var oldpassflag = true;
    var newpassflag = true;

    var reg = /^[0-9!@#$%^&*\S"a-zA-Z]{3,26}$/;
    newpassflag = reg.test(newpassword);

    if (!newpassflag) {
      alert(
        "Password must have at least 3 Alphanumeric characters without space"
      );
    }
    if (newpassword != confirmpassword) {
      newpassflag = false;
      alert("New and Confirm password doesnt match");
    }
    if (newpassword === oldpassword) {
      oldpassflag = false;
      alert("New password cant be same as Old password");
    }

    if (oldpassflag && newpassflag) {
      payload = {
        currentPassword: oldpassword,
        newPassword: newpassword,
      };
      Submitpassword(payload);
    }
  };

  return (
    <div style={{ marginLeft: "34%" }}>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Reset Password
      </Button>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2>Reset Password</h2>

          <TextField
            label="Old-password"
            variant="outlined"
            value={oldpassword}
            onChange={handleoldpassword}
            style={{ margin: "16px" }}
          />
          <TextField
            label="New-password"
            variant="outlined"
            value={newpassword}
            onChange={handlenewpassword}
            style={{ margin: "16px" }}
          />
          <TextField
            label="Confirm-password"
            variant="outlined"
            value={confirmpassword}
            onChange={handleconfirmpassword}
            style={{ margin: "16px" }}
          />
          <Button
            className={classes.submit}
            onClick={handlesubmit}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
}
