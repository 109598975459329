import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Typography, Paper, Icon, CardMedia } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Switch from "@material-ui/core/Switch";
import GroupedSelect from "./GroupingList";
import DeleteIcon from "../../assets/DeleteIcon.png";
import folder_labeled from "../../assets/folder_labeled.png";
import add_square from "../../assets/add_square.png";
import move_left from "../../assets/move_left.png";
import move_right from "../../assets/move_right.png";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { baseApi } from "../../Utils/config";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  container: {
    // paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(8),
  },
  rulesContainer: {
    paddingTop: theme.spacing(10),
    display: "flex",
    fontSize: "16px",
    // paddingBottom: theme.spacing(8),
  },

  list: {
    width: 210,
    height: 286,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    background: "#ffffff",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
    margin: theme.spacing(0.5, 0),
  },
  nextButton: {
    float: "right",
    margin: theme.spacing(0.5, 0),
  },
  greenButton: {
    background: "#0E8050",
    color: "#FFFFFF",
    marginLeft: "3em",
  },
  media: {
    width: "16px",
    height: "20px",
    margin: theme.spacing(1, 2),
  },
  rowWise: {
    display: "flex",
    alignItems: "center",
  },
  alignAtCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectControl: {
    margin: theme.spacing(2),
    minWidth: 240,
    // borderRadius: "3px",
    background: "#ffffff",
    // boxShadow: " 4px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    // borderRadius: "3px",
    background: "#ffffff",
    boxShadow: " 4px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  divContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  title: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#33343D",
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function checkColumns(a, b) {
  return a.filter((value) => b.indexOf(value._id) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function TransferList() {
  const classes = useStyles();
  const history = useHistory();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const [checked, setChecked] = useState([]);
  const [sources, setSources] = useState([]);
  const [checkedTable, setCheckedTable] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [activeTableColumns, setActiveTableColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [allColumns, setAllColumns] = useState({});
  const [selectedTableRules, setSelectedTableRules] = useState({});
  const sourcesChecked = intersection(checked, sources);
  const tablesChecked = intersection(checkedTable, selectedTables);
  const columnsChecked = intersection(checkedColumns, columns);
  const columnsSelected = intersection(checkedColumns, selectedColumns);
  const [filterData, setFilterData] = useState([
    {
      column: "",
      condition: "where",
      type: "",
      value: "",
    },
  ]);

  const [tableRule, setTableRule] = useState({});
  const [joinList, setJoinList] = useState([]);

  useEffect(() => {
    fetchSources();
  }, []);

  function fetchSources() {
    const res = axios({
      method: "get",
      url: apiBaseUrl + "v1/view/sources",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    });
    res
      .then((res) => {
        setSources(res.data.data);
      })
      .catch((err) => console.log(err));
  }
  function fetchColumns(tableid) {
    const res = axios({
      method: "get",
      url: apiBaseUrl + "v1/content/table/" + tableid,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    });
    res
      .then((res) => {
        setColumns(
          checkColumns(
            res.data.data.columns,
            selectedColumns.map((item) => item._id)
          )
        );
        setAllColumns(
          Object.assign(allColumns, {
            [res.data.data.displayName]: res.data.data.columns,
          })
        );
        setActiveTableColumns(res.data.data.columns);
      })
      .catch((err) => console.log(err));
  }

  const handleTableRules = (event) => {
    setTableRule({ ...tableRule, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      setJoinList([...joinList, selectedTableRules[event.target.name]]);
    } else {
      setJoinList(
        joinList.filter(
          (obj) =>
            !Object.values(obj).every(function (element, index) {
              return (
                element ===
                Object.values(selectedTableRules[event.target.name])[index]
              );
            })
        )
      );
    }
  };
  const handleAddFilter = () => {
    setFilterData((prevState) => {
      return [
        ...prevState,
        {
          column: "",
          condition: prevState.length > 1 ? prevState[1]["condition"] : "",
          type: "",
          value: "",
        },
      ];
    });
  };
  const handleFilterClear = (index) => {
    let data = [...filterData];
    let cleared = data.splice(index, 1);
    if (cleared) {
      setFilterData(data);
    }
  };
  const handleFilterData = (event, index) => {
    let data = [...filterData];
    data[index][event.target.name] = event.target.value;
    setFilterData((prevState) => {
      return data;
    });
  };

  const handleToggle = (title, value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleSelectedTable = (title, value) => () => {
    fetchColumns(value._id);
    setCheckedTable([value]);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleColumns = (value) => () => {
    const currentIndex = checkedColumns.indexOf(value);
    const newChecked = [...checkedColumns];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedColumns(newChecked);
  };

  const handleCheckedSources = () => {
    setSelectedTables(selectedTables.concat(sourcesChecked));
    setSources(not(sources, sourcesChecked));
    setChecked(not(checked, sourcesChecked));
  };

  const handleCheckedTables = () => {
    setSelectedColumns(
      checkColumns(
        selectedColumns,
        activeTableColumns.map((item) => item._id)
      )
    );
    setSources(sources.concat(tablesChecked));
    setSelectedTables(not(selectedTables, tablesChecked));
    setChecked(not(checked, tablesChecked));
    setColumns([]);
  };
  const handleCheckedColumns = () => {
    setSelectedColumns(selectedColumns.concat(columnsChecked));
    setColumns(not(columns, columnsChecked));
    setCheckedColumns(not(checkedColumns, columnsChecked));
  };
  const handleSelectedColumns = () => {
    setSelectedColumns(not(selectedColumns, columnsSelected));
    setCheckedColumns(not(checkedColumns, columnsSelected));
  };
  const handleOtherTableColumns = (event) => {
    setSelectedTableRules(
      Object.assign({
        ...selectedTableRules,
        [event.target.name]: {
          ...selectedTableRules[event.target.name],
          ...event.target.value,
        },
      })
    );
  };
  const handleBaseTableColumns = (event) => {
    setSelectedTableRules(
      Object.assign({
        ...selectedTableRules,
        [event.target.name]: {
          ...selectedTableRules[event.target.name],
          ...event.target.value,
        },
      })
    );
  };

  const customList = (title, items) => (
    <Card>
      <Typography
        align="left"
        variant="body1"
        style={{
          height: "47px",
          color: "#33343D",
          fontWeight: "600",
          padding: "1em",
          fontSize: "13px",
        }}
      >
        {title}
      </Typography>
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleToggle(title, value)}
            >
              <img src={folder_labeled} alt="label" />
              <ListItemText
                selected={checked.indexOf(value) !== -1}
                id={labelId}
              >
                <Typography
                  align="left"
                  variant="body1"
                  color={
                    checked.indexOf(value) !== -1 ? "primary" : "secondary"
                  }
                  style={{ fontSize: "13px", marginLeft: "10px" }}
                >
                  {value.displayName}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  const selectedTableList = (title, items) => (
    <Card>
      <Typography
        align="left"
        variant="body1"
        style={{
          height: "47px",
          color: "#33343D",
          fontWeight: "600",
          padding: "1em",
          fontSize: "13px",
        }}
      >
        {title}
      </Typography>
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleSelectedTable(title, value)}
            >
              <ListItemText
                selected={checked.indexOf(value) !== -1}
                id={labelId}
              >
                <Typography
                  align="left"
                  variant="body1"
                  color={
                    checkedTable.indexOf(value) !== -1 ? "primary" : "secondary"
                  }
                  style={{ fontSize: "13px" }}
                >
                  {value.displayName}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
        {items.length === 0 && <ListItem>No data</ListItem>}
        <ListItem />
      </List>
    </Card>
  );
  const customColumnsList = (title, items) => (
    <Card>
      <Typography
        align="left"
        variant="body1"
        style={{
          height: "47px",
          color: "#33343D",
          fontWeight: "600",
          padding: "1em",
          fontSize: "13px",
        }}
      >
        {title}
      </Typography>
      <Divider />
      <List className={classes.list} component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleToggleColumns(value)}
            >
              <ListItemText
                selected={checkedColumns.indexOf(value) !== -1}
                id={labelId}
              >
                <Typography
                  align="left"
                  variant="body1"
                  color={
                    checkedColumns.indexOf(value) !== -1
                      ? "primary"
                      : "secondary"
                  }
                  style={{ fontSize: "13px" }}
                >
                  {value.contentTable.displayName + "." + value.displayName}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
        {items.length === 0 && <ListItem>No data</ListItem>}
        <ListItem />
      </List>
    </Card>
  );
  const selectedColumnsList = (title, items) => (
    <Card>
      <Typography
        align="left"
        variant="body1"
        style={{
          height: "47px",
          color: "#33343D",
          fontWeight: "600",
          fontSize: "13px",
          padding: "1em",
        }}
      >
        {title}
      </Typography>
      <Divider />
      <List className={classes.list} component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={i}
              role="listitem"
              button
              onClick={handleToggleColumns(value)}
            >
              <ListItemText
                selected={checkedColumns.indexOf(value) !== -1}
                id={labelId}
              >
                <Typography
                  align="left"
                  variant="body1"
                  color={
                    checkedColumns.indexOf(value) !== -1
                      ? "primary"
                      : "secondary"
                  }
                  style={{ fontSize: "13px" }}
                >
                  {value.contentTable.displayName + "." + value.displayName}
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
        {items.length === 0 && <ListItem>No data</ListItem>}
        <ListItem />
      </List>
    </Card>
  );
  let tablelist = selectedTables.map((row) => {
    return {
      name: row.name,
      _id: row._id,
    };
  });
  let columnList = selectedColumns.map((row) => {
    return {
      table: row.contentTable.name,
      _id: row.contentTable._id,
      column: row.displayName,
      name: row.contentTable.name + "." + row.name,
      dataType: row.dataType,
    };
  });
  // let joinList = Object.values(selectedTableRules);

  let payload = {
    query: {
      tableSelected: tablelist,
      columnSelected: columnList,
      join: joinList,
      filter: filterData,
    },
    isVerified: false,
  };
  const handleNext = () => {
    console.log(payload);
    history.push({
      pathname: `/view-preview`,
      state: payload,
    });
  };
  const typeList = [
    { name: "contains", value: "contains" },
    { name: "does not contain", value: "does not contain" },
    { name: "is", value: "is" },
    { name: "is not", value: "is not" },
    { name: "is empty", value: "is empty" },
    { name: "is not empty", value: "is not empty" },
  ];
  let baseTableName = selectedTables.length && selectedTables[0].displayName;
  let baseTableColumns = allColumns[baseTableName];

  let noOfSelectedTables = selectedColumns.reduce((acc, column) => {
    if (column.contentTable.displayName !== baseTableName) {
      acc.push(column.contentTable.displayName);
    } else return acc;
    return acc;
  }, []);
  let notMatched = [...new Set(noOfSelectedTables)];

  let otherTableColumns =
    notMatched.length &&
    notMatched.reduce((acc, cv) => {
      return acc.concat(allColumns[cv]);
    }, []);

  let allSelectedColumns = baseTableColumns &&
    otherTableColumns &&
    baseTableColumns.length &&
    otherTableColumns.length && [...baseTableColumns, ...otherTableColumns];
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Manage Views {"->"} Create View
      </Typography>
      <Grid
        container
        spacing={4}
        // justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item style={{ paddingLeft: 0 }}>
          {customList("Sources", sources)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              style={{ width: "36px", height: "36px" }}
              variant="outlined"
              // size="small"
              className={classes.button}
              onClick={handleCheckedSources}
              disabled={sourcesChecked.length === 0}
              aria-label="move selected right"
            >
              &rarr;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedTables}
              disabled={tablesChecked.length === 0}
              aria-label="move selected left"
            >
              &larr;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{selectedTableList("Selected Tables", selectedTables)}</Grid>
        <Grid item>{customColumnsList("All Columns", columns)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              style={{ width: "36px", height: "36px" }}
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedColumns}
              disabled={columnsChecked.length === 0}
              aria-label="move selected right"
            >
              &rarr;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleSelectedColumns}
              disabled={columnsSelected.length === 0}
              aria-label="move selected left"
            >
              &larr;
            </Button>

            {/* <button
              className={classes.button}
              disabled={columnsSelected.length === 0}
              onClick={() => handleSelectedColumns()}
            >
              <KeyboardBackspaceIcon />
              {/* <span>&larr;</span>
            </button> */}
          </Grid>
        </Grid>
        <Grid item>
          {selectedColumnsList("Selected Columns", selectedColumns)}
        </Grid>
      </Grid>
      {notMatched && notMatched.length > 0 && (
        <Container className={classes.rulesContainer}>
          <Typography component="h1">Define Table Rules</Typography>
        </Container>
      )}
      {notMatched &&
        notMatched.length > 0 &&
        notMatched.map((item, i) => (
          <div key={i} className={classes.alignAtCenter}>
            <Typography component="h1"> {baseTableName} :</Typography>
            <Select
              value={
                selectedTableRules.hasOwnProperty(item)
                  ? [...selectedTableRules[item].as]
                  : "Nothing Selected"
              }
              renderValue={(selected) => <Typography>{selected}</Typography>}
              className={classes.selectControl}
              name={item}
              onChange={(e) => handleBaseTableColumns(e)}
              id="BaseTable"
            >
              {baseTableColumns &&
                baseTableColumns.length > 0 &&
                baseTableColumns.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={{ as: item.name, localField: item.name }}
                  >
                    {item.displayName}
                  </MenuItem>
                ))}
            </Select>
            =
            <Select
              value={
                selectedTableRules.hasOwnProperty(item)
                  ? [selectedTableRules[item].foreignField]
                  : "Nothing Selected"
              }
              renderValue={(selected) => <Typography>{selected}</Typography>}
              className={classes.selectControl}
              onChange={(e) => handleOtherTableColumns(e)}
              name={item}
              id="OtherTable"
            >
              {otherTableColumns &&
                otherTableColumns.length > 0 &&
                otherTableColumns.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={{
                      from: item.contentTable.name,
                      foreignField: item.name,
                    }}
                  >
                    {item.contentTable.displayName + "." + item.displayName}
                  </MenuItem>
                ))}
            </Select>
            <Switch
              checked={tableRule[item] ? tableRule[item] : false}
              onChange={handleTableRules}
              color="primary"
              name={item}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        ))}
      <Container className={classes.rulesContainer}>
        <Typography component="h1">Add conditions</Typography>
        <Button
          variant="contained"
          className={classes.greenButton}
          onClick={() => handleAddFilter()}
        >
          <img
            src={add_square}
            style={{ marginRight: "10px" }}
            alt="add_square"
          />
          ADD CONDITION
        </Button>
      </Container>
      {filterData.map((filObj, index) => (
        <div key={index} className={classes.rowWise}>
          <div className={classes.divContainer}>
            {index === 1 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControl className={classes.select}>
                  <Select
                    style={{ minwidth: "74px" }}
                    name="condition"
                    disabled={index > 1}
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={filObj["condition"]}
                    onChange={(event) => handleFilterData(event, index)}
                    input={<BootstrapInput />}
                  >
                    {[
                      { name: "AND", value: "and" },
                      { name: "OR", value: "or" },
                    ].map((condition, i) => (
                      <MenuItem key={i} value={condition.value}>
                        {condition.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <p style={{ marginLeft: "1em", width: "58px" }}>
                {filObj["condition"]}
              </p>
            )}
            <FormControl className={classes.formControl}>
              <Select
                name="column"
                id="grouped-select"
                renderValue={(selected) => <Typography>{selected}</Typography>}
                value={filObj["column"]}
                onChange={(event) => handleFilterData(event, index)}
                input={<BootstrapInput />}
              >
                {console.log(allSelectedColumns)}
                {allSelectedColumns &&
                  allSelectedColumns.length > 0 &&
                  allSelectedColumns.map((column, i) => (
                    <MenuItem key={column._id} value={column.name}>
                      {column.contentTable.displayName +
                        "." +
                        column.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select
                name="type"
                defaultValue=""
                id="grouped-select"
                value={filObj["type"]}
                onChange={(event) => handleFilterData(event, index)}
                input={<BootstrapInput />}
              >
                {typeList.map((type, i) => (
                  <MenuItem key={i} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formInput}>
              <InputLabel htmlFor="demo-customized-textbox">
                Text Input
              </InputLabel>
              <BootstrapInput
                name="value"
                value={filObj["value"]}
                id="demo-customized-textbox"
                onChange={(event) => handleFilterData(event, index)}
              />
            </FormControl>
          </div>
          <CardMedia
            onClick={() => handleFilterClear(index)}
            className={classes.media}
            image={DeleteIcon}
            title="Delete"
          />
        </div>
      ))}

      <Button
        align="right"
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={joinList.length === 0}
        className={classes.nextButton}
      >
        Next
      </Button>
    </Container>
  );
}
