import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Typography, InputBase, fade, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Add from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import CheckBox from "./Checkbox";
import TableList from "../CustomTable/TableList";
import EnhancedTable from "../Table/Table";
import RolesTable from "./Rolestable";
import { baseApi } from "../../Utils/config";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: 0,
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#33343D",
    marginTop: "1%",
    marginLeft: "0%",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",
    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginTop: theme.spacing(2),
    maxWidth: "244px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(4),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    maxWidth: "244px",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  table: {
    minWidth: 200,
  },
  // tablehead: {
  //   background: "lightgrey",
  // },
  tbContainer: {},
}));

export default function ManageRoles() {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));

  const classes = useStyles();
  const history = useHistory();
  const [filterBy, setFilterBy] = useState("");
  const [roles, setRoles] = useState("");
  const [tableRows, settableRows] = useState([]);
  const [errors, setErrors] = useState();
  const [search, setSearch] = useState("");

  const [rolelist, setrolelist] = useState([]);

  useEffect(() => {
    fetchData();
    fetchRoleList();
  }, []);

  async function fetchData() {
    axios({
      method: "get",
      url: apiBaseUrl + "v1/user/role",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    })
      .then((res) => {
        setRoles(res.data.data);
        settableRows(res.data.data.data);
      })
      .catch((err) => setErrors(err));
  }
  var roleByList = [];

  const filterResults = (data, value) => {
    console.log(data);
    console.log(value);
    let requiredData = data.filter((item) => item._id === value);
    // settableRows(requiredData);
    console.log(requiredData);
    return requiredData;
  };
  const searchResults = (data) => {
    let requiredData = data.filter((item) => {
      if (item.moduleMenuItem) {
        let contentTables = [];
        if (item.contentTable) {
          contentTables = item.contentTable.name.toLowerCase();
        }
        let moduleMenuItem = item.moduleMenuItem.name.toLowerCase();
        let moduleMenu = item.moduleMenu.name.toLowerCase();
        let dc = moduleMenu.concat(moduleMenuItem);
        console.log("dc", dc);
        const filter = search.toLowerCase();
        return dc.includes(filter) || contentTables.includes(filter);
      } else return false;
    });
    return requiredData;
  };

  const handleChange = (event) => {
    let value = event.target.value;
    setFilterBy(value);
    console.log(value);
    if (value !== "") {
      if (search !== "") {
        let filtered = filterResults(roles.data, value);
        let list = searchResults(filtered);
        settableRows(list);
        // settableRows(list);
        return;
      } else {
        let roleByList = filterResults(roles.data, value);
        console.log("roleByList", roleByList);
        settableRows(roleByList);
        return;
      }
    } else {
      if (search !== "") {
        let searchList = searchResults(roles.data);
        settableRows(searchList);
      } else {
        settableRows(roles.data);
      }
    }
  };
  const handleSearch = (event) => {
    setSearch(event.target.value);
    console.log(event.target.value);
    let value = event.target.value;
    if (value !== "") {
      if (filterBy !== "") {
        let roleByList = filterResults(roles.data, filterBy);
        let searchList = searchResults(roleByList);
        settableRows(searchList);
      } else {
        let searchList = searchResults(roles.data);
        console.log(searchList);
        settableRows(searchList);
      }
    } else {
      if (filterBy !== "") {
        currentList = roles.data;
        let roleByList = filterResults(roles.data, filterBy);
        console.log(filterBy, roleByList);
        settableRows(roleByList);
      } else {
        settableRows(roles.data);
      }
    }
  };
  const handleAdd = (event) => {
    history.push("/addrole");
  };
  function fetchRoleList() {
    const res = axios(
      {
        method: "get",
        url: apiBaseUrl + "v1/user/role/list",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setrolelist(res.data.data.data);
      })
      .catch((err) => setErrors(err));
  }
  var currentList = {};
  var searchList = [];

  let addRolePermission = user && user.menu["User Management"][0].isAdd;
  return (
    <div style={{ margin: "0 40px 40px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4" className={classes.title}>
            Manage Roles
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              onChange={handleSearch}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Filter by
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={filterBy}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              style={{ height: "43px" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {rolelist.length &&
                rolelist.map((role, i) => (
                  <MenuItem key={i} value={role._id}>
                    {role.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {addRolePermission && (
            <div style={{ display: "block" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={handleAdd}
                startIcon={<Add />}
              >
                Add
              </Button>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: "2%" }}>
        {roles && <RolesTable rows={tableRows} headCells={roles.columns} />}
        {roles && tableRows.length === 0 && <h1>No data found</h1>}
        {!roles && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "block",
          textAlignLast: "center",
          backgroundColor: "#F7FAFF",
        }}
      >
        {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ margin: "0 50px 50px" }}
        >
          Submit
        </Button> */}
      </div>
    </div>
  );
}
