import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
  createData(
    "ARC Content/ Track",
    "10.231.331.12/dsfaas",
    "5/23/2020, 10:45 AM",
    "The track name was updated"
  ),
];

export default function AcccessibleTable(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="caption table">
        {/* <caption>A basic table example with a caption</caption> */}
        <TableHead>
          <TableRow>
            <TableCell>TITLE</TableCell>
            <TableCell>MODULE</TableCell>
            <TableCell>REF URL</TableCell>
            <TableCell>TIME UPDATED</TableCell>
            <TableCell>MESSAGE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.data &&
            data.data
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#27272E",
                      textTransform: "capitalize",
                    }}
                    component="th"
                    scope="row"
                  >
                    {row.functionality}
                  </TableCell>
                  <TableCell
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {row.module}
                  </TableCell>

                  <TableCell>{row.url}</TableCell>
                  <TableCell>
                    {moment(row.updatedAt).format("DD MMM YYYY h:mm a")}
                  </TableCell>
                  <TableCell>{}</TableCell>
                </TableRow>
              ))
              .reverse()}

          {data && data.data && data.data.length == 0 && (
            <>
              <div>
                <TableRow>
                  <TableCell style={{ border: "none" }}>
                    <h2
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1629",
                        margin: "30px",
                      }}
                    >
                      No data found
                    </h2>
                  </TableCell>
                </TableRow>
              </div>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
