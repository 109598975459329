import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import GoogleLogin from "react-google-login";

import axios from "axios";
import Appbar from "../AppBar/Appbar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { baseApi, googleToken } from "../../Utils/config";
import googleloginImg from "../../assets/googleLogin.png";
import loginbackground from "../../assets/LoginBackgroundpng.png";
import logo from "../../assets/LoginLogo.png";
import science from "../../assets/science.png";
import researchareas from "../../assets/researchareas.png";
import media from "../../assets/media.png";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "200%",
    color: "#FFFFFF",
    marginBottom: "2em",
  },
  tagline: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "150%",
    color: "#FFFFFF",
  },
  paper: {
    padding: theme.spacing(5, 8),
    // marginTop: theme.spacing(25),
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [isLogin] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
    // setPostData({
    //   [event.target.name]: event.target.value,
    // });
  };
  const responseGoogle = (response) => {
    console.log("google console response-=-=-=", response);
    if (response.error) {
      console.log(response.error);
    }
    signup(response, "google");
  };
  const signup = (res, type) => {
    let postData;

    console.log("res-----------", res);
    if (type === "google" && res) {
      postData = {
        // name: res.w3.ig,
        firstName:
          res.getBasicProfile().getGivenName() ||
          res.getBasicProfile().getName(),
        lastName:
          res.getBasicProfile().getFamilyName() ||
          res.profileObj.familyName ||
          "",
        method: type,
        email: res.getBasicProfile().getEmail(),
        // provider_id: res.El,
        token: res.getAuthResponse().id_token,
        avatar: res.getBasicProfile().getImageUrl(),
      };
      window.localStorage.setItem("user", JSON.stringify(postData));
    }

    if (type === "facebook" && res) {
      let img = `https://graph.facebook.com/${res.id}/picture?type=large`;
      let fullName = res.name;
      let index = fullName.lastIndexOf(" ");
      let lastName = fullName.substring(index + 1);
      let firstName = fullName.substring(0, index);

      postData = {
        // name: fullName,
        firstName: firstName,
        lastName: lastName,
        method: type,
        email: res.email,
        // provider_id: res.id,
        token: res.accessToken,
        avatar: img,
      };
    }

    if (postData) {
      var apiBaseUrl = baseApi;
      var payload = {
        token: postData.token,
      };

      axios(
        {
          method: "post",
          url: apiBaseUrl + "users/login",
          data: payload,
          headers: {
            "Content-Type": "application/json",
          },
        }
        // { withCredentials: true }
      )
        .then((response) => {
          if (response.data.code === "S200") {
            var date = Date.now();
            window.localStorage.setItem("apiBaseUrl", apiBaseUrl);
            window.localStorage.setItem(
              "user",
              JSON.stringify(response.data.data)
            );
            window.localStorage.setItem("timestamp", date);
            // this.props.handleSuccessfulAuth(response.data);
            // this.setState({
            //   toDashboard: true,
            // });
            history.push("/dashboard");
          } else if (response.data.statusCode === 400) {
            console.log("Username password do not match");
            alert("username password do not match");
          } else {
            alert("Wrong Credentials!! , Please try again");
          }
        })
        .catch((error) => {
          console.log("login error message", error.message);
          alert("User does not exist");
        });
    }
  };
  const Login = () => {
    var apiBaseUrl = baseApi;
    // var self = this; https://test.mai.family/cms-api/v1/user/authenticate

    if ("res") {
      var payload = {
        email: email,
        password: password,
      };

      axios(
        {
          method: "post",
          url: "https://test.mai.family/cms-api/" + "v1/user/authenticate",
          data: payload,
          headers: {
            "Content-Type": "application/json",
          },
        }
        // { withCredentials: true }
      )
        .then((response) => {
          if (response.data.statusCode === 201) {
            var date = Date.now();
            window.localStorage.setItem("apiBaseUrl", apiBaseUrl);
            window.localStorage.setItem(
              "user",
              JSON.stringify(response.data.data)
            );
            window.localStorage.setItem("timestamp", date);
            // this.props.handleSuccessfulAuth(response.data);
            // this.setState({
            //   toDashboard: true,
            // });
            history.push("/");
          } else if (response.data.statusCode === 400) {
            console.log("Username password do not match");
            alert("username password do not match");
          } else {
            alert("Wrong Credentials!! , Please try again");
          }
        })
        .catch((error) => {
          console.log("login error message", error.message);
          alert("User does not exist");
        });
    }
  };
  if (isLogin) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${loginbackground})`,
        // backgroundSize: "cover",
        overflow: "hidden",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        minHeight: window.screen.height,
      }}
    >
      <div
        component="main"
        style={{
          // maxWidth: "500px",
          // height: "100vh",
          display: "flex",
          padding: "2em 3em 0",
          // flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        <div style={{ width: "60%", marginRight: "14%" }}>
          <span style={{ display: "flex", alignItems: "flex-end" }}>
            <img src={logo} style={{ height: "125px" }} />
            <span style={{ marginBottom: "15px" }}>
              <Typography className={classes.tagline}>
                A revolutionary
              </Typography>
              <Typography className={classes.tagline}>
                Skill Development platform
              </Typography>
            </span>
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "2.5em",
              marginTop: "1.5em",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://juniormba.parentof.com/index.php/science/"
              target="_blank"
            >
              <span
                style={{
                  display: "flex",
                  padding: "5px 10px",
                  background: "rgba(255, 255, 255, 0.5)",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "4px",
                  marginRight: "20px",
                }}
              >
                <img
                  src={science}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "5px",
                  }}
                  alt="science"
                />
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "32px",
                    color: "#ffffff",
                  }}
                >
                  Science
                </Typography>
              </span>
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://juniormba.parentof.com/index.php/42-hots-skills-list/"
              target="_blank"
            >
              <span
                style={{
                  display: "flex",
                  padding: "5px 10px",
                  background: "rgba(255, 255, 255, 0.5)",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "4px",
                  marginRight: "20px",
                }}
              >
                <img
                  src={researchareas}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "5px",
                  }}
                  alt="Research Areas"
                />
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "32px",
                    color: "#ffffff",
                  }}
                >
                  Research Areas
                </Typography>
              </span>
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.parentof.com/media-centre.html"
              target="_blank"
            >
              <span
                style={{
                  display: "flex",
                  padding: "5px 10px",
                  background: "rgba(255, 255, 255, 0.5)",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "4px",
                  marginRight: "20px",
                }}
              >
                <img
                  src={media}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "5px",
                  }}
                  alt="Media"
                />
                <Typography
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "32px",
                    color: "#ffffff",
                  }}
                >
                  Media
                </Typography>
              </span>
            </a>
          </div>
          <Typography className={classes.description}>
            Cognitive and Behavioural training for children is still a
            relatively new field—which makes it an exciting space for new
            insights and research. That’s why Parentof conducts cognitive and
            Behavioural training research and facilitates the research of
            independent experts across domains of Motor Development, language
            and Communication, Behavioural and Emotional Management as well as
            Cognition —all with the goal of deepening our understanding of Child
            Growth and Development.
          </Typography>

          <Typography className={classes.description}>
            The ARC platform is our online, collaborative research and teaching
            platform. Through the ARC, we grant qualified experts access to
            Mai’s cognitive and behavioural training tasks and research tools,
            as well as limited access to data on cognitive and behavioural task
            performance. Individually, these collaborators investigate a wide
            spectrum of topics that interest them. As a whole, we're working
            together to push the field forward.
          </Typography>
          <Typography
            className={classes.description}
            style={{ marginBottom: 0 }}
          >
            If you are a expert and wish to contribute to the ARC platform,
            kindly write to{" "}
            <a
              href="mailto:hr@parentof.com"
              style={{
                color: "#F2C94C",
                textDecoration: "none",
              }}
              target="_blank"
            >
              hr@parentof.com
            </a>
          </Typography>
        </div>
        <div style={{ width: "40%", placeSelf: "center" }}>
          <div
            style={{
              width: "376px",
              height: "228px",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              background: "rgba(255, 255, 255, 0.2)",
              boxShadow: "0px 44px 44px rgba(0, 0, 0, 0.15)",
              borderRadius: "4px",
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                lineHeight: "32px",
                textAlign: "center",
                color: "#FFFFFF",
                marginBottom: "10px",
              }}
            >
              Login Here
            </Typography>
            <GoogleLogin
              clientId={googleToken}
              render={(renderProps) => (
                <img
                  src={googleloginImg}
                  style={{ cursor: "pointer", height: "60px" }}
                  alt="Sign in with Google"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                />

                // <button
                //   style={{
                //     background: "#d43737",
                //     color: "white",
                //     padding: "10px 30px",
                //   }}
                //   onClick={renderProps.onClick}
                //   disabled={renderProps.disabled}
                // >
                //   <img src={googleIcon} alt="" />
                //   Sign in with Google
                // </button>
              )}
              buttonText="Sign in with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
