import React, { useEffect, useState } from "react";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import { Typography, Button, InputBase, fade } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl, FormLabel } from "@material-ui/core";
import { RadioGroup, Radio } from "@material-ui/core";
import Dropdown from "../Dropdown/Dropdown";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";
import closeb from "../../assets/closeb.png";
import Datep from "./Datep";
import "../../App.css";
import FormHelperText from "@material-ui/core/FormHelperText";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `48%`,
    left: `49%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    marginTop: "-3%",
    marginLeft: "30px",
    marginBottom: "20px",
    lineHeight: "48px",
    fontSize: "24px",
    width: "400px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "36px",
    letterSpacing: "0.5px",
    color: "#242F57",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "17px 43px",
  },
  modalPaper: {
    position: "absolute",
    width: 450,
    left: "50% !important",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "0px 0px 25px",
    width: "100px",
  },
  inputfield: {
    width: "400px",
  },

  paper: {
    position: "absolute",
    width: "584px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 58px rgba(121, 145, 173, 0.195504)",
    borderRadius: "8px",
  },
  buttonx: {
    "&:hover": {
      background: "#ccc",
    },
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#242F57",
    background: "#F4F9FD",
    borderRadius: "14px",
    border: "none",
    cursor: "pointer",

    padding: "10px 20px",
  },
}));
var phoneValid;
var emailValid;
var empname = "abc";
var nameValid = false;
var optionValid;
var genderValid;
var passwordValid;
var dobvalid;
var descvalid;
export default function EditEmpModal(props) {
  const { datax } = props;
  console.log("data", datax);
  const classes = useStyles();
  const history = useHistory();
  var apiBaseUrl = baseApi;

  var user = JSON.parse(localStorage.getItem("user"));
  const [isLogin, setLogin] = React.useState(false);

  const [firstName, setfirstName] = React.useState("");
  const [taskName, settaskName] = React.useState("");
  const [priority, setpriority] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [dob, setdob] = React.useState("");
  const [dot, setdot] = React.useState("");
  const [namev, setnamev] = React.useState(true);
  const [emailv, setemailv] = React.useState(true);
  const [rolev, setrolev] = React.useState(true);
  const [dobv, setdobv] = React.useState(true);
  const [descv, setdescv] = React.useState(true);
  const [genv, setgenv] = React.useState(true);

  const [lastName, setlastName] = React.useState("");
  const [gender, setgender] = React.useState("");
  const [role, setrole] = React.useState("");
  const [mobileNumber, setmobileNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  let [users, setUsers] = useState();
  const [errors, setErrors] = useState();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  var payload = {
    firstName: taskName,
    gender: gender,
    role: priority,
    email: email,
    dob: dob,
    description: description,
    _id: datax._id,
  };

  useEffect(() => {
    function setData() {
      settaskName(datax.firstName);
      setpriority(datax.role);
      setgender(datax.gender);
      setEmail(datax.email);

      setdob(moment(datax.dob).format("YYYY-MM-DD"));
      setdescription(datax.description);
    }
    setData();
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setnamev(true);
    setgenv(true);

    setdescv(true);
    setrolev(true);
    setemailv(true);
  };
  const handleChange = (event) => {
    console.log("event", typeof event);
    if (typeof event === "string") {
      setdobv(true);
      setdob(event);
    } else {
      switch (event.target.name) {
        case "firstName":
          setfirstName(
            event.target.value.charAt(0).toUpperCase() +
              event.target.value.slice(1)
          );
          break;
        case "taskName":
          setnamev(true);
          settaskName(
            event.target.value.charAt(0).toUpperCase() +
              event.target.value.slice(1)
          );
          break;
        case "lastName":
          setlastName(event.target.value);
          break;
        case "description":
          setdescv(true);
          setdescription(event.target.value);
          break;
        case "gender":
          setgenv(true);
          setgender(event.target.value);
          break;
        case "role":
          setrolev(true);
          setrole(event.target.value);
          break;
        // case "dob":
        //   setdob(event.target.value);
        //   break;
        case "dot":
          setdot(event.target.value);
          break;
        case "priority":
          setrolev(true);

          setpriority(event.target.value);
          break;
        case "mobileNumber":
          setmobileNumber(event.target.value);
          break;
        case "email":
          setemailv(true);
          setEmail(event.target.value);
          break;
        case "password":
          setPassword(event.target.value);
          break;

        default:
          break;
      }
    }
  };
  const handleDateChange = (event) => {
    setdob(moment(event.target.value).format("YYYY-MM-DD"));
    // console.log("handleDateChange", event.target.value);
  };

  const handleSaveT = (props) => {
    console.log("hello", payload);
    handleAdd();
  };

  const handleAdd = (props) => {
    //Validate input
    // phoneValid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
    //   payload.mobileNumber
    // );
    emailValid = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(payload.email);
    if (emailValid) {
      setemailv(true);
    } else {
      setemailv(false);
    }

    var reg = /^[a-zA-Z ]*$/;
    nameValid = reg.test(payload.firstName);

    if (payload.firstName.length > 2 && nameValid) {
      nameValid = true;
      setnamev(true);
    } else {
      setnamev(false);
      nameValid = false;
    }

    // var reg2 = /^[a-zA-Z ]*$/;
    // descvalid = reg2.test(payload.description);

    // if (payload.description && payload.description.length > 2 && descvalid) {
    //   descvalid = true;
    //   setdescv(true);
    // } else {
    //   setdescv(false);
    //   descvalid = false;
    // }
    // var reg2 = /^[0-9!@#$%^&*\S"a-zA-Z]{3,26}$/;
    // passwordValid = reg2.test(payload.password);

    // if (passwordValid) {
    //   passwordValid = true;
    // }
    optionValid = false;
    if (payload.role === "") {
      setrolev(false);
      optionValid = false;
    } else {
      setrolev(true);
      optionValid = true;
    }
    genderValid = false;
    if (payload.gender === "") {
      setgenv(false);
      genderValid = false;
    } else {
      setgenv(true);
      genderValid = true;
    }

    dobvalid = false;
    if (payload.dob === "") {
      setdobv(false);
      dobvalid = false;
    } else {
      setdobv(true);
      dobvalid = true;
    }

    console.log(
      "optionval",
      emailValid,
      nameValid,
      optionValid,
      genderValid,
      dobvalid,
      descvalid
    );
    const formValid =
      emailValid && nameValid && optionValid && genderValid && dobvalid;
    if (!formValid) {
      console.log("error in field");
    } else {
      SignUp(payload);
    }
  };
  const SignUp = (props) => {
    var apiBaseUrl = baseApi;

    axios({
      method: "put",
      url: apiBaseUrl + "users/update",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    })
      .then((response) => {
        alert("Employee details updated successfully");
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Paper>
        <div style={{ height: "600px", overflowY: "scroll" }}>
          <div
            style={{
              padding: "20px 10px",
            }}
          >
            <div onClick={handleClose}>
              <img
                src={closeb}
                style={{
                  width: "40px",
                  cursor: "pointer",
                  float: "right",
                  right: "30px",
                }}
              />
            </div>

            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              style={{
                width: "90%",
                marginTop: "10px",
                paddingTop: "10px",
                marginBottom: "12px",
              }}
            >
              Edit Employee
            </Typography>
          </div>

          <div style={{ padding: "40px", paddingTop: "10px" }}>
            <form className={classes.form} noValidate>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {/* {namev && (
                    <TextField
                      autoComplete="fname"
                      name="taskName"
                      variant="outlined"
                      placeholder="Employee name"
                      required
                      fullWidth
                      id="taskName"
                      label="Full Name"
                      autoFocus
                      onChange={handleChange}
                    />
                  )} */}
                  <TextField
                    error={!namev}
                    value={taskName}
                    autoComplete="fname"
                    name="taskName"
                    variant="outlined"
                    placeholder="Employee name"
                    required
                    fullWidth
                    id="taskName"
                    helperText={!namev ? "Please check name" : ""}
                    label="Full Name"
                    autoFocus
                    onChange={handleChange}
                  />

                  {/* {!namev && (
                    <TextField
                      error
                      fullWidth
                      name="taskName"
                      label="Full Name"
                      defaultValue=""
                      id="taskName"
                      helperText="Error in field"
                      variant="outlined"
                      autoFocus
                      required
                      onChange={handleChange}
                    />
                  )} */}
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    placeholder="Enter Email"
                    value={email}
                    helperText={!emailv ? "Please check email" : ""}
                    error={!emailv}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    err={!rolev}
                    required
                    // value={priority}
                    data={priority}
                    changeevent={handleChange}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    autoComplete="off"
                    type="text"
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <input
                    onChange={handleDateChange}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      padding: "10px 20px",
                      border: "2px solid rgb(125, 133, 146)",
                      // fontFeatureSettings: "'salt' on",
                      color: "#7D8592",
                      width: "220px",
                      borderRadius: "6px",
                    }}
                    type="date"
                    id="start"
                    name="dob"
                    format="DD MMM YYYY"
                    value={dob}
                    max="2030-12-31"
                  />
                  {/* <Datep
                    error={dobv}
                    required
                    // value={datax.dob}
                    data={dob}
                    changeevent={handleChange}
                  /> */}
                </Grid>

                <Grid id="outlined-multiline-static" item xs={12}>
                  <TextField
                    label="Description"
                    error={!descv}
                    multiline
                    helperText={!descv ? "Error in field" : ""}
                    rows={4}
                    value={description}
                    name="description"
                    id="description"
                    placeholder="Add about the employee description"
                    variant="outlined"
                    fullWidth="true"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={4}>
                  <FormControl component="fieldset" error={!genv}>
                    <FormLabel
                      required
                      align="left"
                      component="legend"
                      style={{ marginBottom: "5px" }}
                    >
                      Gender
                    </FormLabel>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <RadioGroup
                          aria-label="gender"
                          onChange={handleChange}
                          name="gender"
                          value={gender}
                        >
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="Send Invitation"
              />
            </Grid> */}
              </Grid>

              <Button
                onClick={handleSaveT}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  background: "#4C6FFF",
                  boxShadow: "0px 6px 12px rgba(63, 140, 255, 0.263686)",
                  borderRadius: "8px",
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "14px",
                  textTransform: "none",
                  lineHeight: "17px",
                  padding: "10px 20px",
                  width: "145px",
                  float: "right",
                  margin: "-65px 30px 0px 20px",
                }}
              >
                Save Changes
              </Button>
            </form>
          </div>
        </div>
      </Paper>
    </div>
  );

  return (
    <>
      <button onClick={handleOpen} className={classes.buttonx}>
        Edit
      </button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
}
