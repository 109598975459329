import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { blue } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import MapsTableItem from "./MapsTableItem";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, rowCount, onRequestSort, headcells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  // const col = props.headcells;

  return (
    <TableHead>
      <TableRow>
        {headcells.map((headCell, index) => {
          if (
            headCell.name === "action" ||
            headCell.name === "user" ||
            headCell.name === "createdAt"
          ) {
            return (
              <TableCell
                key={index}
                align="center"
                // padding={'default'}
                className={classes.tablehead}
                sortDirection={orderBy === headCell.name ? order : false}
              >
                <TableSortLabel
                  style={{ fontWeight: 600 }}
                  active={orderBy === headCell.name}
                  direction={orderBy === headCell.name ? order : "asc"}
                  onClick={createSortHandler(headCell.name)}
                >
                  {headCell.displayName.charAt(0).toUpperCase() +
                    headCell.displayName.slice(1)}
                  {orderBy === headCell.name ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={index}
                align="center"
                // padding={'default'}
                className={classes.tablehead}
                sortDirection={orderBy === headCell.name ? order : false}
                style={{ fontWeight: 600 }}
              >
                {headCell.displayName.charAt(0).toUpperCase() +
                  headCell.displayName.slice(1)}
                {orderBy === headCell.name ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tablehead: {
    padding: "10px",
    fontSize: "13px",
  },
  tablerowstr: {
    wordWrap: "break-word",
    textAlign: "left",
    maxWidth: "170px",
  },
  tablecell: {
    border: "1px solid rgba(0,0,0,0.08)",
    padding: "10px 5px",
    fontSize: "13px",
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: "#0E8050",
    "&$checked": {
      color: "#0E8050",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

var rows = [];
var headcells = [];
export default function ViewsTrailTable(props) {
  rows = props.rows;
  headcells = props.headcells;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    // props.handlepagechange(newPage);
    setPage(newPage);
  };

  const handleClick = (event) => {
    console.log(event.target.value);

    history.push({
      pathname: `/mapstableitem`,
      state: { id: event.target.value },
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage;
  // rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const columns = headcells.map((cell) => cell.name);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer style={{ maxWidth: "100%" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              headcells={headcells}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      {/* <TableCell align="center"></TableCell> */}

                      {/* {console.log(row)} */}
                      {Object.values(columns).map((data, ind) => {
                        //   {console.log(row[columns[ind]])}
                        if (
                          typeof row[columns[ind]] === "object" &&
                          row[columns[ind]] != null
                        ) {
                          if (
                            row[columns[ind]].length > 1 &&
                            row[columns[ind]][0].name
                          ) {
                            //   console.log(row[columns[ind]][index].name );
                            let ar = row[columns[ind]].map((item) => item.name);
                            return (
                              <TableCell
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                              >
                                {ar.join(" , ")}
                              </TableCell>
                            );
                          } else if (
                            row[columns[ind]].length > 1 &&
                            !row[columns[ind]][0].name
                          ) {
                            return (
                              <TableCell
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                              >
                                {row[columns[ind]]}
                              </TableCell>
                            );
                          } else if (
                            row[columns[ind]].length < 2 &&
                            row[columns[ind]].length > 0
                          ) {
                            if (row[columns[ind]][0].name) {
                              return (
                                <TableCell
                                  className={classes.tablecell}
                                  key={ind}
                                  align="center"
                                >
                                  {row[columns[ind]][0].name}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  className={classes.tablecell}
                                  key={ind}
                                  align="center"
                                >
                                  {row[columns[ind]]}
                                </TableCell>
                              );
                            }
                          } else
                            return (
                              <TableCell
                                className={classes.tablecell}
                                key={ind}
                                align="center"
                              >
                                {row[columns[ind]].name}
                              </TableCell>
                            );
                        } else if (row[columns[ind]] === null) {
                          return (
                            <TableCell
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            ></TableCell>
                          );
                        } else if (row[columns[ind]] === "img") {
                          return (
                            <TableCell
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            >
                              image
                            </TableCell>
                          );
                        } else if (typeof row[columns[ind]] === "boolean") {
                          return (
                            <TableCell
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            >
                              <GreenCheckbox
                                checked={true}
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              className={classes.tablecell}
                              key={ind}
                              align="center"
                            >
                              {row[columns[ind]]}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length === 0 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4>No Search Results Found</h4>
          </div>
        )}
        <TablePagination
          rowsPerPageOptions={[100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
