import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { baseApi } from "../../Utils/config";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
  { id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
  { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
  { id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.name + i}
            align="center"
            sortDirection={orderBy === headCell.name ? order : false}
            style={{ fontWeight: 600, padding: "13px 15px" }}
          >
            {headCell.displayName.charAt(0).toUpperCase() +
              headCell.displayName.slice(1)}

            <TableSortLabel
              style={{ fontWeight: 600 }}
              active={orderBy === headCell.name}
              direction={orderBy === headCell.name ? order : "asc"}
              // onClick={createSortHandler(headCell.name)}
            >
              {/* {orderBy === headCell.name ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tablecell: {
    // border: "1px solid rgba(0,0,0,0.08)"
  },
  tablehead: {
    padding: "10px 5px",
  },
}));
const GreenCheckbox = withStyles({
  root: {
    color: "#0E8050",
    "&$checked": {
      color: "#0E8050",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function RolesTable({ rows, headCells }) {
  const classes = useStyles();
  let tableRows = rows;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selectedState, setSelected] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const columns = headCells.map((cell) => cell.name);
  var user = JSON.parse(localStorage.getItem("user"));
  const [dense, setDense] = React.useState(true);

  useEffect(() => {
    const selected = {};
    tableRows.forEach((row) => {
      selected[row.isAdd._id] = {
        isAdd: row.isAdd.displayName,
        isEdit: row.isEdit.displayName,
        isView: row.isView.displayName,
      };
    });
    setSelected(selected);
  }, [tableRows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableRows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckBox = (event, id, name) => {
    //console.log(id, name, event.target.checked);
    let postData = {
      _id: id,
      [name]: `${event.target.checked}`,
    };
    const updatedObj = {
      ...selectedState,
      [id]: {
        ...selectedState[id],
        [name]: event.target.checked,
      },
    };
    fetchData(postData);
    setSelected(updatedObj);
  };
  async function fetchData(payload) {
    var apiBaseUrl = baseApi;
    axios({
      method: "put",
      url: apiBaseUrl + "v1/user/role-permission",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    })
      .then((res) => {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 1000);
      })
      .catch((err) => {
        alert("Unable to Update at the moment");
        console.log(err);
      });
  }

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableRows.length - page * rowsPerPage);

  if (!Object.keys(selectedState).length) {
    return null;
  }
  if (tableRows.length === 0) {
    return "Not Found";
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ position: "fixed", top: "70px", left: "45%" }}>
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Successfully Updated
            </Alert>
          </Collapse>
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tableRows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(tableRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name + index}
                    >
                      {Object.keys(row).map((rowData, index) => {
                        if (typeof row[columns[index]] === "object") {
                          if (row[columns[index]].CardType === "Checkbox") {
                            return (
                              <TableCell
                                align="center"
                                className={classes.tablecell}
                                key={"k" + index}
                              >
                                <GreenCheckbox
                                  checked={
                                    selectedState.hasOwnProperty(
                                      row[columns[index]]._id
                                    ) &&
                                    selectedState[row[columns[index]]._id][
                                      columns[index]
                                    ]
                                  }
                                  onChange={(e) =>
                                    handleCheckBox(
                                      e,
                                      row[columns[index]]._id,
                                      columns[index]
                                    )
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                align="center"
                                className={classes.tablecell}
                                key={"k" + index}
                              >
                                {row[columns[index]].name}
                              </TableCell>
                            );
                          }
                        } else {
                          return (
                            <TableCell
                              align="center"
                              className={classes.tablecell}
                              key={"k" + index}
                            >
                              {row[columns[index]]}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
