import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import usimg from "../../assets/usimg.png";
import editicon from "../../assets/editicon.png";
import FormControlLabelPosition from "./CheckBoxList";
import moment from "moment";
import Revoke from "./Revoke";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    cursor: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listname: {
    margin: "10px 20px",
  },
  dataitem: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0A1629",
  },
}));
var user = [];
export default function ListComp({ role, data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  // let length = data.length;
  const history = useHistory();
  // user = props.location.state;

  // console.log("data users", data);
  // console.log(user);

  const handleClick = () => {
    setOpen(!open);
  };
  const calculate_age = (dob) => {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    return Math.abs(age.getUTCFullYear() - 1970);
  };

  useEffect(() => {
    // document.body.style.zoom = "70%";
  }, [role]);
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <p
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "24px",
              letterSpacing: "0.5px",
              color: "#242F57",
              padding: "35px 15px 0px",
            }}
          >
            All Users ({data && Object.keys(data).length})
          </p>
        </ListSubheader>
      }
      className={classes.root}
    >
      {data &&
        data
          .map((item, ind) => {
            return (
              <ListItem button key={ind}>
                {item.userStatus == "active" && (
                  <div style={{ display: "flex", cursor: "auto" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "10px 10px",
                      }}
                    >
                      <ListItemIcon>
                        <img
                          alt=""
                          src={item.profilePic}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </ListItemIcon>
                    </div>
                    <Link
                      key={ind}
                      to={{
                        pathname: "/manageusers/profile",
                        // hash: "#the-hash",
                        state: item,
                      }}
                      style={{
                        textDecoration: "none",
                        // color:
                        //   pathname == "/manageRoles" || pathname == "/addrole"
                        //     ? "#0057FF"
                        //     : "#33343D",
                        fontWeight: "500",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 10px",
                        }}
                      >
                        <ListItemText className={classes.listname}>
                          <p
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#0A1629",
                              margin: 0,
                            }}
                          >
                            {item.firstName?.charAt(0).toUpperCase() +
                              item.firstName?.slice(1)}
                          </p>
                        </ListItemText>

                        <ListItemText>
                          <p
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "14px",
                              lineHeight: "19px",

                              color: "#91929E",
                              margin: "-8px 19px 0px",
                            }}
                          >
                            {item.email}
                          </p>
                        </ListItemText>
                      </div>
                    </Link>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "10%",
                      }}
                    >
                      <ListItemText
                        primary="Gender"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {item.gender.charAt(0).toUpperCase() +
                            item.gender.slice(1)}
                        </p>
                      </ListItemText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "13%",
                      }}
                    >
                      <ListItemText
                        primary="Date of joining"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {moment(item.dob).format("DD MMM YYYY")}
                        </p>
                      </ListItemText>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "10%",
                      }}
                    >
                      <ListItemText
                        primary="Full Age"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {calculate_age(
                            moment(item.dob).format("DD MMM YYYY")
                          )}
                        </p>
                      </ListItemText>
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "15%",
                      }}
                    >
                      <ListItemText
                        primary="Role"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            textTransform: "capitalize",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {item.role.charAt(0).toUpperCase() +
                            item.role.slice(1)}
                        </p>
                      </ListItemText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "0 10px",
                        width: "30%",
                        marginLeft: "7%",
                      }}
                    >
                      <ListItemText>
                        <button
                          style={{
                            background: "#4C6FFF",
                            borderRadius: "8px",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#FFFFFF",
                            height: "32px",
                            minWidth: "128px",
                            marginTop: "30px",
                            cursor: "pointer",
                            marginRight: "20px",
                          }}
                          onClick={() => {
                            history.push({
                              pathname: `/manageusers/profile`,
                              state: item,
                            });
                          }}
                        >
                          Edit Access
                        </button>
                      </ListItemText>

                      <ListItemText
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {/* <img
                        src={editicon}
                        style={{
                          width: "44px",
                          height: "44px",
                          marginTop: "23px",
                        }}
                      /> */}
                        <Revoke datax={item} />
                      </ListItemText>
                    </div>
                  </div>
                )}

                {item.userStatus == "inactive" && (
                  <div
                    style={{
                      display: "flex",
                      background: "#eee",
                      cursor: "not-allowed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "10px 10px",
                      }}
                    >
                      <ListItemIcon>
                        <img
                          alt=""
                          src={item.profilePic}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </ListItemIcon>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                      }}
                    >
                      <ListItemText
                        primary={
                          item.firstName.charAt(0).toUpperCase() +
                          item.firstName.slice(1)
                        }
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#0A1629",
                        }}
                      />

                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "19px",

                            color: "#91929E",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {item.email}
                        </p>
                      </ListItemText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "10%",
                      }}
                    >
                      <ListItemText
                        primary="Gender"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {item.gender.charAt(0).toUpperCase() +
                            item.gender.slice(1)}
                        </p>
                      </ListItemText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "13%",
                      }}
                    >
                      <ListItemText
                        primary="Date of joining"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {moment(item.dob).format("DD MMM YYYY")}
                        </p>
                      </ListItemText>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "10%",
                      }}
                    >
                      <ListItemText
                        primary="Full Age"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {calculate_age(
                            moment(item.dob).format("DD MMM YYYY")
                          )}
                        </p>
                      </ListItemText>
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 10px",
                        width: "15%",
                      }}
                    >
                      <ListItemText
                        primary="Role"
                        className={classes.listname}
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#91929E",
                        }}
                      />
                      <ListItemText>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#0A1629",
                            margin: "-8px 19px 0px",
                          }}
                        >
                          {item.role.charAt(0).toUpperCase() +
                            item.role.slice(1)}
                        </p>
                      </ListItemText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "0 10px",
                        width: "30%",
                        marginLeft: "7%",
                      }}
                    >
                      <ListItemText>
                        <button
                          style={{
                            background: "#4C6FFF",
                            borderRadius: "8px",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "14px",
                            color: "#FFFFFF",
                            height: "32px",
                            minWidth: "128px",
                            marginTop: "30px",
                            marginRight: "20px",
                            cursor: "not-allowed",
                          }}
                        >
                          Edit Access
                        </button>
                      </ListItemText>

                      <ListItemText>
                        {/* <img
                        src={editicon}
                        style={{
                          width: "44px",
                          height: "44px",
                          marginTop: "23px",
                        }}
                      /> */}
                        <Revoke datax={item} />
                      </ListItemText>
                    </div>
                  </div>
                )}
              </ListItem>
            );
          })
          .reverse()}

      {data.length === 0 && (
        <>
          <div>
            <h2
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#0A1629",
                margin: "30px",
              }}
            >
              No data found
            </h2>
          </div>
        </>
      )}
    </List>
  );
}
