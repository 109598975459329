import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Popover from "@material-ui/core/Popover";
import { useHistory } from "react-router-dom";
import foldericon from "../../assets/folder_outlined.png";
import fold from "../../assets/fold.png";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";
import lock from "../../assets/lock.png";

function createData(name) {
  return { name };
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "auto",
  },
  typography: {
    padding: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "4px 4px 10px rgba(0,0,0,0.05)",
  },
  container: {
    marginTop: 16,
    marginRight: 52,
    width: "447px",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: "6px 6px 0px 0px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "30px",
    letterSpacing: "0.5px",
    color: "#242F57",
    borderBottom: "1px solid #eee",
  },

  hover: {
    display: "flex",

    "&:hover": {
      background: "#F4FAFF",
      borderLeft: "solid 2px #369AFE",
    },
    // "&:last-child": {
    // },
  },
}));
var payload = [];

export default function CustomizedTables(props) {
  const { content, contentX } = props;
  const [tableList, setTableList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  var user = JSON.parse(localStorage.getItem("user"));

  const classes = useStyles();

  useEffect(() => {
    setTableList(props.content);
  }, [props.content]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickroute = (name) => () => {
    if (name == "Content Maps") {
      history.push({
        pathname: `/maps`,
      });
    }
    if (name == "Content Tags") {
      history.push({
        pathname: `/tags`,
      });
    }
    if (name == "Content Views") {
      history.push({
        pathname: `/views`,
      });
    }
  };
  const Audit = (payload) => {
    var apiBaseUrl = baseApi;

    axios({
      method: "post",
      url: apiBaseUrl + "users/auditTrail/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    })
      .then((response) => {
        console.log("post called");
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  };
  const handleLock = () => {
    alert("hello");
  };
  const handleClickitem = (item) => () => {
    console.log("data ---------", item);
    console.log("contentype ---------", contentX);

    payload = {
      functionality: contentX,
      module: item.moduleHeader,
      subModule: item.subModule.moduleTitle,
      url: item.subModule.url,
      _userId: user.id,
    };

    console.log("payload ---------", payload);
    Audit(payload);

    window.open(item.subModule.url, "_blank");
  };
  return (
    <Grid
      style={{
        margin: "0px 35px 30px 0px",
        border: "1px solid #EAEDF7",
        boxShadow: "0px 2px 4px rgba(28, 41, 90, 0.0367952)",
        borderRadius: "10px",
      }}
    >
      <Grid>
        <div className={classes.title}>
          {content.name !== "Content Bookmarks" ? (
            <Typography
              onClick={handleClickroute(content.name)}
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "22px",
                lineHeight: "30px",
                letterSpacing: "0.5px",
                color: "#242F57",
              }}
              variant="subtitle1"
            >
              {content.moduleHeader}
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "22px",
                lineHeight: "30px",
                letterSpacing: "0.5px",
                color: "#242F57",
              }}
            >
              {content.moduleHeader}
            </Typography>
          )}
          {/* {content.name !== "Content Bookmarks" && (
            <MoreHorizIcon
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            />
          )} */}
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              onClick={handleClickroute(content.name)}
              className={classes.typography}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              View All
            </Typography>
          </Popover> */}
        </div>

        <div className={classes.demo}>
          <List style={{ paddingBottom: "0px" }}>
            {tableList.length == 0 && (
              <>
                <h3
                  style={{
                    padding: "12% 27%",
                    fontFamily: "Helvetica Neue",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  No data found
                </h3>
              </>
            )}
            {content.data &&
              content.data.length > 0 &&
              content.data.map((item, ind) => (
                <div
                  style={{
                    display: "flex",
                    background: item.subModule.isView
                      ? ""
                      : "rgba(0, 0, 0, 0.1)",
                    cursor: item.subModule.isView ? "pointer" : "not-allowed",
                  }}
                  className={classes.hover}
                  key={ind}
                  onClick={item.subModule.isView ? handleClickitem(item) : {}}
                >
                  {item.subModule.isView ? (
                    ""
                  ) : (
                    <img
                      src={lock}
                      alt=""
                      style={{
                        width: "36px",
                        position: "absolute",
                        right: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "20px",
                        zIndex: "1",
                      }}
                    />
                  )}
                  <ListItem
                    style={{
                      fontFamily: "Helvetica Neue",
                      borderBottom: "2px solid rgba(0,0,0,0.08)",
                      // display: "flex !important",
                      flexDirection: "row",
                    }}
                    // value={row._id}
                  >
                    {/* <ListItemAvatar>
            
                    </ListItemAvatar> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "40px",
                      }}
                    >
                      <img
                        src={fold}
                        style={{ width: "40px", height: "33px" }}
                        alt="folder"
                      />
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "22px",
                          letterSpacing: "0.5px",
                          color: "#242F57",
                          marginLeft: "25px",
                        }}
                      >
                        {item.subModule.moduleTitle}
                      </h2>
                    </div>
                  </ListItem>
                </div>
              )).reverse()}
          </List>
        </div>
      </Grid>
    </Grid>
  );
}
