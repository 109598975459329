import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 200,
  },
  tbContainer: { marginTop: "100px" },
}));

function TableList({ users }) {
  const { columns, data } = users;
  const classes = useStyles();
  return (
    <div className={classes.tbContainer}>
      <TableContainer component={Paper} className={classes.tbContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => {
                return (
                  <StyledTableCell
                    key={i}
                    style={{
                      borderRight: "0.1em solid lightgrey",
                      padding: "0.5em",
                    }}
                    align="center"
                  >
                    {column.displayName}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell
                  style={{
                    borderRight: "0.1em solid lightgrey",
                    padding: "0.5em",
                  }}
                  align="center"
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderRight: "0.1em solid lightgrey",
                    padding: "0.5em",
                  }}
                  align="center"
                >
                  {row.email}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderRight: "0.1em solid lightgrey",
                    padding: "0.5em",
                  }}
                  align="center"
                >
                  {row.role.name}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    borderRight: "0.1em solid lightgrey",
                    padding: "0.5em",
                  }}
                  align="center"
                >
                  {row.active.displayName.toString()}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableList;
