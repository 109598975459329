import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { baseApi } from "../../Utils/config";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    paddingLeft: "10px !important",
  },
}));

export default function Drop3({ changeevent, data, err, task }) {
  const classes = useStyles();
  const [priority, setPriority] = React.useState("");

  const handleChange = (event) => {
    setPriority(event.target.value);
    changeevent(event);
  };

  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));

  const [roleoptions, setroleoptions] = useState();
  const [errors, setErrors] = useState();

  // useEffect(() => {
  //   function fetchData() {
  //     const res = axios(
  //       {
  //         method: "get",
  //         url: apiBaseUrl + "v1/user/role/list",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-auth-token": user.apiToken,
  //         },
  //       }
  //       // { withCredentials: true }
  //     );
  //     res
  //       .then((res) => {
  //         //console.log(res.data.data);
  //         setroleoptions(res.data.data);
  //       })
  //       .catch((err) => setErrors(err));
  //   }
  //   fetchData();
  // }, []);

  return (
    <div>
      {data && data.length > 0 && (
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={err}
        >
          <InputLabel required id="demo-simple-select-outlined-label">
            Tool
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Tool"
            name="via"
            autoComplete="lname"
            value={priority}
            onChange={handleChange}
            required
          >
            <MenuItem value="">
              <em>Tool</em>
            </MenuItem>
            {["Phone Call", "Zoom", "Google Hangouts", "Google Meet"].map(
              (priority, i) => (
                <MenuItem className={classes.title} key={i} value={priority}>
                  {" " + priority.charAt(0).toUpperCase() + priority.slice(1)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
