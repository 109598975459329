import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import { DateRangePicker } from "react-date-range";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { baseApi } from "../../Utils/config";
import SimpleTabs from "./TabsPanel";
import {
  UserAttendanceReport,
  OverAllIdleTimeReport,
  TasksReportCard,
  SessionsReportCard,
} from "../DataCards/Datacards";
import ModuleUsage from "../Admin/ModuleUsage";
import TotalEdits from "../Admin/TotalEdits";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import Modalt from "../Users/Modalt";
import UserProfile from "../Users/UserProfile";
import AuditLogs from "../Admin/AuditLogs";
import ModalSchedule from "../Users/AddSchedule";
import DateRangeComponent from "../Users/DateRangePicker";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FAFCFE",
    border: "1px solid #97A0C3",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",

    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginRight: "2%",
    width: "260px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
let AUTH_ERROR_STATUS_CODES = [401, 402, 403];
export default function Tasks(props) {
  var apiBaseUrl = baseApi;
  var employeeID = "props.location.state._id.toString()";
  const [dashboardData, setDashboardData] = useState();
  const [usersData, setUsersData] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [auditdata, setauditdata] = useState("");
  const [analyticsData, setAnalyticsData] = useState("");
  const [activeUsersAnalytics, setActiveUsersAnalytics] = useState("");
  const [load, setload] = useState(true);
  const [filteredData, setFilteredData] = useState();
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState();
  var user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleSelectChange = (event) => {
    console.log(usersData[event.target.value]);
    setUserDetails(usersData[event.target.value]);
    UserAudDetails(usersData[event.target.value]._id);
  };

  const handleChange = (event) => {
    let dashboardList = JSON.parse(JSON.stringify(dashboardData));
    let searchList = dashboardList.data.map((content) => {
      let filter = content.list.filter((item) =>
        item.displayName
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      content.list = filter;
      return content;
    });
    dashboardList.data = searchList;
    setFilteredData(dashboardList);
  };
  // const [selectedDate, setSelectedDate] = React.useState("");

  const handleDateChange = (date) => {
    // setSelectedDate(date);
    console.log(date);
    UserAnalyticsDetails(date);
  };
  function UserData() {
    const res = axios(
      {
        method: "get",
        url: apiBaseUrl + `users/userDetails?role=all`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setUsersData(res.data.data);
        setUserDetails(res.data.data[0]);
        UserAudDetails(res.data.data[0]._id);
        // setuserscol(res.data.data.columns);
        // setorgusers(res.data.data.data);
        setload(false);
      })
      .catch((err) => setErrors(err));
  }

  useEffect(() => {
    UserData();
    UserAnalyticsDetails();
  }, []);

  function UserAudDetails(employeeID) {
    const res = axios(
      {
        method: "get",
        url: apiBaseUrl + `users/auditTrail/${employeeID}?query=today`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setauditdata(res.data);
      })
      .catch((err) => setErrors(err));
  }
  function UserAnalyticsDetails(selectedDate) {
    const res = axios(
      {
        method: "get",
        url:
          apiBaseUrl +
          `users/analytics?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${selectedDate.toDate}`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setAnalyticsData(res.data.data);
      })
      .catch((err) => setErrors(err));
  }

  return (
    <div className={classes.container}>
      <div
        style={{
          margin: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "35px",
          }}
        >
          <div>
            <Typography
              variant="h4"
              align="left"
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "#242F57",
                marginBottom: "10px",
                textTransform: "capitalize",
              }}
            >
              Hi {user && user.name}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#636E95",
                marginBottom: "3%",
              }}
            >
              Here is the list of tasks assiged for your today 12 March 2021
            </Typography>
          </div>
          <DateRangeComponent handleDateChange={handleDateChange} />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            // justifyContent: "space-between",
          }}
        >
          {analyticsData && analyticsData.loggedIn && (
            <UserAttendanceReport analyticsData={analyticsData.loggedIn} />
          )}
          {analyticsData && analyticsData.ideltimeData && (
            <OverAllIdleTimeReport analyticsData={analyticsData.ideltimeData} />
          )}
          {analyticsData && analyticsData.task && (
            <TasksReportCard analyticsData={analyticsData.task} />
          )}
          {analyticsData && analyticsData.task && (
            <SessionsReportCard analyticsData={analyticsData.task} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            // justifyContent: "space-between",
          }}
        >
          <TotalEdits />
          {/* <ModuleUsage moduleUsage={analyticsData.moduleUsage} /> */}
        </div>
        {usersData && userDetails && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "2em",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{ marginRight: "10px", cursor: "pointer" }}
                className={classes.avatar}
              >
                <PersonIcon />
              </Avatar>
              <div style={{ marginRight: "20px" }}>
                <h4
                  style={{
                    margin: "0",
                    marginBottom: "4px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "17px",
                    color: "#404040",
                  }}
                >
                  {userDetails.firstName}
                </h4>
              </div>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  User
                </InputLabel>
                <Select
                  native
                  // value={userDetails.firstName}
                  label="User"
                  onChange={handleSelectChange}
                  inputProps={{
                    name: "User",
                    id: "outlined-age-native-simple",
                  }}
                >
                  {usersData &&
                    usersData.map((user, i) => (
                      <option key={i + "user"} value={i}>
                        {user.firstName}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <div style={{ margin: "1em" }}>
                <Modalt userDetails={userDetails} />
              </div>
              <div style={{ margin: "1em" }}>
                <ModalSchedule userDetails={userDetails} />
              </div>
            </span>
          </div>
        )}
        {userDetails ? (
          <div className={classes.gridList}>
            <SimpleTabs userDetails={userDetails} />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.gridList}>
          {auditdata && <AuditLogs data={auditdata} />}
        </div>
      </div>
    </div>
  );
}
