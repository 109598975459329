import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";

const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    marginTop: "1%",
    marginLeft: "2%",
    lineHeight: "48px",
    fontSize: "24px",
    width: "400px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 43px",
  },
  header: {
    backgroundColor: "#0057FF",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    color: "white",
    textAlign: "center",
    fontSize: "1.2em",
    width: "150px",
    lineHeight: "50px",
  },
}));

export default function AddRole() {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const history = useHistory();

  var user = JSON.parse(localStorage.getItem("user"));

  const classes = useStyles();
  var payload = {
    name: name,
    description: description,
  };
  const handleChange = (event) => {
    switch (event.target.name) {
      case "name":
        setName(
          event.target.value.charAt(0).toUpperCase() +
            event.target.value.slice(1)
        );
        break;
      case "description":
        setDescription(event.target.value);
        break;
      default:
        break;
    }
  };
  var nameValid = false;
  var descriptionValid = false;

  const AddRole = (props) => {
    var apiBaseUrl = baseApi;
    if (payload.name.length > 3) {
      nameValid = true;
    }
    if (payload.description.length > 3) {
      descriptionValid = true;
    }
    const formValid = descriptionValid && nameValid;

    if (!formValid) {
      alert("Please fill details");
    } else {
      axios({
        method: "post",
        url: apiBaseUrl + "v1/user/role",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      })
        .then((response, error) => {
          if (response.data.statusCode === 201) {
            alert("Role added successfully");
            history.push("/manageroles");
          } else if (response.data.statusCode === 400) {
            alert("problem with POST API with code 400" + error.message);
          } else {
            alert("problem with Post API" + error.message);
          }
        })
        .catch((error) => {
          alert(error.response.data.error.message);
        });
    }
  };

  return (
    <>
      <Typography
        align="left"
        variant="h5"
        className={classes.title}
        style={{ marginLeft: "20px" }}
      >
        Manage Roles {">"} Add Role
      </Typography>
      <Container
        style={{ margin: "2% auto", minWidth: "600px" }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Paper>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Role details
            </Typography>
            <TextField
              name="name"
              style={{ margin: "40px 0", width: "450px" }}
              id="outlined-basic"
              variant="outlined"
              onChange={handleChange}
              placeholder="Role Name *"
              required
            />
            <TextField
              name="description"
              id="outlined-textarea"
              placeholder="Role Description *"
              multiline
              rows={8}
              variant="outlined"
              onChange={handleChange}
              style={{
                margin: "20px 0",
                width: "450px",
                bottom: "20px",
              }}
              required
            />
            <Button
              variant="contained"
              color="primary"
              align="right"
              onClick={AddRole}
              style={{
                margin: "20px 0",
                width: "100px",
                bottom: "20px",
                background: "#0E8050",
                boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                borderRadius: "3px",
                color: "#ffffff",
                fontWeight: 600,
                fontSize: "14px",
                textTransform: "none",
                lineHeight: "17px",
                padding: "10px 20px",
              }}
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Container>
    </>
  );
}
