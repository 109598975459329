import React, { useEffect, useState } from "react";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { baseApi } from "../../Utils/config";
import { Doughnut } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import progressIcon from "../../assets/progressIcon.png";
import { CircularProgress } from "@material-ui/core";
import DateRangeComponent from "../Users/DateRangePicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "95px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    float: "left",
    marginTop: "1%",
    marginLeft: "2%",
    lineHeight: "48px",
    fontSize: "24px",
  },
  button: {
    marginLeft: "39% !important",
    marginTop: "-3%",
  },
  container: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    padding: "20px 30px 0px",
    margin: "20px",
  },
  progressColor: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFEEF1",
    borderRadius: "8px",
    height: "46px",
    width: "46px",
  },
}));

export default function ModuleUsage() {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [moduleUsage, setModuleUsage] = useState("");
  const [role, setrole] = React.useState("admin");

  let backGroundColors = [
    "#FF92AE",
    "#4C6FFF",
    "#66CB9F",
    "#F7936F",
    "#A6B7D4",
  ];
  function ModuleAnalytics(selectedDate) {
    const res = axios(
      {
        method: "get",
        url:
          apiBaseUrl +
          `users/analytics/moduleUsage?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${selectedDate.toDate}`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        console.log(res.data.data);
        setModuleUsage(res.data.data);
      })
      .catch((err) => setErrors(err));
  }
  useEffect(() => {
    ModuleAnalytics();
  }, []);

  const options = {
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  // console.log("moduleUsage", moduleUsage);
  let moduleUsageData =
    moduleUsage &&
    moduleUsage
      .filter((eachModule) => {
        if (role == "all") {
          return eachModule;
        } else {
          return eachModule.role === role;
        }
      })
      .map((item) => item.count);
  const data = {
    // labels: ["#A6B7D4", "#4C6FFF", "#FF92AE"],
    datasets: [
      {
        maintainAspectRatio: false,
        responsive: false,
        label: "My First Dataset",
        data: moduleUsageData,
        backgroundColor: backGroundColors,
        hoverOffset: 4,
      },
    ],
  };
  if (!moduleUsage) {
    return <CircularProgress />;
  }
  const handleDateChange = (date) => {
    ModuleAnalytics(date);
  };
  const roles = [
    "all",
    "admin",
    "r&d head",
    "delivery experts",
    "course coordinator",
    "product head",
    "mai gq experts",
  ];
  const handleChange = (event) => {
    console.log("event", event);
    setrole(event.target.value);
  };

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "375px",
        }}
      >
        <h4
          style={{
            margin: "0",
            color: "#323B4B",
            marginBottom: "1em",
            fontWeight: 600,
            fontSize: "16px",
          }}
        >
          Module Usage
        </h4>
        <DateRangeComponent handleDateChange={handleDateChange} />
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel required id="outlined-age-native-simple">
              Roles
            </InputLabel>
            <Select
              native
              // labelId="demo-simple-select-outlined-label"
              // id="outlined-age-native-simple"
              inputProps={{
                name: "Role",
                id: "outlined-age-native-simple",
              }}
              value={role}
              onChange={handleChange}
              style={{ height: "43px" }}
              label="Select User Role *"
              required
            >
              {roles.map((role, i) => (
                <option className={classes.title} key={i} value={role}>
                  {" " + role}
                </option>
              ))}
            </Select>
          </FormControl>
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "200px",
          height: "200px",
        }}
      >
        <Doughnut data={data} options={options} height={100} width={100} />
      </div>
      {moduleUsage && moduleUsage.length ? (
        <div
          style={{
            display: "flex",
            width: "315px",
            marginTop: "1em",
            marginRight: "auto",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {moduleUsage
            .filter((eachModule) => {
              if (role == "all") {
                return eachModule;
              } else {
                return eachModule.role === role;
              }
            })
            .map((item, i) => {
              return (
                <span key={i} style={{ width: "103px" }}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        backgroundColor:
                          backGroundColors[i % backGroundColors.length],
                        minWidth: "8px",
                        maxWidth: "8px",
                        minHeight: "8px",
                        maxHeight: "8px",
                      }}
                    ></div>
                    <span
                      style={{
                        marginTop: "-4px",
                        marginLeft: "10px",
                        fontSize: "12px",
                        lineHeight: "20px",
                        color: "#27272E",
                      }}
                    >
                      {item.count}
                    </span>
                  </div>
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "2em",
                      fontSize: "10px",
                      lineHeight: "12px",
                      color: "#7A7A9D",
                    }}
                  >
                    {item.functionality}
                  </p>
                </span>
              );
            })}
        </div>
      ) : (
        "No Data Available"
      )}
    </div>
  );
}
