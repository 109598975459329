import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl, FormLabel } from "@material-ui/core";
import { RadioGroup, Radio } from "@material-ui/core";
import Dropdown from "../Dropdown/Dropdown";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    marginTop: "1%",
    marginLeft: "2%",
    lineHeight: "48px",
    fontSize: "24px",
    width: "400px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "17px 43px",
  },
  modalPaper: {
    position: "absolute",
    width: 450,
    left: "50% !important",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100px",
  },
  inputfield: {
    width: "400px",
  },
}));

var phoneValid;
var emailValid;
var nameValid = false;
var optionValid;
var genderValid;
var passwordValid;

export default function AddUser() {
  const classes = useStyles();
  const history = useHistory();
  var apiBaseUrl = baseApi;

  var user = JSON.parse(localStorage.getItem("user"));
  const [isLogin, setLogin] = React.useState(false);

  const [firstName, setfirstName] = React.useState("");
  const [lastName, setlastName] = React.useState("");
  const [gender, setgender] = React.useState("");
  const [role, setrole] = React.useState("");
  const [mobileNumber, setmobileNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  let [users, setUsers] = useState();
  const [errors, setErrors] = useState();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  var payload = {
    firstName: firstName,
    lastName: lastName,
    gender: gender,
    role: role,
    email: email,
    mobileNumber: mobileNumber,
    password: password,
  };

  useEffect(() => {
    async function fetchData() {
      const res = axios(
        {
          method: "get",
          url: apiBaseUrl + "v1/user/role-assign",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.apiToken,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          //console.log(res.data.data);
          setUsers(res.data.data);
        })
        .catch((err) => setErrors(err));
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "firstName":
        setfirstName(
          event.target.value.charAt(0).toUpperCase() +
            event.target.value.slice(1)
        );
        break;
      case "lastName":
        setlastName(event.target.value);
        break;
      case "gender":
        setgender(event.target.value);
        break;
      case "role":
        setrole(event.target.value);
        break;
      case "mobileNumber":
        setmobileNumber(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;

      default:
        break;
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = (props) => {
    //Validate input
    phoneValid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
      payload.mobileNumber
    );
    emailValid = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(payload.email);

    var reg = /^[a-z]{3,26}$/i;
    nameValid = reg.test(payload.firstName);

    if (payload.firstName.length > 2 && nameValid) {
      nameValid = true;
    }

    var reg2 = /^[0-9!@#$%^&*\S"a-zA-Z]{3,26}$/;
    passwordValid = reg2.test(payload.password);

    // if (passwordValid) {
    //   passwordValid = true;
    // }
    optionValid = false;
    if (payload.role != "") {
      optionValid = true;
    }
    genderValid = false;
    if (payload.gender != "") {
      genderValid = true;
    }

    const formValid =
      emailValid &&
      phoneValid &&
      nameValid &&
      optionValid &&
      genderValid &&
      passwordValid;

    if (!formValid) {
      handleOpen();
    } else {
      SignUp(payload);
    }
  };

  const SignUp = (props) => {
    var apiBaseUrl = baseApi;

    axios({
      method: "post",
      url: apiBaseUrl + "v1/user/add",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    })
      .then((response, error) => {
        if (response.data.statusCode === 201) {
          history.push("/manageusers");
          alert("User added successfully");
        } else if (response.data.statusCode === 400) {
          alert("problem with POST API with code 400  " + error.message);
        } else {
          alert("problem with Post API  " + error.message);
        }
      })
      .catch((error) => {
        alert(error.response.data.error.message);
      });
  };

  return (
    <>
      <Typography
        align="left"
        variant="h5"
        className={classes.title}
        style={{ marginLeft: "20px" }}
      >
        Manage Users {">"} Add User
      </Typography>
      <Container
        style={{ margin: "2% auto", minWidth: "680px" }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Paper>
          <div className={classes.paper}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              style={{ textAlign: "center" }}
            >
              User Details
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    autoComplete="off"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    onChange={handleChange}
                    type="number"
                    required
                    fullWidth
                    id="mobileNumber"
                    label="Mobile Number"
                    name="mobileNumber"
                    autoComplete="mobile"
                  />
                </Grid>

                <Grid item sm={4}>
                  <FormControl component="fieldset">
                    <FormLabel required align="left" component="legend">
                      Gender
                    </FormLabel>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <RadioGroup
                          aria-label="gender"
                          onChange={handleChange}
                          name="gender"
                        >
                          <FormControlLabel
                            value="Male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="Female"
                            control={<Radio />}
                            label="Female"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item sm={8}>
                  <Dropdown required changeevent={handleChange} />
                </Grid>

                {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="Send Invitation"
              />
            </Grid> */}
              </Grid>

              <Button
                onClick={handleAdd}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  background: "#0E8050",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "3px",
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "14px",
                  textTransform: "none",
                  lineHeight: "17px",
                  padding: "10px 20px",
                }}
              >
                Submit
              </Button>
            </form>

            <Modal
              aria-labelledby="simple-modal-title"
              open={open}
              onClose={handleClose}
            >
              <div style={modalStyle} className={classes.modalPaper}>
                <h2>Check for Input Errors</h2>

                {!nameValid && (
                  <>
                    <h3>
                      Name entered must Alphabets and at least 3 characters
                    </h3>
                    <span>Entered Name = {payload.firstName}</span>
                  </>
                )}

                {!emailValid && (
                  <>
                    <h3>Email entered isnt correct </h3>
                    <span>Entered Email = {payload.email}</span>
                  </>
                )}

                {!phoneValid && (
                  <>
                    <h3>Phone number must be 10 digit</h3>
                    <span>Entered Phone = {payload.mobileNumber}</span>
                  </>
                )}

                {!passwordValid && (
                  <>
                    <h3>
                      Password must have at least 3 Alphanumeric characters
                      without space
                    </h3>
                    <span>Entered Password = {payload.password}</span>
                  </>
                )}

                {!genderValid && (
                  <>
                    <h3>Gender cant be blank</h3>
                    <span>Entered Gender = {payload.gender}</span>
                  </>
                )}

                {!optionValid && (
                  <>
                    <h3>Role needs to be selected</h3>
                    <span>Entered Role = {payload.role}</span>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </Paper>
      </Container>
    </>
  );
}
