import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { DateRangePicker } from "react-date-range";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { baseApi } from "../../Utils/config";
import SimpleTabs from "../Tasks/TabsPanel";
import {
  UserAttendanceReport,
  OverAllIdleTimeReport,
  TasksReportCard,
  SessionsReportCard,
  PaidUsersReport,
} from "../DataCards/Datacards";
import ModuleUsage from "../Admin/ModuleUsage";
import TotalEdits from "../Admin/TotalEdits";
import Modalt from "../Users/Modalt";
import UserProfile from "../Users/UserProfile";
import AuditLogs from "../Admin/AuditLogs";
import ModalSchedule from "../Users/AddSchedule";
import DateRangeComponent from "../Users/DateRangePicker";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FAFCFE",
    border: "1px solid #97A0C3",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  formControl: {
    height: "65px",
    padding: "0",
  },
  dayWiseList: {
    paddingBottom: "5px",
    cursor: "pointer",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#718096",
  },
  gridList: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",

    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginRight: "2%",
    width: "260px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
let AUTH_ERROR_STATUS_CODES = [401, 402, 403];

export default function UserLevelDetails(props) {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const [usersData, setUsersData] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [auditdata, setauditdata] = useState("");
  const [userAnalyticsDetails, setUserAnalyticsDetails] = useState("");
  const [updateList, setUpdateList] = useState(false);
  const [taskDetails, setTaskDetails] = useState("");
  const [dayWiseActive, setDayWiseActive] = useState("today");
  const [load, setload] = useState(true);
  const classes = useStyles();
  const history = useHistory();

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    if(event.target.value!=="" && event.target.value!=="None")
    { 
    console.log(usersData[event.target.value]);
    setUserDetails(usersData[event.target.value]);
    getUserDetails("today", usersData[event.target.value]._id);
    UserAudDetails("today", usersData[event.target.value]._id);
    TaskDetails("today", usersData[event.target.value]._id);
    setDayWiseActive("today");
    setSelectedUserLevelDate("");
  }
  };
  const [selectedUserLevelDate, setSelectedUserLevelDate] = React.useState("");

  const handleUserLevelDateChange = (date) => {
    setDayWiseActive("");
    setSelectedUserLevelDate(date);
    getUserDetails(date, userDetails._id);
    UserAudDetails(date, userDetails._id);
    TaskDetails(date, userDetails._id);
  };
  const handleDateWiseChange = (type) => {
    setDayWiseActive(type);
    setSelectedUserLevelDate("");
    getUserDetails(type, userDetails._id);
    UserAudDetails(type, userDetails._id);
    TaskDetails(type, userDetails._id);
  };

  function TaskDetails(type, employeeID) {
    if (type && typeof type === "string") {
      const res = axios({
        method: "get",
        url: apiBaseUrl + `task/list/users/${employeeID}?query=${type}`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      });
      res
        .then((response, error) => {
          setTaskDetails(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const res = axios({
        method: "get",
        url:
          apiBaseUrl +
          `task/list/users/${employeeID}?${
            type
              ? `from=${type.fromDate}&to=${
                  type.toDate ? type.toDate : type.fromDate
                }`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      });
      res
        .then((response, error) => {
          setTaskDetails(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function UserData() {
    const res = axios(
      {
        method: "get",
        url: apiBaseUrl + `users/userDetails?role=all`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setUsersData(res.data.data);
        let userData = res.data.data.filter(item => item._id===user.id);
        console.log("userData",userData);
        if(userData) {
        setUserDetails(userData[0]);
        UserAudDetails("", userData[0]._id);
        getUserDetails("", userData[0]._id);
        TaskDetails("", userData[0]._id);
        // setuserscol(res.data.data.columns);
        // setorgusers(res.data.data.data);
        setload(false);
      }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    UserData();
  }, []);

  function getUserDetails(type, id) {
    if (type && typeof type === "string") {
      const res = axios(
        {
          method: "get",
          url: apiBaseUrl + `users/userDetails/${id}?query=${type}`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.jT,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          setUserAnalyticsDetails(res.data.data);
          setload(false);
        })
        .catch((err) => console.log(err));
    } else {
      const res = axios(
        {
          method: "get",
          url:
            apiBaseUrl +
            `users/userDetails/${id}?${
              type
                ? `from=${type.fromDate}&to=${
                    type.toDate ? type.toDate : type.fromDate
                  }`
                : "query=today"
            }`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.jT,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          setUserAnalyticsDetails(res.data.data);
          setload(false);
        })
        .catch((err) => console.log(err));
    }
  }

  function UserAudDetails(type, employeeID) {
    if (type && typeof type === "string") {
      const res = axios(
        {
          method: "get",
          url: apiBaseUrl + `users/auditTrail/${employeeID}?query=${type}`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.jT,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          setauditdata(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      const res = axios(
        {
          method: "get",
          url:
            apiBaseUrl +
            `users/auditTrail/${employeeID}?${
              type
                ? `from=${type.fromDate}&to=${
                    type.toDate ? type.toDate : type.fromDate
                  }`
                : "query=today"
            }`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.jT,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          setauditdata(res.data);
        })
        .catch((err) => console.log(err));
    }
  }

  function handleUpdateList(update) {
    if (update) {
      setUpdateList(!updateList);
      handleDateWiseChange("today");
    }
  }
  function handleTaskUpdate(update) {
    if (update === true) {
      if (selectedUserLevelDate) {
        handleUserLevelDateChange(selectedUserLevelDate);
      } else {
        handleDateWiseChange(dayWiseActive);
      }
    }
  }

  return (
    <div className={classes.container}>
      <div
        style={{
          margin: "2em",
        }}
      >
        {usersData && userDetails && (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              marginBottom: "2em",
            }}
          >
            <div>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  className={classes.avatar}
                >
                  <PersonIcon />
                </Avatar>
                <div style={{ marginRight: "20px" }}>
                  <h4
                    style={{
                      margin: "0",
                      textTransform: "capitalize",
                      marginBottom: "4px",
                      fontWeight: "bold",
                      fontSize: "18px",
                      lineHeight: "36px",
                      color: "#242F57",
                    }}
                  >
                    {userDetails.firstName}
                  </h4>
                </div>
              </span>
              <div
                style={{
                  marginTop: "2em",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "340px",
                }}
              >
                <span
                  className={classes.dayWiseList}
                  onClick={() => handleDateWiseChange("today")}
                  style={
                    dayWiseActive == "today"
                      ? {
                          borderBottom: "1px solid #4C6FFF",
                          fontWeight: 600,
                        }
                      : { fontWeight: 400 }
                  }
                >
                  Today
                </span>
                <span
                  onClick={() => handleDateWiseChange("tomorrow")}
                  style={
                    dayWiseActive == "tomorrow"
                      ? {
                          borderBottom: "1px solid #4C6FFF",
                          fontWeight: 600,
                        }
                      : { fontWeight: 400 }
                  }
                  className={classes.dayWiseList}
                >
                  Tomorrow
                </span>
                <span
                  onClick={() => handleDateWiseChange("this_week")}
                  style={
                    dayWiseActive == "this_week"
                      ? {
                          borderBottom: "1px solid #4C6FFF",
                          fontWeight: 600,
                        }
                      : { fontWeight: 400 }
                  }
                  className={classes.dayWiseList}
                >
                  This week
                </span>
                <span
                  onClick={() => handleDateWiseChange("this_month")}
                  style={
                    dayWiseActive == "this_month"
                      ? {
                          borderBottom: "1px solid #4C6FFF",
                          fontWeight: 600,
                        }
                      : { fontWeight: 400 }
                  }
                  className={classes.dayWiseList}
                >
                  This month
                </span>
              </div>
            </div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ margin: "0 1em" }}
              >
                <InputLabel htmlFor="outlined-age-native-simple" >Users</InputLabel>
        <Select
          native
          // value={userDetails.firstName}
          onChange={handleSelectChange}
          label="Age"
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
          style={{ height: "40px", marginTop: "13px"  }}
        >
          <option style={{backgroundColor:"#0057FF",color:"#ffffff"}} aria-label="None" value="None">
            {userDetails.firstName}
            </option>
          {usersData.map((user, i) => (
                      <option key={i + "user"} value={i}>
                        {user.firstName}
                      </option>
                    ))}
         
        </Select>
                {/* <InputLabel
                  htmlFor="outlined-age-native-simple"
                  style={{ marginTop: "13px" }}
                >
                  User
                </InputLabel>
                <Select
                  native
                  value={userDetails.firstName}
                  label="User"
                  onChange={handleSelectChange}
                  inputProps={{
                    name: "User",
                    id: "outlined-age-native-simple",
                  }}
                  style={{ height: "40px", marginTop: "13px" }}
                >
                  {usersData.map((user, i) => (
                      <option key={i + "user"} value={i}>
                        {user.firstName}
                      </option>
                    ))}
                </Select> */}
              </FormControl>
              <div style={{ margin: "0 1em" }}>
                <DateRangeComponent
                  handleDateChange={handleUserLevelDateChange}
                />
              </div>
              <div style={{ margin: "0 1em" }}>
                <Modalt
                  handleUpdateList={handleUpdateList}
                  userDetails={userDetails}
                />
              </div>
              <div style={{ margin: "0 1em" }}>
                <ModalSchedule
                  handleUpdateList={handleUpdateList}
                  userDetails={userDetails}
                />
              </div>
            </span>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            margin: "0px 10px 40px",
            // justifyContent: "space-between",
          }}
        >
          {userAnalyticsDetails && userAnalyticsDetails.loggedIn && (
            <UserAttendanceReport
              userDetails={userDetails}
              dayWiseActive={dayWiseActive}
              selectedDate={selectedUserLevelDate}
              analyticsData={userAnalyticsDetails.loggedIn}
            />
          )}
          {userAnalyticsDetails && userAnalyticsDetails.ideltimeData && (
            <OverAllIdleTimeReport
              userDetails={userDetails}
              dayWiseActive={dayWiseActive}
              selectedDate={selectedUserLevelDate}
              analyticsData={userAnalyticsDetails.ideltimeData}
            />
          )}
          {userAnalyticsDetails && userAnalyticsDetails.task && (
            <TasksReportCard
              dayWiseActive={dayWiseActive}
              selectedDate={selectedUserLevelDate}
              userDetails={userDetails}
              analyticsData={userAnalyticsDetails.task}
            />
          )}
          {userAnalyticsDetails && userAnalyticsDetails.task && (
            <SessionsReportCard
              dayWiseActive={dayWiseActive}
              selectedDate={selectedUserLevelDate}
              userDetails={userDetails}
              analyticsData={userAnalyticsDetails.task}
            />
          )}
        </div>

        {userDetails && taskDetails ? (
          <div className={classes.gridList}>
            <SimpleTabs
              admin={true}
              handleTaskUpdate={handleTaskUpdate}
              dayWiseActive={dayWiseActive}
              updateList={updateList}
              selectedDate={selectedUserLevelDate}
              userDetails={userDetails}
              taskDetails={taskDetails}
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.gridList}>
          {auditdata && <AuditLogs data={auditdata} />}
        </div>
      </div>
    </div>
  );
}
