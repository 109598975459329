import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { logout, isLogin } from "../../Utils/logincheck";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import logo from "../../assets/logo2.png";

class Appbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogin: isLogin(),
      redirect: null,
    };
  }

  handleLogout = () => {
    logout();
    this.setState({
      isLogin: false,
      redirect: "/login",
    });
  };

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          style={{
            background: "white",
            color: "black",
            borderBottom: "0.9px solid #E0E0E0",
          }}
        >
          <Toolbar>
            {/* <Typography
              variant="h6"
              style={{
                fontSize: "22px",
                lineHeight: "27px",
                letterSpacing: "0.5px",
                color: "#0080FF",
              }}
            >
              Parentof ARC
            </Typography> */}
            <img src={logo} style={{ height: "68px", margin: "5px 0px" }} />
            {this.state.isLogin ? (
              <button
                style={{ marginLeft: "74%" }}
                onClick={() => this.handleLogout()}
              >
                Click here to log out
              </button>
            ) : (
              <span></span>
            )}

            {this.state.redirect ? (
              <Redirect to={this.state.redirect} />
            ) : (
              <span></span>
            )}
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default Appbar;
