import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import { baseApi } from "../../Utils/config";
import {
  UserAttendanceReport,
  OverAllIdleTimeReport,
  TasksReportCard,
  SessionsReportCard,
  PaidUsersReport,
  NoofAsCards,
} from "../DataCards/Datacards";
import TotalEdits from "../Admin/TotalEdits";
import DateRangeComponent from "../Users/DateRangePicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    height: "65px",
    padding: "0",
  },
  dayWiseList: {
    paddingBottom: "5px",
    cursor: "pointer",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#718096",
  },
  gridList: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",

    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginRight: "2%",
    width: "260px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function AdminLevelDetails() {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const [analyticsData, setAnalyticsData] = useState("");
  const classes = useStyles();
  const [selectedAnalyticsDate, setSelectedAnalyticsDate] = React.useState("");

  const handleAnalyticsDateChange = (date) => {
    setSelectedAnalyticsDate(date);
    AllUserAnalyticsDetails(date);
  };

  useEffect(() => {
    AllUserAnalyticsDetails();
  }, []);

  function AllUserAnalyticsDetails(selectedDate) {
    const res = axios(
      {
        method: "get",
        url:
          apiBaseUrl +
          `users/analytics?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${
                  selectedDate.toDate
                    ? selectedDate.toDate
                    : selectedDate.fromDate
                }`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setAnalyticsData(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={classes.container}>
      <div
        style={{
          margin: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "35px",
          }}
        >
          <div>
            <Typography
              variant="h4"
              align="left"
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "#242F57",
                marginBottom: "10px",
                textTransform: "capitalize",
              }}
            >
              Hi {user && user.name}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#636E95",
                marginBottom: "3%",
              }}
            >
              Here's how your dashboard looks for{" "}
              <span
                style={{
                  color: "#0080FF",
                }}
              >
                {selectedAnalyticsDate
                  ? moment(selectedAnalyticsDate.fromDate).format(
                      "DD MMM YYYY"
                    ) +
                    (selectedAnalyticsDate.toDate
                      ? " - " +
                        moment(selectedAnalyticsDate.toDate).format(
                          "DD MMM YYYY"
                        )
                      : "")
                  : moment(new Date()).format("DD MMM YYYY")}
              </span>
            </Typography>
          </div>
          <DateRangeComponent handleDateChange={handleAnalyticsDateChange} />
        </div>
        <p
          style={{
            color: "#7A7A9D",
            margin: "5px",
            marginLeft: "1em",
            fontWeight: "bold",
          }}
        >
          Staff Management
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            // justifyContent: "space-between",
          }}
        >
          {analyticsData && analyticsData.loggedIn && (
            <UserAttendanceReport
              admin={true}
              selectedDate={selectedAnalyticsDate}
              analyticsData={analyticsData.loggedIn}
            />
          )}
          {analyticsData && analyticsData.ideltimeData && (
            <OverAllIdleTimeReport
              admin={true}
              selectedDate={selectedAnalyticsDate}
              analyticsData={analyticsData.ideltimeData}
            />
          )}
          {analyticsData && analyticsData.task && (
            <TasksReportCard
              admin={true}
              selectedDate={selectedAnalyticsDate}
              analyticsData={analyticsData.task}
            />
          )}
          {analyticsData && analyticsData.task && (
            <SessionsReportCard
              admin={true}
              selectedDate={selectedAnalyticsDate}
              analyticsData={analyticsData.task}
            />
          )}
        </div>
        <p
          style={{
            color: "#7A7A9D",
            margin: "5px",
            marginLeft: "1em",
            fontWeight: "bold",
          }}
        >
          User Management
        </p>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            // justifyContent: "space-between",
          }}
        >
          {analyticsData && analyticsData.paidUser && (
            <PaidUsersReport
              admin={true}
              selectedDate={selectedAnalyticsDate}
              analyticsData={analyticsData.paidUser}
            />
          )}
          {analyticsData && analyticsData.noOfAssessments && (
            <NoofAsCards
              admin={true}
              selectedDate={selectedAnalyticsDate}
              analyticsData={analyticsData.noOfAssessments}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            marginBottom: "3em",
            // justifyContent: "space-between",
          }}
        >
          <TotalEdits selectedDate={selectedAnalyticsDate} />
          {/* <ModuleUsage moduleUsage={analyticsData.moduleUsage} /> */}
        </div>
      </div>
    </div>
  );
}
