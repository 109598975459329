import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { DateRangePicker } from "react-date-range";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { baseApi } from "../../Utils/config";
import UserTaskLists from "../Tasks/UserTaskLists";
import {
  UserAttendanceReport,
  OverAllIdleTimeReport,
  TasksReportCard,
  SessionsReportCard,
} from "../DataCards/Datacards";
import Modalt from "../Users/Modalt";
import UserProfile from "../Users/UserProfile";
import AuditLogs from "../Admin/AuditLogs";
import ModalSchedule from "../Users/AddSchedule";
import DateRangeComponent from "../Users/DateRangePicker";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FAFCFE",
    border: "1px solid #97A0C3",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  dayWiseList: {
    paddingBottom: "5px",
    cursor: "pointer",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#718096",
  },
  gridList: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",

    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginRight: "2%",
    width: "260px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
let AUTH_ERROR_STATUS_CODES = [401, 402, 403];
export default function Dashboard(props) {
  var apiBaseUrl = baseApi;
  var employeeID = "props.location.state._id.toString()";
  const [dashboardData, setDashboardData] = useState();
  const [userDetails, setUserDetails] = useState("");
  const [auditdata, setauditdata] = useState("");
  const [analyticsData, setAnalyticsData] = useState("");
  const [updateList, setUpdateList] = useState(false);
  const [dayWiseActive, setDayWiseActive] = useState("today");
  const [taskDetails, setTaskDetails] = useState("");
  const [activeUsersAnalytics, setActiveUsersAnalytics] = useState("");
  const [load, setload] = useState(true);
  const [filteredData, setFilteredData] = useState();
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState();
  var user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const [selectedDate, setSelectedDate] = React.useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDayWiseActive("");
    UserData(date);
    TaskDetails(date);
    // UserAudDetails(user.id, date);
  };
  function UserData(selectedDate) {
    if (selectedDate && typeof selectedDate === "string") {
      const res = axios(
        {
          method: "get",
          url:
            apiBaseUrl + `users/userDetails/${user.id}?query=${selectedDate}`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.jT,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          setAnalyticsData(res.data.data);
          setUserDetails(res.data.data.userDetails.userDetails);
          // setuserscol(res.data.data.columns);
          // setorgusers(res.data.data.data);
          setload(false);
        })
        .catch((err) => setErrors(err));
    } else {
      const res = axios(
        {
          method: "get",
          url:
            apiBaseUrl +
            `users/userDetails/${user.id}?${
              selectedDate
                ? `from=${selectedDate.fromDate}&to=${
                    selectedDate.toDate
                      ? selectedDate.toDate
                      : selectedDate.fromDate
                  }`
                : "query=today"
            }`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": user.jT,
          },
        }
        // { withCredentials: true }
      );
      res
        .then((res) => {
          setAnalyticsData(res.data.data);
          setUserDetails(res.data.data.userDetails.userDetails);
          // UserAudDetails(res.data.data.userDetails.userDetails._id);
          // setuserscol(res.data.data.columns);
          // setorgusers(res.data.data.data);
          setload(false);
        })
        .catch((err) => setErrors(err));
    }
  }

  useEffect(() => {
    UserData();
    TaskDetails();

    // UserAnalyticsDetails();
  }, []);

  const handleDateWiseChange = (type) => {
    setSelectedDate("");
    setDayWiseActive(type);
    UserData(type);
    TaskDetails(type);
    // setSelectedUserLevelDate("");
    // getUserDetails(type, userDetails._id);
    // UserAudDetails(type, userDetails._id);
  };
  function TaskDetails(selectedDate) {
    if (selectedDate && typeof selectedDate === "string") {
      const res = axios({
        method: "get",
        url: apiBaseUrl + `task/list/users/${user.id}?query=${selectedDate}`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      });
      res
        .then((response, error) => {
          setTaskDetails(response.data.data);
          // setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const res = axios({
        method: "get",
        url:
          apiBaseUrl +
          `task/list/users/${user.id}?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${
                  selectedDate.toDate
                    ? selectedDate.toDate
                    : selectedDate.fromDate
                }`
              : `query=${dayWiseActive}`
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      });
      res
        .then((response, error) => {
          setTaskDetails(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleTaskUpdate(update) {
    if (update === true) {
      if (selectedDate) {
        handleDateChange(selectedDate);
      } else {
        handleDateWiseChange(dayWiseActive);
      }
    }
  }

  function handleUpdateList(update) {
    if (update === true) {
      setUpdateList(!updateList);
      setDayWiseActive("today");
      UserData("today");
      TaskDetails("today");
    }
  }

  return (
    <div className={classes.container}>
      <div
        style={{
          margin: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // marginBottom: "20px",
          }}
        >
          <div>
            <Typography
              variant="h4"
              align="left"
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "#242F57",
                marginBottom: "10px",
                textTransform: "capitalize",
              }}
            >
              Hi {user && user.name}
            </Typography>
            <Typography
              variant="h6"
              align="left"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#636E95",
                marginBottom: "3%",
              }}
            >
              Here's how your dashboard looks for{" "}
              <span
                style={{
                  color: "#0080FF",
                }}
              >
                {selectedDate
                  ? moment(selectedDate.fromDate).format("DD MMM YYYY") +
                    (selectedDate.toDate
                      ? " - " +
                        moment(selectedDate.toDate).format("DD MMM YYYY")
                      : "")
                  : moment(new Date()).format("DD MMM YYYY")}
              </span>
            </Typography>
          </div>
          <DateRangeComponent handleDateChange={handleDateChange} />
        </div>
        {userDetails && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "2em",
            }}
          >
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                justifyContent: "space-between",
                width: "340px",
              }}
            >
              <span
                className={classes.dayWiseList}
                onClick={() => handleDateWiseChange("today")}
                style={
                  dayWiseActive == "today"
                    ? {
                        borderBottom: "1px solid #4C6FFF",
                        fontWeight: 600,
                      }
                    : { fontWeight: 400 }
                }
              >
                Today
              </span>
              <span
                onClick={() => handleDateWiseChange("tomorrow")}
                style={
                  dayWiseActive == "tomorrow"
                    ? {
                        borderBottom: "1px solid #4C6FFF",
                        fontWeight: 600,
                      }
                    : { fontWeight: 400 }
                }
                className={classes.dayWiseList}
              >
                Tomorrow
              </span>
              <span
                onClick={() => handleDateWiseChange("this_week")}
                style={
                  dayWiseActive == "this_week"
                    ? {
                        borderBottom: "1px solid #4C6FFF",
                        fontWeight: 600,
                      }
                    : { fontWeight: 400 }
                }
                className={classes.dayWiseList}
              >
                This week
              </span>
              <span
                onClick={() => handleDateWiseChange("this_month")}
                style={
                  dayWiseActive == "this_month"
                    ? {
                        borderBottom: "1px solid #4C6FFF",
                        fontWeight: 600,
                      }
                    : { fontWeight: 400 }
                }
                className={classes.dayWiseList}
              >
                This month
              </span>
            </div>

            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "1em" }}>
                <Modalt
                  handleUpdateList={handleUpdateList}
                  userDetails={userDetails}
                />
              </div>
              <div style={{ margin: "1em" }}>
                <ModalSchedule
                  handleUpdateList={handleUpdateList}
                  userDetails={userDetails}
                />
              </div>
            </span>
          </div>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            // justifyContent: "space-between",
          }}
        >
          {analyticsData && analyticsData.loggedIn && (
            <UserAttendanceReport
              userDetails={userDetails}
              dayWiseActive={dayWiseActive}
              selectedDate={selectedDate}
              analyticsData={analyticsData.loggedIn}
            />
          )}
          {analyticsData && analyticsData.ideltimeData && (
            <OverAllIdleTimeReport
              userDetails={userDetails}
              dayWiseActive={dayWiseActive}
              selectedDate={selectedDate}
              analyticsData={analyticsData.ideltimeData}
            />
          )}
          {analyticsData && analyticsData.task && (
            <TasksReportCard
              userDetails={userDetails}
              dayWiseActive={dayWiseActive}
              selectedDate={selectedDate}
              analyticsData={analyticsData.task}
            />
          )}
          {analyticsData && analyticsData.task && (
            <SessionsReportCard
              userDetails={userDetails}
              dayWiseActive={dayWiseActive}
              selectedDate={selectedDate}
              analyticsData={analyticsData.task}
            />
          )}
        </div>

        {userDetails && taskDetails ? (
          <div className={classes.gridList}>
            <UserTaskLists
              admin={false}
              handleTaskUpdate={handleTaskUpdate}
              dayWiseActive={dayWiseActive}
              updateList={updateList}
              selectedDate={selectedDate}
              userDetails={userDetails}
              taskDetails={taskDetails}
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
        {/* <div className={classes.gridList}>
          {auditdata && <AuditLogs data={auditdata} />}
        </div> */}
      </div>
    </div>
  );
}
