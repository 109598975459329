import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { baseApi } from "../../Utils/config";
import { makeStyles } from "@material-ui/core/styles";
import progressIcon from "../../assets/progressIcon.png";
import DateRangeComponent from "../Users/DateRangePicker";

const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    marginTop: "1%",
    marginLeft: "2%",
    lineHeight: "48px",
    fontSize: "24px",
  },
  button: {
    marginLeft: "39% !important",
    marginTop: "-3%",
  },
  container: {
    maxWidth: "100%",
    minWidth: "800px",
    minHeight: "450px",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    padding: "20px 30px",
    margin: "20px",
    marginLeft: "0",
    marginTop: "2em",
  },
  progressColor: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFEEF1",
    borderRadius: "8px",
    height: "46px",
    width: "46px",
  },
}));

export default function TotalEdits({ selectedDate }) {
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [activeUsersAnalytics, setActiveUsersAnalytics] = useState("");
  function ActiveUsersAnalytics(selectedDate) {
    // console.log("TotalEditsselectedDate", selectedDate);
    const res = axios(
      {
        method: "get",
        url:
          apiBaseUrl +
          `users/analytics/activeUser?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${
                  selectedDate.toDate
                    ? selectedDate.toDate
                    : selectedDate.fromDate
                }`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        setActiveUsersAnalytics(res.data.data);
      })
      .catch((err) => setErrors(err));
  }
  useEffect(() => {
    ActiveUsersAnalytics(selectedDate);
  }, [selectedDate]);

  const getDataPoints = (functionality) => {
    return activeUsersAnalytics.map((item) =>
      item.data
        .map((item) => {
          if (item.functionality === functionality) return item.count;
        })
        .filter(function (x) {
          return x !== undefined;
        })
    );
  };
  const getAllDataPoints = () => {
    let obj = {
      documentation: 0,
      "content management": 0,
      "user onboarding": 0,
      "user interaction": 0,
    };
    activeUsersAnalytics.map((item) =>
      item.data.map((item) => {
        obj[item.functionality] = obj[item.functionality] + item.count;
      })
    );
    return Object.values(obj);
  };

  const data = {
    labels: [
      "Documentation",
      "Content Management",
      "User Onboarding",
      "User Interaction",
    ],
    datasets: [
      {
        // label: '# of Votes',
        data: activeUsersAnalytics && getAllDataPoints(),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
    // labels: [
    //   "Documentation",
    //   "Content Management",
    //   "User Onboarding",
    //   "User Interaction",
    // ],
    // datasets: [
    //   {
    //     // label: "Documentation",
    //     backgroundColor: "#4C6FFF",
    //     borderWidth: 2,
    //     width: 0.5,
    //     borderRadius: Number.MAX_VALUE,
    //     borderSkipped: false,
    //     data: activeUsersAnalytics && getAllDataPoints(),
    //     // data: activeUsersAnalytics && [
    //     //   ...getDataPoints("documentation").flat(),
    //     //   ...getDataPoints("content management").flat(),
    //     //   ...getDataPoints("user onboarding").flat(),
    //     //   ...getDataPoints("user interaction").flat(),
    //     // ],
    //   },
    // {
    //   label: "Content Management",
    //   backgroundColor: "#4C6FFF",
    //   borderWidth: 2,
    //   width: 5,
    //   borderRadius: Number.MAX_VALUE,
    //   borderSkipped: false,
    //   data:
    //     activeUsersAnalytics && getDataPoints("content management").flat(),
    // },
    // {
    //   label: "User Onboarding",
    //   backgroundColor: "#F7936F",
    //   borderWidth: 2,
    //   width: 5,
    //   borderRadius: Number.MAX_VALUE,
    //   borderSkipped: false,
    //   data: activeUsersAnalytics && getDataPoints("user onboarding").flat(),
    // },
    // {
    //   label: "User Interaction",
    //   backgroundColor: "#E4ECF7",
    //   borderWidth: 2,
    //   width: 5,
    //   borderRadius: Number.MAX_VALUE,
    //   borderSkipped: false,
    //   data: activeUsersAnalytics && getDataPoints("user interaction").flat(),
    // },
    // ],
  };
  const options = {
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  const handleDateChange = (date) => {
    ActiveUsersAnalytics(date);
  };

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4
          style={{
            margin: "0",
            marginLeft: "1em",
            marginBottom: "1em",
            fontWeight: 600,
            fontSize: "16px",
          }}
        >
          Module Usage
        </h4>
        {/* <DateRangeComponent handleDateChange={handleDateChange} /> */}
      </div>

      <div>
        <Bar
          data={data}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            plugins: {
              legend: {
                display: false,
                // borderRadius: Number.MAX_VALUE,
                // position: "top",
              },
            },
            // responsive: true,
            // plugins: {
            //   legend: {
            //     display: false,
            //     // borderRadius: Number.MAX_VALUE,
            //     // position: "top",
            //   },
            // },
          }}
        />
      </div>
    </div>
  );
}
