import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import editicon from "../../assets/editicon.png";
import { ButtonBase } from "@material-ui/core";
import { baseApi } from "../../Utils/config";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";

var payload = [];
export default function Revoke({ datax }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const history = useHistory();

  var user = JSON.parse(localStorage.getItem("user"));
  var apiBaseUrl = baseApi;
  payload = {
    _id: datax._id,
    userStatus: "inactive",
  };

  const handleDea = () => {
    revoke(payload);
  };
  const revoke = (props) => {
    var apiBaseUrl = baseApi;

    axios({
      method: "put",
      url: apiBaseUrl + "users/update/status",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    })
      .then((response) => {
        alert("User Revoked");
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <ButtonBase
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Open Menu
      </Button> */}
      <img
        onClick={datax.userStatus == "active" ? handleClick : {}}
        src={editicon}
        style={{
          width: "44px",
          height: "44px",
          marginTop: "23px",
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleDea}
          style={{ padding: "10px 10px !important" }}
        >
          Deactivate User
        </MenuItem>
      </Menu>
    </>
  );
}
