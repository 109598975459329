import React, { useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import "../App.css";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import PeopleIcon from "@material-ui/icons/People";
// import BarChartIcon from "@material-ui/icons/BarChart";
// import AssignmentIcon from "@material-ui/icons/Assignment";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
// import DraftsIcon from "@material-ui/icons/Drafts";
// import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { Divider } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import user from "../assets/user.png";
import group from "../assets/Group.png";
import tags from "../assets/tags.png";
import maps from "../assets/maps.png";
import dash from "../assets/dash.png";
import documentation from "../assets/documentation.png";
import userI from "../assets/userI.png";
import ContentManagement from "../assets/icons/Content management.png";
import Dashboard from "../assets/icons/Dashboard.png";
import Documentation from "../assets/icons/Documentation.png";
import StaffManagement from "../assets/icons/Staff management.png";
import UserInteraction from "../assets/icons/User Interaction.png";
import UserOnboarding from "../assets/icons/User onboarding.png";
import UserAnalytics from "../assets/icons/User analytics.png";
import UserDcumentation from "../assets/icons/User documentation.png";
import ProductDocumentation from "../assets/icons/ProductDocumentation.png";
import AnalyticsImg from "../assets/icons/analytics.png";
import raw from "../assets/icons/raw.png";
import Filteration from "../assets/icons/filteration.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginTop: "23%",
    display: "inherit",
  },
  nested: {
    // paddingLeft: theme.spacing(4),
  },
  dropdownList: {
    marginLeft: "85px",
    marginTop: "-10px",
    "& .menu-item": {
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: "-25px", // Adjust the position as needed
        width: "2px", // Adjust the thickness of the line
        height: "100%",
        backgroundColor: "#9FA3AF", // Change color as needed
      },
    },
  },
}));

export const MainListItems = (props) => {
  const classes = useStyles();
  const [openUserManagement, setOpenUserManagement] = React.useState(true);
  const [openContent, setOpenContent] = React.useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const history = useHistory();
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  useEffect(() => {
    if (isDropdownVisible) {
      history.push("/data-analytics/data-overview");
    }
  }, [isDropdownVisible]);
  let menus = props.menus;
  // let menus = { content: [1, 2, 3], "User Management": [] };
  // let isActive = this.context.router.isActive(this.props.to, true);

  // var className = isActive ? "active" : "";

  const handleUserManagement = (e) => {
    setOpenUserManagement(!openUserManagement);
  };
  const handleContent = () => {
    setOpenContent(!openContent);
  };
  let pathname = history.location.pathname;

  const handleDrawerClose1 = () => {
    props.handleDrawerClose();
  };
  if (menus)
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem timeout="auto">
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              color:
                pathname === "/dashboard" || pathname === "/lms/dashboard"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/dashboard" || pathname === "/lms/dashboard"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      width: "25px",
                      height: "25px",
                      marginBottom: "-7px",
                      opacity:
                        pathname === "/dashboard" ||
                        pathname === "/lms/dashboard"
                          ? 1
                          : 0.5,
                    }}
                    src={Dashboard}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </List>
          </Link>
          <Link
            activeclassname="active"
            to="/contentMgmt"
            style={{
              textDecoration: "none",
              color:
                pathname === "/contentMgmt" || pathname === "/lms/contentMgmt"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/contentMgmt" || pathname === "/lms/contentMgmt"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/contentMgmt" ||
                        pathname === "/lms/contentMgmt"
                          ? 1
                          : 0.5,
                    }}
                    src={ContentManagement}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Content Management" />
              </ListItem>
            </List>
          </Link>
          <Link
            to="/user-onboarding"
            style={{
              textDecoration: "none",
              color:
                pathname === "/user-onboarding" ||
                pathname === "lms/user-onboarding"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/user-onboarding" ||
                pathname === "lms/user-onboarding"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/user-onboarding" ||
                        pathname === "lms/user-onboarding"
                          ? 1
                          : 0.5,
                    }}
                    src={UserOnboarding}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="User Onboarding" />
              </ListItem>
            </List>
          </Link>
          <Link
            to="/user-interaction"
            style={{
              textDecoration: "none",
              color:
                pathname === "/user-interaction" ||
                pathname === "lms/user-interaction"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/user-interaction" ||
                pathname === "lms/user-interaction"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/user-interaction" ||
                        pathname === "lms/user-interaction"
                          ? 1
                          : 0.5,
                    }}
                    src={UserInteraction}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="User Interaction" />
              </ListItem>
            </List>
          </Link>
          <Link
            to="/user-analytics"
            style={{
              textDecoration: "none",
              color:
                pathname === "/user-analytics" ||
                pathname === "lms/user-analytics"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/user-analytics" ||
                pathname === "lms/user-analytics"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/user-analytics" ||
                        pathname === "lms/user-analytics"
                          ? 1
                          : 0.5,
                    }}
                    src={UserAnalytics}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="User Analytics" />
              </ListItem>
            </List>
          </Link>
          <Link
            to="/user-documentation"
            style={{
              textDecoration: "none",
              color:
                pathname === "/user-documentation" ||
                pathname === "lms/user-documentation"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/user-documentation" ||
                pathname === "lms/user-documentation"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/user-documentation" ||
                        pathname === "lms/user-documentation"
                          ? 1
                          : 0.5,
                    }}
                    src={UserDcumentation}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="User Documentation" />
              </ListItem>
            </List>
          </Link>
          <Link
            to="/product-documentation"
            style={{
              textDecoration: "none",
              color:
                pathname === "/product-documentation" ||
                pathname === "lms/product-documentation"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/product-documentation" ||
                pathname === "lms/product-documentation"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/product-documentation" ||
                        pathname === "lms/product-documentation"
                          ? 1
                          : 0.5,
                    }}
                    src={ProductDocumentation}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Product Documentation" />
              </ListItem>
            </List>
          </Link>
          {/* <Link
            to="/data-analytics"
            style={{
              textDecoration: "none",
              color:
                pathname === "/data-analytics" ||
                pathname === "lms/data-analytics"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/data-analytics" ||
                pathname === "lms/data-analytics"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/data-analytics" ||
                        pathname === "lms/data-analytics"
                          ? 1
                          : 0.5,
                    }}
                    src={ProductDocumentation}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Data Analytics" />
              </ListItem>
            </List>
          </Link> */}
          <div
            onClick={toggleDropdown}
            style={{
              paddingLeft: "45px",
              textDecoration: "none",
              color:
                pathname.startsWith("/data-analytics") ||
                pathname.startsWith("/lms/data-analytics")
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname.startsWith("/data-analytics") ||
                pathname.startsWith("/lms/data-analytics")
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname.startsWith("/data-analytics") ||
                        pathname.startsWith("/lms/data-analytics")
                          ? 1
                          : 0.5,
                    }}
                    src={AnalyticsImg}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Data Analytics" />
                <IconButton>
                  <ExpandMoreIcon
                    style={{
                      transform: isDropdownVisible
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </IconButton>
              </ListItem>
            </List>
          </div>

          {isDropdownVisible && (
            <List
              component="nav"
              aria-label="secondary mailbox folders"
              className={classes.dropdownList}
            >
              {/* Add your dropdown menu items here */}
              <ListItem
                button
                component={Link}
                to="/data-analytics/data-overview"
                className="menu-item"
                style={{
                  color:
                    pathname === "/data-analytics/data-overview" ||
                    pathname === "/lms/data-analytics/data-overview"
                      ? "#242F57"
                      : "#97A0C3",
                  fontWeight:
                    pathname === "/data-analytics/data-overview" ||
                    pathname === "/lms/data-analytics/data-overview"
                      ? "700"
                      : "500",
                }}
              >
                <ListItemText primary="Data Overview" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/data-analytics/curation"
                className="menu-item"
                style={{
                  color:
                    pathname === "/data-analytics/curation" ||
                    pathname === "/lms/data-analytics/curation"
                      ? "#242F57"
                      : "#97A0C3",
                  fontWeight:
                    pathname === "/data-analytics/curation" ||
                    pathname === "/lms/data-analytics/curation"
                      ? "700"
                      : "500",
                }}
              >
                <ListItemText primary="Curation Engine" />
              </ListItem>
              <ListItem
                button
                className="menu-item"
                style={{ color: "#97A0C3" }}
              >
                <ListItemText
                  primary="Assessment & Reporting Engine"
                  style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                />
              </ListItem>
              {/* Add more menu items as needed */}
            </List>
          )}
          <Link
            to="/raw-data"
            style={{
              textDecoration: "none",
              color:
                pathname === "/raw-data" || pathname === "lms/raw-data"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/raw-data" || pathname === "lms/raw-data"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/raw-data" || pathname === "lms/raw-data"
                          ? 1
                          : 0.5,
                    }}
                    src={raw}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Raw Data" />
              </ListItem>
            </List>
          </Link>
          <Link
            to="/data-filteration"
            style={{
              textDecoration: "none",
              color:
                pathname === "/data-filteration" ||
                pathname === "lms/data-filteration"
                  ? "#242F57"
                  : "#97A0C3",
              fontWeight:
                pathname === "/data-filteration" ||
                pathname === "lms/data-filteration"
                  ? "700"
                  : "500",
            }}
          >
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={handleDrawerClose1}
                className={classes.nested}
              >
                <ListItemIcon>
                  <img
                    style={{
                      marginBottom: "-10px",
                      opacity:
                        pathname === "/data-filteration" ||
                        pathname === "lms/data-filteration"
                          ? 1
                          : 0.5,
                    }}
                    src={Filteration}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText primary="Data Filteration" />
              </ListItem>
            </List>
          </Link>
          {user && user.role === "admin" && (
            <Link
              to="/manageusers"
              style={{
                textDecoration: "none",
                color:
                  pathname === "/manageusers" || pathname === "lms/manageusers"
                    ? "#242F57"
                    : "#97A0C3",
                fontWeight:
                  pathname === "/manageusers" || pathname === "lms/manageusers"
                    ? "700"
                    : "500",
              }}
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={handleDrawerClose1}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <img
                      style={{
                        marginBottom: "-10px",
                        opacity:
                          pathname === "/manageusers" ||
                          pathname === "lms/manageusers"
                            ? 1
                            : 0.5,
                      }}
                      src={StaffManagement}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText primary="Staff Management" />
                </ListItem>
              </List>
            </Link>
          )}
        </ListItem>
      </List>
    );
  return null;
};
