import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function TableS(props) {
  const classes = useStyles();
  var ID = props.location.state;
  console.log("data-idle", ID);
  return (
    <div style={{ margin: "30px" }}>
      <h1
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "26px",
          lineHeight: "36px",
          letterSpacing: "0.5px",
          color: "#242F57",
        }}
      >
        Staff Idle Time
      </h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">User Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Idle Time (min)</TableCell>
              <TableCell align="center">From Time</TableCell>
              <TableCell align="center">To Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ID &&
              ID.filter((item) => Math.floor(item.idleTime / 60) > 30).map(
                (item) => {
                  return (
                    <TableRow>
                      <TableCell align="center">
                        {item._userId ? item._userId.firstName : ""}
                      </TableCell>
                      <TableCell align="center">
                        {item._userId ? item._userId.email : ""}
                      </TableCell>
                      <TableCell align="center">
                        {Math.floor(item.idleTime / 60)}
                      </TableCell>

                      <TableCell align="center">
                        {moment(item.fromTime).format("DD MMM YYYY h:mm a")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item.toTime).format("DD MMM YYYY h:mm a")}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            {/* <TableRow>
              <TableCell component="th" scope="row">
                {ID[0]._userId.firstName}
              </TableCell>
              <TableCell align="center">{ID[0]._userId.email}</TableCell>
              <TableCell align="center">{ID[0].idleTime / 60}</TableCell>
              <TableCell align="center">{ID[0].fromTime}</TableCell>
              <TableCell align="center">{ID[0].toTime}</TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
