import React from "react";
import { useHistory, Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
// import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
// import Badge from "@material-ui/core/Badge";
// import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import Popover from "@material-ui/core/Popover";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import { MainListItems } from "./NavList";
import TagsTable from "../components/Tags/Table";
import TransferList from "../components/Views/CreateView";
import ManageUsers from "../components/Users/ManageUsers";
import CreateTag from "../components/Tags/CreateTag";
import Routes from "../routers/AppRouter";
import AddUser from "../components/Users/AddUser";
import PrivateRoute from "../routers/PrivateRouter";
import logo from "../assets/logo2.png";
import profilePic from "../assets/profilePic.png";
import dropDown from "../assets/dropDown.png";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    background: "#F7FAFF",
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "#FFFFFF",
    color: "#1B0F0F",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    cursor: "pointer",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "22px",
    lineHeight: "27px",
    letterSpacing: "0.5px",
    color: "#0080FF",
  },

  drawerPaper: {
    position: "fixed",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing(7),
    width: 0,
    // [theme.breakpoints.up("sm")]: {
    //   width: theme.spacing(9),
    // },
  },
  fixedHeight: {
    height: 240,
  },
  rowWise: {
    display: "flex",
    alignItems: "center",
  },
  popoverContent: {
    top: "60px",
    right: "20px",
  },
}));

export default function SideNavBar() {
  const classes = useStyles();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    return;
    // if (open) setOpen(false);
    // else setOpen(true);
  };

  const handleDrawerClose = () => {
    return;
    // setOpen(false);
  };
  const handleClick = () => {
    history.push("/");
  };
  const handleLogout = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("apiBaseUrl");
    window.localStorage.removeItem("timestamp");
    window.localStorage.removeItem("appState");
    history.push("/login");
  };
  //   const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ boxShadow: "none", borderBottom: "0.9px solid #E0E0E0" }}
        // position="absolute"
        className={clsx(classes.appBar, classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            color="inherit"
           
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon  />
          </IconButton> */}
          <div className={classes.rowWise}>
            {/* <img
              onClick={handleDrawerOpen}
              src={logo}
              style={{ marginRight: "10px", cursor: "pointer" }}
              alt="sort"
            /> */}

            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              onClick={handleClick}
              className={classes.title}
            >
              Parentof ARC
            </Typography> */}
            <img src={logo} style={{ height: "68px", margin: "5px 0px" }} />
          </div>
          <div className={classes.rowWise}>
            {user.profilePic ? (
              <img
                onClick={handleDrawerOpen}
                src={user.profilePic}
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  width: "45px",
                  height: "45px",
                }}
                alt="sort"
              />
            ) : (
              <Avatar
                style={{ marginRight: "10px", cursor: "pointer" }}
                className={classes.avatar}
              >
                <PersonIcon />
              </Avatar>
            )}
            <div style={{ marginRight: "20px" }}>
              <h4
                style={{
                  margin: "0",
                  marginBottom: "4px",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: "#404040",
                }}
              >
                {user &&
                  user.name &&
                  user.name.charAt(0).toUpperCase() + user.name.slice(1)}
              </h4>
              <p
                style={{
                  margin: "0",
                  fontWeight: 600,
                  fontSize: "12px",
                  textTransform: "capitalize",
                  // lineHeight: "15px",
                  color: "#565656",
                }}
              >
                {user.role}
              </p>
            </div>
            <img
              onClick={handlePopOver}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                width: "18px",
                height: "18px",
              }}
              src={dropDown}
              alt="dropDown"
            />
            <Popover
              id={id}
              open={openPopOver}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ marginLeft: "9%" }}
            >
              {/* <Typography style={{ margin: "10px 10px 0 10px" }}>
                <Link
                  style={{ textDecoration: "none", color: "rgb(64, 64, 64)" }}
                  to="/view-profile"
                  onClick={handleClose}
                >
                  View Profile
                </Link>
              </Typography> */}
              <Typography
                style={{
                  margin: "0 10px 10px 10px",
                  cursor: "pointer",
                  fontWeigt: "bold",
                  paddingTop: "10px",
                }}
                onClick={handleLogout}
              >
                Logout
              </Typography>
            </Popover>
          </div>

          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        // open={true}
      >
        {/* <div style={{ marginRight: "74%" }} className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider /> */}

        <MainListItems
          menus={{
            Content: [
              {
                isAdd: true,
                isEdit: true,
                isView: true,
                name: "Tags",
                role: "Admin",
                _id: "5f4f654982060949f7e8d651",
              },
              {
                isAdd: true,
                isEdit: true,
                isView: true,
                name: "Maps",
                role: "Admin",
                _id: "5f4f654982060949f7e8d652",
              },
              {
                isAdd: true,
                isEdit: true,
                isView: true,
                name: "Views",
                role: "Admin",
                _id: "5f4f654982060949f7e8d653",
              },
            ],
            "User Management": [
              {
                isAdd: true,
                isEdit: true,
                isView: true,
                name: "Roles",
                role: "Admin",
                _id: "5f4f654982060949f7e8d64f",
              },
              {
                isAdd: true,
                isEdit: true,
                isView: true,
                name: "Users",
                role: "Admin",
                _id: "5f4f654982060949f7e8d650",
              },
            ],
          }}
          handleDrawerClose={handleDrawerClose}
        />
      </Drawer>

      <div
        // className={classes.appBarSpacer}
        className={clsx(
          classes.appBarSpacer,
          !open && classes.appBarSpacerClose
        )}
        style={{
          marginTop: "80px",
          marginLeft: "300px",
          width: "-webkit-fill-available",
        }}
      >
        <Routes />
      </div>
    </div>
  );
}
