import React, { useState, useEffect } from "react";
import AdminDashboard from "./AdminDashboard";
import UserDashboard from "./UserDashboard";

const Dashboard = () => {
  var user = JSON.parse(localStorage.getItem("user"));
  return user.role == "admin" ? <AdminDashboard /> : <UserDashboard />;
};

export default Dashboard;
