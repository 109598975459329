import React, { useEffect, useState } from "react";
import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Modal from "@material-ui/core/Modal";
import { Typography, Button, InputBase, fade } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormControl, FormLabel } from "@material-ui/core";
import { RadioGroup, Radio } from "@material-ui/core";
import Drop2 from "../Dropdown/Drop2";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";
import closeb from "../../assets/closeb.png";
import Datep from "./Datep";
import "../../App.css";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `48%`,
    left: `53%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    marginTop: "-3%",
    marginLeft: "30px",
    marginBottom: "20px",
    lineHeight: "48px",
    fontSize: "24px",
    width: "400px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "36px",
    letterSpacing: "0.5px",
    color: "#242F57",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "17px 43px",
  },
  modalPaper: {
    position: "absolute",
    width: 450,
    left: "50% !important",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "47px 0px",
    width: "100px",
  },
  inputfield: {
    width: "400px",
  },
  paper: {
    position: "absolute",
    width: "584px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 58px rgba(121, 145, 173, 0.195504)",
    borderRadius: "8px",
  },
}));
var phoneValid;
var emailValid;
var empname = "abc";
var nameValid = false;
var optionValid;
var genderValid;
var passwordValid;
var dobvalid;
var descvalid;
export default function Modalt({ handleUpdateList, userDetails }) {
  const classes = useStyles();
  const history = useHistory();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const [isLogin, setLogin] = React.useState(false);
  const [firstName, setfirstName] = React.useState("");
  const [taskName, settaskName] = React.useState("");
  const [priority, setpriority] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [dob, setdob] = React.useState(new Date());
  const [dot, setdot] = React.useState("");
  const [namev, setnamev] = React.useState(true);
  const [rolev, setrolev] = React.useState(true);
  const [descv, setdescv] = React.useState(true);
  const [dobv, setdobv] = React.useState(true);
  const [selectedFromTime, setSelectedFromTime] = React.useState(new Date());
  const [selectedToTime, setSelectedToTime] = React.useState(new Date());
  const [lastName, setlastName] = React.useState("");
  const [url, setUrl] = useState("");
  const [via, setVia] = useState("");
  const [gender, setgender] = React.useState("");
  const [role, setrole] = React.useState("");
  const [mobileNumber, setmobileNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  let [users, setUsers] = useState();
  const [errors, setErrors] = useState();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  // req.body = taskName, priority, dateTime, description, _createdBy
  {
    /* taskName, priority, dateTime, timeFrom, timeTo, via, url, description, _createdBy */
  }

  var taskdata = {
    taskName: taskName,
    priority: priority,
    description: description,
    dob: dob,
    dot: dot,
  };
  // useEffect(() => {
  //   async function fetchData() {
  //     const res = axios(
  //       {
  //         method: "get",
  //         url: apiBaseUrl + "v1/user/role-assign",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-auth-token": user.apiToken,
  //         },
  //       }
  //       // { withCredentials: true }
  //     );
  //     res
  //       .then((res) => {
  //         //console.log(res.data.data);
  //         setUsers(res.data.data);
  //       })
  //       .catch((err) => setErrors(err));
  //   }
  //   fetchData();
  // }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (update) => {
    handleUpdateList(update);
    setOpen(false);
    setnamev(true);
    settaskName("");
    setdobv(true);

    setrolev(true);
    setdescv(true);
    setdescription("");
  };
  const handleChange = (event) => {
    console.log(event, typeof event);
    if (Array.isArray(event)) {
      if (event[0] === "fromTime") {
        setSelectedFromTime(event[1]);
      }
      if (event[0] === "toTime") {
        setSelectedToTime(event[1]);
      }
      console.log("Time", event);
    } else if (typeof event === "string") {
      setdobv(true);
      setdob(event);
    } else {
      switch (event.target.name) {
        case "taskName":
          setnamev(true);

          settaskName(
            event.target.value.charAt(0).toUpperCase() +
              event.target.value.slice(1)
          );
          break;
        case "via":
          setVia(event.target.value);
          break;
        case "url":
          setUrl(event.target.value);
          break;
        case "description":
          setdescv(true);

          setdescription(event.target.value);
          break;
        case "gender":
          setgender(event.target.value);
          break;
        case "role":
          setrole(event.target.value);
          break;
        case "dob":
          setdob(event.target.value);
          break;
        case "dot":
          setdot(event.target.value);
          break;
        case "priority":
          setrolev(true);
          setpriority(event.target.value);
          break;
        case "mobileNumber":
          setmobileNumber(event.target.value);
          break;
        case "email":
          setEmail(event.target.value);
          break;
        case "password":
          setPassword(event.target.value);
          break;

        default:
          break;
      }
    }
  };
  const handleDateChange = (event) => {
    setdob(moment(event.target.value).format("YYYY-MM-DD"));
    // console.log("handleDateChange", event.target.value);
  };

  const handleSaveT = (props) => {
    console.log("hello", payload);
    handleAdd();
  };
  var payload = {};
  if (userDetails) {
    payload = {
      taskName: taskName,
      dateTime: dob,
      priority: priority,
      description: description,
      _createdBy: user.id,
      _assignedTo: userDetails._id,
    };
  } else {
    payload = {
      taskName: taskName,
      dateTime: dob,
      priority: priority,
      description: description,
      _createdBy: user.id,
    };
  }
  const handleAdd = (props) => {
    //Validate input
    phoneValid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
      payload.mobileNumber
    );
    emailValid = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(payload.email);

    var reg = /^[ A-Za-z0-9_@./#&+-]*$/;
    // nameValid = reg.test(payload.taskName);

    if (payload.taskName.length > 2) {
      nameValid = true;
      setnamev(true);
    } else {
      nameValid = false;

      setnamev(false);
    }

    // var reg2 = /^[0-9!@#$%^&*\S"a-zA-Z]{3,26}$/;
    // passwordValid = reg2.test(payload.password);
    var reg2 = /^[ A-Za-z0-9_@./#&+-]*$/;
    // descvalid = reg2.test(payload.description);

    if (payload.description.length > 2) {
      descvalid = true;
      setdescv(true);
    } else {
      setdescv(false);
      descvalid = false;
    }
    // if (passwordValid) {
    //   passwordValid = true;
    // }
    optionValid = false;
    if (payload.priority === "") {
      setrolev(false);
      optionValid = false;
    } else {
      setrolev(true);
      optionValid = true;
    }
    dobvalid = false;
    if (payload.dateTime === "") {
      setdobv(false);
      dobvalid = false;
    } else {
      setdobv(true);
      dobvalid = true;
    }
    console.log("optionval", nameValid, optionValid, dobvalid, descvalid);
    const formValid = nameValid && optionValid && dobvalid && descvalid;

    if (!formValid) {
      console.log("error in field");
    } else {
      SignUp(payload);
    }
  };
  const SignUp = (props) => {
    var apiBaseUrl = baseApi;
    if (payload._assignedTo) {
      axios({
        method: "post",
        url: apiBaseUrl + "task/assign",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      })
        .then((response) => {
          alert("Task created");
          handleClose(true);
          // window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          alert(error);
        });
    } else {
      axios({
        method: "post",
        url: apiBaseUrl + "task/create",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      })
        .then((response) => {
          alert("Task created");
          handleClose(true);
          // window.location.reload();
        })
        .catch((error) => {
          console.log("error", error);
          alert(error);
        });
    }
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Paper>
        <div>
          <div
            style={{
              padding: "20px 10px",
            }}
          >
            <img
              onClick={handleClose}
              src={closeb}
              style={{
                width: "40px",
                cursor: "pointer",
                position: "absolute",
                right: "30px",
              }}
            />

            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              style={{ width: "90%", marginTop: "10px", paddingTop: "10px" }}
            >
              Task assigning to {userDetails.firstName}
            </Typography>
          </div>
          <div style={{ padding: "40px" }}>
            <form className={classes.form} noValidate>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="fname"
                    name="taskName"
                    error={!namev}
                    placeholder="Task name"
                    variant="outlined"
                    required
                    helperText={!namev ? "Please check Task name" : ""}
                    fullWidth
                    id="taskName"
                    label="Task Name"
                    autoFocus
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Drop2
                    err={!rolev}
                    required
                    changeevent={handleChange}
                    data={empname}
                  />
                </Grid>

                <Grid item xs={12}>
                  <input
                    onChange={handleDateChange}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "20px",
                      padding: "10px 20px",
                      border: "2px solid rgb(125, 133, 146)",
                      // fontFeatureSettings: "'salt' on",
                      color: "#7D8592",
                      width: "220px",
                      borderRadius: "6px",
                    }}
                    type="date"
                    id="start"
                    name="dob"
                    format="DD MMM YYYY"
                    value={dob}
                    min={moment().format("YYYY-MM-DD")}
                    max="2030-12-31"
                  />
                  {/* <Datep
                    error={dobv}
                    required
                    changeevent={handleChange}
                    changeevent2={handleChange}
                    data={true}
                  /> */}
                </Grid>

                <Grid id="outlined-multiline-static" item xs={12}>
                  <TextField
                    label="Description"
                    error={!descv}
                    helperText={!descv ? "Add about the task description" : ""}
                    multiline
                    required
                    rows={4}
                    name="description"
                    id="description"
                    defaultValue=""
                    placeholder="Add about the task description"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Button
                onClick={handleSaveT}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  background: "#4C6FFF",
                  boxShadow: "0px 6px 12px rgba(63, 140, 255, 0.263686)",
                  borderRadius: "8px",
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "14px",
                  textTransform: "none",
                  lineHeight: "17px",
                  padding: "10px 20px",
                  width: "123px",
                  float: "right",
                }}
              >
                Save Task
              </Button>
            </form>
          </div>
        </div>
      </Paper>
    </div>
  );

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleOpen}
        className={classes.button}
      >
        Add Task
      </Button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
}
