import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { Redirect, useHistory } from "react-router-dom";
import moment from "moment";
import { baseApi } from "./Utils/config";
import axios from "axios";

import { useIdleTimer } from "react-idle-timer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { syncHistoryWithStore } from "react-router-redux";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import store from "./Store";
import "./App.css";
import SideNavBar from "./navs/SideNavBar";
import GoogleLogin from "./components/Login/GoogleLogin";
import PrivateRoute from "./routers/PrivateRouter";
import PublicRoute from "./routers/PublicRouter";
import IdleTimer from "react-idle-timer";
import IdleTimeOutModal from "./IdleTimeOutModal";
var todayInit;
var today;
var payload = [];
function App() {
  const history = useHistory();
  var apiBaseUrl = baseApi;

  const [idletime, setidletime] = React.useState(0);
  const [fromtime, setfromtime] = React.useState(0);
  const [totime, settotime] = React.useState(0);
  document.body.style.zoom = "90%";
  document.body.style.background = "#F7FAFF";
  var idleTimer = useRef(null);
  const [timeout, settimeout] = useState(1000 * 1800 * 1);
  const [showmodal, setshowmodal] = useState(false);
  // const [userloaggedin, setErrors] = useState();
  const [istimeout, setistimeout] = useState(false);
  var user = "";
  if (JSON.parse(localStorage.getItem("user"))) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }
  const onAction = (e) => {
    setistimeout(false);
  };

  const onActive = (e) => {
    setistimeout(false);
  };

  const onIdle = (e) => {
    if (istimeout) {
      history.push("/");
    } else {
      setshowmodal(true);
      idleTimer.reset();
      setistimeout(true);
    }
    setistimeout(false);
  };

  const handleClose = () => {
    setshowmodal(false);
  };

  useEffect(() => {
    todayInit = moment(new Date(), "hh:mm:ss a");

    console.log("current", todayInit);
  }, []);

  return (
    <Router basename="/lms">
      <div className="App">
        <Switch>
          <PublicRoute
            restricted={true}
            component={GoogleLogin}
            path="/login"
            exact
          />
          <PrivateRoute path="/" component={SideNavBar} />
        </Switch>

        {user && (
          <>
            <IdleTimer
              ref={(ref) => {
                idleTimer = ref;
              }}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              debounce={250}
              timeout={timeout}
            />
            <IdleTimeOutModal
              showModal={showmodal}
              handleClose={handleClose}
              payload={payload}
              todayInit={todayInit}
            />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
