import React, { Component, Suspense, lazy } from "react";
import { Link, Redirect, Switch, Route, withRouter } from "react-router-dom";
import TransferList from "../components/Views/CreateView";
import Login from "../components/Login/Login";
import TagsTable from "../components/Tags/Table";
import Container from "@material-ui/core/Container";
import SideNavBar from "../navs/SideNavBar";
import ManageRoles from "../components/Roles/ManageRoles";
import ManageUsers from "../components/Users/ManageUsers";
import ContentManagement from "../components/ContentManagement/ContentManagement";
import AddRole from "../components/Roles/AddRole";
import AddUser from "../components/Users/AddUser";
import PrivateRoute from "./PrivateRouter";
import EditView from "../components/Views/EditView";
import ViewsTableItem from "../components/Views/ViewsTableItem";
import ViewProfile from "../components/Profile/ViewProfile";
import TagsTrail from "../components/Tags/TagsTrail";
import ViewsTrail from "../components/Views/ViewsTrail";
import Tasks from "../components/Tasks/Tasks";
import Doc from "../components/Documentation/Doc";
import UserInteraction from "../components/UserInteraction/index";
import UserProfile from "../components/Users/UserProfile";
import UserOnBoarding from "../components/UserOnBoarding";
import UserAuditProfile from "../components/Users/UserAuditProfile";
import UserInt from "../components/UserInteraction/UserInt";
import Dashboard from "../components/Dashboard/index";
import EnhancedTable from "../components/Table/CardTable";
import TableS from "../components/Dashboard/TableS";
import UserAtn from "../components/Dashboard/UserAtn";
import Card3 from "../components/Dashboard/Card3";
import Card4 from "../components/Dashboard/Card4";
import Card5 from "../components/Dashboard/Card5";
import Card6 from "../components/Dashboard/Card6";
import ProductDocumentation from "../components/Documentation/ProductDocumentation";
import UserDocumentaion from "../components/Documentation/UserDocumentation";
import UserAnalytics from "../components/UserInteraction/UserAnalytics";
import HomeCmp from "../components/iframe/Home";
import CurationCmp from "../components/iframe/Curation";
import RawCmp from "../components/iframe/Raw";

class Routes extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <div>
            <h1>FallBack @ Routes</h1>
          </div>
        }
      >
        <Switch>
          <PrivateRoute
            path="/contentMgmt"
            exact
            component={ContentManagement}
          />
          <PrivateRoute
            path="/user-onboarding"
            exact
            component={UserOnBoarding}
          />
          <PrivateRoute
            path="/user-documentation"
            exact
            component={UserDocumentaion}
          />
          <PrivateRoute
            path="/product-documentation"
            exact
            component={ProductDocumentation}
          />
          <PrivateRoute
            path="/user-analytics"
            exact
            component={UserAnalytics}
          />
          <PrivateRoute
            path="/data-analytics/data-overview"
            exact
            component={HomeCmp}
          />
          <PrivateRoute
            path="/data-analytics/curation"
            exact
            component={CurationCmp}
          />
          <PrivateRoute
            path="/raw-data"
            exact
            component={RawCmp}
          />
          <PrivateRoute path="/user-interaction" exact component={UserInt} />
          <PrivateRoute path="/edit-views" exact component={EditView} />
          <PrivateRoute path="/views-trail" exact component={ViewsTrail} />

          {/* <PrivateRoute path="/views" exact component={TransferList} /> */}
          <PrivateRoute path="/view-profile" exact component={ViewProfile} />

          <PrivateRoute path="/adduser" exact component={AddUser} />
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
          <PrivateRoute
            path="/dashboard/card-details"
            exact
            component={EnhancedTable}
          />
          <PrivateRoute path="/" exact component={Dashboard} />

          <PrivateRoute path="/addrole" exact component={AddRole} />
          <PrivateRoute path="/tagstable" exact component={TagsTable} />
          <PrivateRoute path="/manageRoles" exact component={ManageRoles} />
          <PrivateRoute path="/manageusers" exact component={ManageUsers} />
          <PrivateRoute
            path="/manageusers/profile"
            exact
            component={UserProfile}
          />
          <PrivateRoute path="/dashboard/idletime" exact component={TableS} />
          <PrivateRoute
            path="/dashboard/userattendance"
            exact
            component={UserAtn}
          />
          <PrivateRoute path="/dashboard/paiduser" exact component={Card3} />
          <PrivateRoute path="/dashboard/taskdone" exact component={Card4} />
          <PrivateRoute path="/dashboard/noofas" exact component={Card6} />

          <PrivateRoute path="/dashboard/sched" exact component={Card5} />

          <PrivateRoute
            path="/manageusers/profile/audit"
            exact
            component={UserAuditProfile}
          />
          <PrivateRoute path="/tagstable" exact component={TagsTable} />
          {/* <PrivateRoute component={Dashboard} path="/dashboard" exact /> */}
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(Routes);
