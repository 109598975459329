import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { baseApi } from "../../Utils/config";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    paddingLeft: "10px !important",
  },
}));

export default function Dropdown({ changeevent, data, err, task, value }) {
  const classes = useStyles();
  const [role, setrole] = React.useState("");

  const handleChange = (event) => {
    setrole(event.target.value);
    changeevent(event);
  };
  console.log("value", value);
  console.log("data", data);
  useEffect(() => {
    function setData() {
      if (data) {
        setrole(data);
      }
      if (data === "") {
        setrole("");
      }
    }
    setData();
  }, []);

  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));

  const [roleoptions, setroleoptions] = useState();
  const [errors, setErrors] = useState();

  // useEffect(() => {
  //   function fetchData() {
  //     const res = axios(
  //       {
  //         method: "get",
  //         url: apiBaseUrl + "v1/user/role/list",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-auth-token": user.apiToken,
  //         },
  //       }
  //       // { withCredentials: true }
  //     );
  //     res
  //       .then((res) => {
  //         //console.log(res.data.data);
  //         setroleoptions(res.data.data);
  //       })
  //       .catch((err) => setErrors(err));
  //   }
  //   fetchData();
  // }, []);

  return (
    <div>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={err}
      >
        <InputLabel required id="demo-simple-select-outlined-label">
          Assign Roles
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="priority"
          value={role}
          onChange={handleChange}
          label="Select User Role *"
          required
        >
          <MenuItem value="">
            <em>Select Role</em>
          </MenuItem>
          {[
            "admin",
            "r&d head",
            "delivery experts",
            "course coordinator",
            "product head",
            "mai gq experts",
          ].map((role, i) => (
            <MenuItem className={classes.title} key={i} value={role}>
              {" " + role.charAt(0).toUpperCase() + role.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
