import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { baseApi } from "../../Utils/config";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import usimg from "../../assets/usimg.png";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Typography } from "@material-ui/core";
import AddEmpModal from "./AddEmpModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditEmpModal from "./EditEmpModal";
import back_icon from "../../assets/back_icon.png";
import { Edit } from "@material-ui/icons";
import AuditLogs from "../Admin/AuditLogs";
import {
  UserAttendanceReport,
  OverAllIdleTimeReport,
  TasksReportCard,
  SessionsReportCard,
} from "../DataCards/Datacards";
import DateRangeComponent from "./DateRangePicker";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "1%",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#33343D",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listname: {
    margin: "10px 20px",
  },
  dataitem: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0A1629",
  },
  butonz: {
    "&:hover": {
      background: "#ccc",
    },
    background: "#F4F9FD",
  },
}));
var userx = [];

export default function UserAuditProfile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  var apiBaseUrl = baseApi;
  var employeeID = props.location.state._id.toString();
  console.log("props-location-", props.location);

  var user = JSON.parse(localStorage.getItem("user"));
  const [pageData, setPageData] = React.useState("");
  const [load, setload] = useState(true);
  const [auditdata, setauditdata] = useState("");
  const [analyticsData, setAnalyticsData] = useState("");
  const [userData, setUserData] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [errors, setErrors] = useState("");

  const fetchData = (params) => {
    const modulesList = axios({
      method: "get",
      url: apiBaseUrl + `permission/${employeeID}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    });
    modulesList
      .then((response, error) => {
        console.log("permisson-------", response.data);
        setPageData(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const calculate_age = (dob) => {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    return Math.abs(age.getUTCFullYear() - 1970);
  };
  function UserDetails(selectedDate) {
    const res = axios(
      {
        method: "get",
        url:
          apiBaseUrl +
          `users/userDetails/${employeeID}?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${
                  selectedDate.toDate
                    ? selectedDate.toDate
                    : selectedDate.fromDate
                }`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        console.log("userDetails-----------", res.data.data);
        setUserData(res.data.data.userDetails.userDetails);
        setAnalyticsData(res.data.data);
        setload(false);
      })
      .catch((err) => setErrors(err));
  }
  function UserAudDetails(selectedDate) {
    const res = axios(
      {
        method: "get",
        url:
          apiBaseUrl +
          `users/auditTrail/${employeeID}?${
            selectedDate
              ? `from=${selectedDate.fromDate}&to=${
                  selectedDate.toDate
                    ? selectedDate.toDate
                    : selectedDate.fromDate
                }`
              : "query=today"
          }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        console.log("userAUDI-----Details", res.data.data);
        // setUserData(res.data.data.userDetails.userDetails);
        // setuserscol(res.data.data.columns);
        // setorgusers(res.data.data.data);
        setauditdata(res.data);
        setload(false);
      })
      .catch((err) => setErrors(err));
  }
  const UpdatePermissions = (postData) => {
    const modulesList = axios({
      method: "post",
      url: apiBaseUrl + `permission`,
      data: postData,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.jT,
      },
    });
    modulesList
      .then((response, error) => {
        // console.log(response.data);
        setPageData(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
    UserDetails();
    UserAudDetails();

    // return () => {
    //   cleanup;
    // };
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  const hanldeDateChange = (date) => {
    setSelectedDate(date);
    UserDetails(date);
    UserAudDetails(date);
  };

  return (
    <div style={{ margin: "0 40px 40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "1em",
          marginTop: "1%",
          marginBottom: "3%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={back_icon}
              onClick={() => {
                history.push("/manageusers");
              }}
              style={{
                cursor: "pointer",
                height: "20px",
                width: "39px",
                marginRight: "12px",
              }}
              alt="back"
            />
            <Typography variant="h4" className={classes.title}>
              Staff Management
            </Typography>
          </div>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "19px",
              letterSpacing: "0.5px",
              color: "#636E95",
              marginBottom: "3%",
            }}
          >
            Add users to this platform and manage what they have access to
          </Typography>
        </div>
        <DateRangeComponent handleDateChange={hanldeDateChange} />
      </div>

      <div style={{ background: "white" }}>
        {!load && userData ? (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
          >
            <ListItem button>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px 10px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      alt=""
                      src={userData.profilePic}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                  }}
                >
                  <ListItemText
                    // primary={userData.firstName}
                    className={classes.listname}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#0A1629",
                    }}
                  >
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1629",
                        margin: 0,
                      }}
                    >
                      {userData.firstName?.charAt(0).toUpperCase() +
                        userData.firstName?.slice(1)}
                    </p>
                  </ListItemText>

                  <ListItemText>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "19px",

                        color: "#91929E",
                        margin: "-8px 19px 0px",
                      }}
                    >
                      {userData.email}
                    </p>
                  </ListItemText>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                    width: "10%",
                  }}
                >
                  <ListItemText
                    primary="Gender"
                    className={classes.listname}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "19px",
                      color: "#91929E",
                    }}
                  />
                  <ListItemText>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1629",
                        margin: "-8px 19px 0px",
                      }}
                    >
                      {userData.gender.charAt(0).toUpperCase() +
                        userData.gender.slice(1)}
                    </p>
                  </ListItemText>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                    width: "13%",
                  }}
                >
                  <ListItemText
                    primary="Date of joining"
                    className={classes.listname}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "19px",
                      color: "#91929E",
                    }}
                  />
                  <ListItemText>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1629",
                        margin: "-8px 19px 0px",
                      }}
                    >
                      {moment(userData.dob).format("DD MMM YYYY")}
                    </p>
                  </ListItemText>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 10px",
                    width: "15%",
                  }}
                >
                  <ListItemText
                    primary="Position"
                    className={classes.listname}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "19px",
                      color: "#91929E",
                    }}
                  ></ListItemText>
                  <ListItemText>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1629",
                        margin: "-8px 19px 0px",
                      }}
                    >
                      {userData.role.charAt(0).toUpperCase() +
                        userData.role.slice(1)}
                    </p>
                  </ListItemText>
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: "0 10px",
                    width: "18%",
                    marginLeft: "7%",
                  }}
                >
                  {/* <ListItemText>
                  <button
                    className={classes.butonz}
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "center",
                      color: "#242F57",
                      borderRadius: "14px",
                      border: "none",
                      cursor: "pointer",
                      padding: "10px 20px",
                    }}
                  >
                    Audit Trail
                  </button>
                </ListItemText> */}
                  <ListItemText>
                    {/* <button
                    style={{
                      background: "#F4F9FD",
                      border: "none",
                      borderRadius: "14px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#242F57",
                      height: "32px",
                      minWidth: "128px",
                      marginTop: "30px",
                      marginRight: "20px",
                    }}
                  >
                    Edit
                  </button> */}
                    <EditEmpModal datax={userData} />
                  </ListItemText>
                </div>
              </div>
            </ListItem>
          </List>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}

        <div style={{ margin: "30px 30px" }}>
          <Typography
            variant="h4"
            align="left"
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "22px",
              letterSpacing: "0.5px",
              color: "#242F57",
            }}
          >
            Audit Trail
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            margin: "0px 10px 40px",
            // justifyContent: "space-between",
          }}
        >
          {analyticsData && analyticsData.loggedIn && (
            <UserAttendanceReport
              userDetails={userData}
              selectedDate={selectedDate}
              analyticsData={analyticsData.loggedIn}
            />
          )}
          {analyticsData && analyticsData.ideltimeData && (
            <OverAllIdleTimeReport
              userDetails={userData}
              selectedDate={selectedDate}
              analyticsData={analyticsData.ideltimeData}
            />
          )}
          {analyticsData && analyticsData.task && (
            <TasksReportCard
              userDetails={userData}
              selectedDate={selectedDate}
              analyticsData={analyticsData.task}
            />
          )}
          {analyticsData && analyticsData.task && (
            <SessionsReportCard
              userDetails={userData}
              selectedDate={selectedDate}
              analyticsData={analyticsData.task}
            />
          )}
        </div>
        <div
          className={classes.root}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <AuditLogs data={auditdata} />
        </div>
      </div>
    </div>
  );
}
