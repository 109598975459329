import React, { useEffect } from "react";
import axios from "axios";
import { baseApi } from "../../Utils/config";
import moment from "moment";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import zoomIcon from "../../assets/zoomIcon.png";
import scheduleIcon from "../../assets/scheduleIcon.png";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "auto",
    // width: "100%",
    // backgroundColor: theme.palette.background.paper,
    // border: "1px solid #EAEDF7",
    // boxShadow: "0px 2px 4px rgba(28, 41, 90, 0.0367952)",
    // borderRadius: "10px",
    // padding: "1em",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "1em",
    cursor: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgba(223, 227, 236, 0.48)",
    boxShadow: "0px 2px 4px rgba(28, 41, 90, 0.0367952)",
    borderRadius: "8px",
    margin: "1em 0",
  },
  checkbox: {
    backgroundColor: "#86DEFA",
  },
  icon: {
    borderRadius: 3,
    border: "1px solid #DFE3EC",
    width: 20,
    height: 20,
    backgroundColor: "#ffffff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#86DEFA",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    cursor: "pointer",
    backgroundColor: "#86DEFA",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#86DEFA",
    },
  },
  listItemPrimary: {
    color: "#4E5A7E",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
  },
  listItemSecondary: {
    width: "auto",
    color: "#636D96",
    fontSize: "14px",
    lineHeight: "24px",
  },
  colorEdge: {
    width: "4px",
    height: "100%",
    background: "#4848BE",
    borderRadius: "24px",
  },
}));

export default function CheckboxList({
  handleTaskUpdate,
  userDetails,
  taskList,
}) {
  const classes = useStyles();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  // const taskListData = [...taskList];
  const [checked, setChecked] = React.useState([0]);
  const [taskListData, setTaskListData] = React.useState([...taskList]);
  useEffect(() => {
    if (taskList) {
      setTaskListData(taskList);
    }
  }, [taskList]);
  function updateList(event, id) {
    let payload = {
      taskStatus: event.target.checked ? "completed" : "pending",
      _id: id,
    };
    const res = axios(
      {
        method: "put",
        url: apiBaseUrl + `task/update`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        console.log("res ", res);
        console.log("taskList", taskListData);
        const data = [...taskListData];
        data.forEach((item) => {
          if (item._id === id) {
            item.taskStatus = payload.taskStatus;
            return item;
          }
        });
        setTaskListData(data);
        handleTaskUpdate(true);
        // setauditdata(res.data);
      })
      .catch((err) => console.log(err));
  }
  if (taskListData && taskListData.length === 0) {
    return "No Task List";
  }

  return (
    <div className={classes.root}>
      {taskListData &&
        taskListData.map((value, i) => {
          const labelId = `checkbox-list-label-${i}`;

          return (
            <div
              key={i + "listitem"}
              className={classes.listItem}
              style={{
                borderLeft: `4px solid ${
                  value.taskStatus === "completed"
                    ? "#66CB9F"
                    : value.priority == "High"
                    ? "#FF5630"
                    : value.priority == "Medium"
                    ? "#FFCE56"
                    : "#4C6FFF"
                }`,
              }}
            >
              {userDetails._id == user.id && (
                <ListItemIcon>
                  <Checkbox
                    onChange={(e) => updateList(e, value._id)}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    color="primary"
                    edge="start"
                    checked={value.taskStatus == "completed" ? true : false}
                    tabIndex={-1}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                style={{ width: "100px", marginLeft: "1em" }}
                className={classes.listItemPrimary}
                id={labelId}
                // primary={value.taskName}
              >
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "#4E5A7E",
                    margin: 0,
                  }}
                >
                  {value.taskName}
                </p>
              </ListItemText>
              <ListItemText
                className={classes.listItemSecondary}
                id={labelId}
                style={{ width: "275px", margin: "0 15px" }}
              >
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "#636D96",
                    margin: 0,
                  }}
                >
                  {value.description.charAt(0).toUpperCase() +
                    value.description.slice(1)}
                </p>
              </ListItemText>
              <ListItemText
                className={classes.listItemSecondary}
                id={labelId}
                edge="end"
                style={{ width: "100px" }}
              >
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "#636D96",
                    margin: 0,
                  }}
                >
                  {value.taskStatus.charAt(0).toUpperCase() +
                    value.taskStatus.slice(1)}
                </p>
              </ListItemText>
            </div>
          );
        })}
    </div>
  );
}
