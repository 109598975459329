import { combineReducers } from 'redux';
import UserReducer from './user-reducer';
import {routerReducer} from 'react-router-redux'

const reducers = {
  userStore: UserReducer,
  routing: routerReducer

}

const rootReducer = combineReducers(reducers);

export default rootReducer;
