import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "./Table";
import ScheduleList from "../Tasks/ScheduleList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: "16px",
    backgroundColor: theme.palette.background.paper,
  },
  taskTitle: {
    borderRadius: "16px",
    width: "fit-content",
    backgroundColor: "rgba(196, 196, 196, 0.25)",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#1F2D3D",
    padding: "5px 10px",
    marginBottom: "1em",
  },
  tabStyle: {
    boxShadow: "unset",
    backgroundColor: "#F4F7FC",
    color: "#1A202C",
  },
}));

export default function AuditLogs(props) {
  const { data } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        {/* <Tabs
          className={classes.tabStyle}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            className={classes.tabStyle}
            label="Activity Log"
            {...a11yProps(0)}
          />
        </Tabs> */}
      </AppBar>
      <TabPanel value={value} index={0}>
        <Typography
          className={classes.taskTitle}
          component="div"
          variant="subtitle1"
        >
          Activity Log
        </Typography>
        <Table data={data} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Table />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Table />
      </TabPanel>
    </div>
  );
}
