import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";

import { Typography, Paper, CardMedia } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import GroupedSelect from "../Views/GroupingList";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import DeleteIcon from "../../assets/DeleteIcon.png";
import addIcon from "../../assets/add_square.png";
import { baseApi } from "../../Utils/config";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3% auto",
    flexGrow: 1,
    backgroundColor: "#F7FAFF",
    width: "900px",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2, 2, 1),
      backgroundColor: theme.palette.background.paper,
    },
  },
  textFields: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    padding: theme.spacing(2, 2),
  },
  tagName: {
    width: "305px",
    boxSizing: "border-box",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  tagDetails: {
    width: "486px",
    boxSizing: "border-box",
    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  greenButton: {
    marginTop: "2em",
    marginBottom: "1em",
    background: "#0E8050",
    color: "#FFFFFF",
  },
  formControl: {
    margin: theme.spacing(2, 4),
    minWidth: 180,
    // borderRadius: "3px",
    background: "#ffffff",
    boxShadow: " 4px 4px 10px rgba(0, 0, 0, 0.05)",
  },
  divContainer: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  nextButton: {
    margin: theme.spacing(4, 2, 8),
  },
  removeItem: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function CreateTag(props) {
  var ID = props.location.state.id.toString();
  const { tablename, tabledesc, column } = props.location.state;
  const history = useHistory();

  const [tname, setTablename] = useState(tablename);
  const [desc, setTabledesc] = useState(tabledesc);
  const [dataTypes, setDataTypes] = useState([]);
  const [references, setReferences] = useState([]);
  const [addNewColumn, setAddNewColumn] = useState(false);
  const [disableRefernce, setDisableRefernce] = useState(true);

  const [form, setForm] = useState({
    name: "",
    dataType: "",
    isRequired: false,
    reference: "",
  });
  const [tableColumn, setcolumn] = useState(column);
  //console.log(ID);
  const classes = useStyles();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  let [tagstable, setTagsTable] = useState();

  var payload = {
    tableId: ID,
    start: 0,
    limit: 200,
  };

  useEffect(() => {
    fetchData();
    fetchDataTypes();
    fetchReferences();
  }, []);
  function fetchData() {
    const res = axios(
      {
        method: "post",
        url: apiBaseUrl + "v1/content/list",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log(response.data.data);
          setTagsTable(response.data.data);
        } else if (response.data.statusCode === 400) {
          console.log("Username password do not match");
          alert("username password do not match");
        } else {
          alert("Wrong Credentials!! , Please try again");
        }
      })
      .catch((error) => {
        console.log("login error message", error.message);
        alert("User does not exist");
      });
  }
  const fetchDataTypes = () => {
    const res = axios({
      method: "get",
      url: apiBaseUrl + "v1/content/dataTypes",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    });
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log("dataTypes", response.data.data);
          setDataTypes(response.data.data);
        }
      })
      .catch((error) => {
        console.log("login error message", error.message);
        alert("User does not exist");
      });
  };
  const fetchReferences = () => {
    const res = axios({
      method: "get",
      url: apiBaseUrl + "v1/content/table/references",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.apiToken,
      },
    });
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log("references", response.data.data);
          setReferences(response.data.data);
        }
      })
      .catch((error) => {
        console.log("login error message", error.message);
        alert("User does not exist");
      });
  };

  const handleNameChange = (event) => {
    event.persist();
    setTablename(() => event.target.value);
  };
  const handleDescChange = (event) => {
    event.persist();
    setTabledesc(() => event.target.value);
  };
  const hanldeFormChange = (event) => {
    event.persist();
    console.log(event.target.name, event.target.value);
    if (event.target.name === "dataType") {
      if (
        event.target.value === "5ef093198206097c0f74d95e" ||
        event.target.value === "5ef093198206097c0f74d95f"
      ) {
        setDisableRefernce(false);
      } else {
        setForm((prevState) => {
          return { ...prevState, reference: "" };
        });
        setDisableRefernce(true);
      }
    }

    setForm((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };
  const hanldeIsRequired = (event) => {
    event.persist();
    console.log(event.target.name, event.target.checked);
    setForm((prevState) => {
      return { ...prevState, [event.target.name]: event.target.checked };
    });
  };

  const handleSubmit = () => {
    let payload = {
      contentTable: ID,
      displayName: tname,
      description: desc,
      columns: [form],
    };
    const res = axios(
      {
        method: "post",
        url: apiBaseUrl + "v1/content/table",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.apiToken,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((response) => {
        if (response.data.statusCode === 201) {
          console.log(response.data.data);
          setForm({
            name: "",
            dataType: "",
            isRequired: false,
            reference: "",
          });
          history.push("/tags");
          alert("Columns Added Successfully");
        } else if (response.data.statusCode === 400) {
          console.log("Somethimg went wrong");
          alert("Somethimg went wrong");
        } else {
          alert("Wrong Credentials!! , Please login again");
        }
      })
      .catch((error) => {
        console.log("table update error message:", error.message);
        alert("table update error, Please login again");
      });
  };
  const handleAddColumn = () => {
    setAddNewColumn(() => {
      return !addNewColumn;
    });
  };

  return (
    <>
      <div className={classes.root}>
        <Typography align="left" variant="h5" style={{ marginBottom: "30px" }}>
          Manage Tags {">"} Edit Tags
        </Typography>

        <Paper>
          <div style={{ marginLeft: "2%" }}>
            <div className={classes.title}>
              <Typography variant="h6">Tag Details</Typography>
            </div>
            <form className={classes.form} autoComplete="off">
              <div className={classes.textFields}>
                <TextField
                  className={classes.tagName}
                  id="outlined-textarea"
                  label="Tag Table Name"
                  placeholder="Placeholder"
                  multiline
                  value={tname}
                  variant="outlined"
                  onChange={handleNameChange}
                />
                <TextField
                  className={classes.tagDetails}
                  id="outlined-multiline-static"
                  label="Tag Table Description"
                  multiline
                  rows={4}
                  value={desc}
                  onChange={handleDescChange}
                  variant="outlined"
                />
              </div>
            </form>
            <Container
              style={{ margin: "0 -.5%" }}
              className={classes.rulesContainer}
            >
              <Button
                variant="contained"
                onClick={() => handleAddColumn()}
                className={classes.greenButton}
              >
                <img style={{ marginRight: "6px" }} src={addIcon} /> ADD
              </Button>
            </Container>
            {addNewColumn && (
              <div className={classes.rowWise}>
                <div className={classes.divContainer}>
                  <TextField
                    name="name"
                    id="outlined-basic"
                    label="Column Name"
                    variant="outlined"
                    value={form["name"]}
                    onChange={(e) => hanldeFormChange(e)}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="grouped-select">Data-Type</InputLabel>

                    <Select
                      id="grouped-select"
                      name="dataType"
                      value={form["dataType"]}
                      onChange={hanldeFormChange}
                    >
                      {dataTypes.map((datatype, i) => (
                        <MenuItem
                          key={"datatype" + i}
                          value={datatype._id}
                          datatype={datatype.name}
                        >
                          {datatype.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="grouped-select">Reference</InputLabel>
                    <Select
                      id="grouped-select"
                      disabled={disableRefernce}
                      name="reference"
                      value={form["reference"]}
                      onChange={hanldeFormChange}
                    >
                      {references.map((reference, i) => (
                        <MenuItem key={"reference" + i} value={reference.name}>
                          {reference.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={form["isRequired"]}
                        onChange={hanldeIsRequired}
                        name="isRequired"
                      />
                    }
                    label="Is Mandatory"
                  />
                  <CardMedia
                    className={classes.media}
                    image={DeleteIcon}
                    title="Delete"
                  />
                </div>
              </div>
            )}
            <Typography component="h1" style={{ margin: "1em" }}>
              Column Creation Rules
            </Typography>

            {tagstable &&
              tagstable.columns.map((col, ind) => {
                return (
                  <div key={col._id + ind} className={classes.rowWise}>
                    <div className={classes.divContainer}>
                      <TextField
                        disabled
                        key={col._id}
                        id="outlined-basic"
                        label="Column Name"
                        variant="outlined"
                        value={
                          col.displayName.charAt(0).toUpperCase() +
                          col.displayName.slice(1)
                        }
                      />
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">
                          Data-Type
                        </InputLabel>

                        <Select
                          defaultValue={col.dataType}
                          disabled
                          id="grouped-select"
                        >
                          <MenuItem value={col.dataType}>
                            {col.dataType}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="grouped-select">
                          Reference
                        </InputLabel>
                        <Select
                          disabled
                          defaultValue={col.reference ? col.reference : "..."}
                          id="grouped-select"
                        >
                          <MenuItem value={col.reference}>
                            {col.reference ? col.reference : "..."}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        disabled
                        control={
                          <GreenCheckbox
                            checked={col.isRequired}
                            name="ismandatory"
                          />
                        }
                        label="Is Mandatory"
                      />
                    </div>
                  </div>
                );
              })}

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              className={classes.nextButton}
            >
              Next
            </Button>
          </div>
        </Paper>
      </div>
    </>
  );
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
