import React, { Component, useEffect, useState } from "react";
import moment from "moment";
// import { DateRangePicker, DateRange } from "react-date-range";
import Modal from "@material-ui/core/Modal";
import { Typography, Button, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
// import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
// import DateRangePicker from "@material-ui/lab/DateRangePicker";
// import StaticDateRangePicker from "@material-ui/lab/StaticDateRangePicker";
import Box from "@material-ui/core/Box";
import { Redirect, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";
import closeb from "../../assets/closeb.png";
import date_dropdown_icon from "../../assets/date_dropdown_icon.png";
import Datep from "./Datep";

import "../../App.css";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `51%`,
    left: `53%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  title: {
    float: "left",
    marginTop: "-3%",
    marginLeft: "30px",
    marginBottom: "20px",
    lineHeight: "48px",
    fontSize: "24px",
    width: "400px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "36px",
    letterSpacing: "0.5px",
    color: "#242F57",
  },
  paper: {
    height: "500px",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "17px 43px",
  },
  modalPaper: {
    position: "absolute",
    // width: 450,
    height: "500px",
    left: "50% !important",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: "47px 0px",
    width: "100px",
  },
  inputfield: {
    width: "400px",
  },
  paper: {
    position: "absolute",
    width: "600px",
    padding: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 6px 58px rgba(121, 145, 173, 0.195504)",
    borderRadius: "8px",
  },
}));

export default function DateRangeComponent({ handleDateChange }) {
  const history = useHistory();
  const classes = useStyles();

  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const [isLogin, setLogin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    moment(new Date()).format("DD MMM")
  );
  const [modalStyle] = React.useState(getModalStyle);
  const [value, setValue] = React.useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDate(
      `${
        moment(dateRange.fromDate).format("DD MMM") +
        (dateRange.toDate
          ? " - " + moment(dateRange.toDate).format("DD MMM")
          : "")
      }`
    );
    setOpen(false);
  };
  const handleSelectDate = (event) => {
    if (event.target.name === "fromDate") {
      setDateRange({
        [event.target.name]: moment(event.target.value).format("YYYY-MM-DD"),
      });
    } else {
      setDateRange({
        ...dateRange,
        [event.target.name]: moment(event.target.value).format("YYYY-MM-DD"),
      });
    }
    // console.log("dateRange", dateRange);
  };

  function CompareDate(dateRange) {
    //Note: 00 is month i.e. January
    if (dateRange.toDate && dateRange.fromDate) {
      var dateOne = new Date(dateRange.fromDate); // Year, Month, Date
      var dateTwo = new Date(dateRange.toDate); // Year, Month, Date
      if (moment(dateRange.fromDate).isSame(dateRange.toDate)) {
        return true;
      } else {
        return moment(dateRange.fromDate).isBefore(dateRange.toDate);
      }
    } else return true;
  }

  const handleSubmit = () => {
    if (CompareDate(dateRange)) {
      handleDateChange(dateRange);
      handleClose();
    } else {
      alert("From date is Greater than To Date");
    }
  };

  return (
    <>
      <button
        variant="contained"
        color="primary"
        size="medium"
        style={{
          cursor: "pointer",
          // width: "120px",
          background: "#FAFCFE",
          border: "1px solid #97A0C3",
          borderRadius: "10px",
          height: "40px",
          fontSize: "16px",
          lineHeight: "19px",
          textAlign: "center",
          color: "#636E95",
        }}
        onClick={handleOpen}
      >
        {selectedDate}
        <img
          src={date_dropdown_icon}
          style={{
            verticalAlign: "middle",
            marginLeft: "30px",
            width: "8px",
          }}
          alt="v"
        />
      </button>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider> */}
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select date"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider> */}

          <Paper style={{ display: "flex", flexDirection: "column" }}>
            <form style={{ display: "flex", justifyContent: "space-between" }}>
              <label>
                From date:
                <input
                  onChange={handleSelectDate}
                  style={{ margin: "20px", cursor: "pointer" }}
                  type="date"
                  id="start"
                  name="fromDate"
                  format="DD-MM-YYYY"
                  value={selectedDate.fromDate}
                  min="2014-01-01"
                  max="2030-12-31"
                />
              </label>

              <label>
                To date:
                <input
                  onChange={handleSelectDate}
                  style={{ margin: "20px", cursor: "pointer" }}
                  type="date"
                  id="start"
                  name="toDate"
                  format="DD-MM-YYYY"
                  value={selectedDate.toDate}
                  min="2014-01-01"
                  max="2030-12-31"
                />
              </label>
            </form>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
              className={classes.button}
              style={{ width: "fit-content", alignSelf: "flex-end" }}
            >
              Submit
            </Button>

            {/* <DatePicker /> */}
          </Paper>
        </div>
      </Modal>
    </>
  );
}
// class DatePicker extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   handleSelect(ranges) {
//     console.log(ranges);
//     // {
//     //   selection: {
//     //     startDate: [native Date Object],
//     //     endDate: [native Date Object],
//     //   }
//     // }
//   }
//   render() {
//     const selectionRange = [
//       {
//         startDate: new Date(),
//         endDate: null,
//         key: "selection",
//       },
//     ];
//     return (
//       <DateRangePicker
//         editableDateInputs={true}
//         onChange={(item) => this.handleSelect([item.selection])}
//         moveRangeOnFirstSelection={false}
//         ranges={selectionRange}
//       />
//       //   <DateRangePicker ranges={[selectionRange]} onChange={this.handleSelect} />
//     );
//   }
// }
