import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  InputBase,
  fade,
  CircularProgress,
} from "@material-ui/core";
import Searchbar from "../Search/Searchbar";
import TableList from "../CustomTable/TableList";
import UserTable from "./UserTable";
import Add from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import Nodata from "../Table/Nodata";
import { baseApi } from "../../Utils/config";
// import { connect } from 'react-redux';
// import * as actions from '../../actions/user-actions';
import Modalt from "./Modalt";
import AddEmpModal from "./AddEmpModal";
import ListComp from "./ListComp";
import noti from "../../assets/notifications.png";
import Filterx from "./Filterx";
import { CheckCircleOutline } from "@material-ui/icons";
import Dropfilter from "../Dropdown/Dropfilter";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "1%",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#33343D",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "2px solid #E7E8F2",
    "&:hover": {
      backgroundColor: fade("#0057FF", 0.25),
    },
    marginLeft: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    maxWidth: "244px",
    height: "max-content",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(4),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    maxWidth: "244px",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
// class ManageUsers extends Component {
//   constructor(props){
//     super(props);
//   }
//   componentDidMount(){
//     this.props.onfetchuser2();
//   }
//   render()
//    {
//   const {data} = this.props.users.users;
var num = 0;

export default function ManageUsers() {
  const classes = useStyles();
  var apiBaseUrl = baseApi;
  var user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const [role, setRole] = useState("all");
  const [age, setAge] = React.useState("");
  let [users, setusers] = useState();
  let [orgusers, setorgusers] = useState();
  let [col, setuserscol] = useState();
  let [nodata, setnodata] = useState(false);
  let [load, setload] = useState(true);
  let [numx, setnumx] = useState(0);

  let [filter, setFilter] = useState([]);
  var filterdata = [];
  const [errors, setErrors] = useState();

  const checkrole = () => {
    // if (role === "admin") {
    //   role = "admin";
    // } else {
    //   role = "all";
    // }

    console.log("role", role);
  };

  async function fetchData(role) {
    checkrole();
    const res = axios(
      {
        method: "get",
        url: apiBaseUrl + `users/userDetails?role=${role}`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": user.jT,
        },
      }
      // { withCredentials: true }
    );
    res
      .then((res) => {
        console.log(res.data.data);
        setusers(res.data.data);
        // setuserscol(res.data.data.columns);
        // setorgusers(res.data.data.data);
        setload(false);
      })
      .catch((err) => setErrors(err));
  }
  useEffect(() => {
    // document.body.style.zoom = "70%";

    fetchData(role);
  }, [role]);

  const handleAdd = (event) => {
    history.push("/addUser");
  };

  const handlefilteruser = (e) => {
    var currentList = [];
    var newList = [];

    if (e.target.value !== "") {
      currentList = orgusers;
      newList = currentList.filter((item) => {
        const lc = item.name.toLowerCase();
        let ac = item.role.name.toLowerCase();
        let dc = ac.concat(lc);
        let ec = item.email.toLowerCase();
        let all = dc.concat(ec);
        const filter = e.target.value.toLowerCase();
        return all.includes(filter);
      });
    } else {
      newList = orgusers;
    }
    setTableData(newList);
  };

  const handledata = (data) => {
    // alert(data);
    setload(true);
    setRole(data);
    setnumx(num++);
    // fetchData(role);

    // if (data === "admin") {
    //   setnumx(1);
    //   role = "admin";
    // } else if (data === "all") {
    //   setnumx(2);
    //   role = "all";
    // }
  };

  const emails = [
    { name: "All", value: "all" },
    { name: "Admin", value: "admin" },
    { name: "R&D Head", value: "r&d-head" },
    { name: "Delivery experts", value: "delivery-experts" },
    { name: "Course coordinator", value: "course-coordinator" },
    { name: "Product head", value: "product-head" },
    { name: "Mai gq experts", value: "mai-gq-experts" },
  ];
  const setTableData = (data) => {
    filterdata = data;

    if (filterdata.length > 0) {
      setusers(filterdata);
      setnodata(false);
    } else {
      setusers([]);
      setnodata(true);
    }
  };
  // let addUserPermission = user && user.menu["User Management"][1].isAdd;

  return (
    <div style={{ margin: "0 40px 40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "1em",
          marginTop: "1%",
          marginBottom: "3%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h4" className={classes.title}>
            Staff Management
          </Typography>
          <Typography
            variant="p"
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "19px",
              letterSpacing: "0.5px",
              color: "#636E95",
              marginBottom: "3%",
            }}
          >
            Add users to this platform and manage what they have access to
          </Typography>

          {/* {orgusers && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search for User"
                onChange={handlefilteruser}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            <Searchbar users={users.data} filtereduser={handlefilteruser} />
          )} */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "2em",
          }}
        >
          {
            <div style={{ display: "flex", height: "40px" }}>
              {/* <Filterx changeevent={handledata} /> */}
              <Dropfilter changeevent={handledata} />
              {/* <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                style={{
                  background: "#FAFCFE",
                  boxShadow: "0px 6px 58px rgb(196 203 214 / 10%)",
                  padding: "10px 0px",
                  marginRight: "15px",
                }}
                onClick={() => {}}
              >
                <img src={noti} style={{ width: "40px" }} />
              </Button> */}
              <AddEmpModal />

              {/* <Avatar>
            <Add />
          </Avatar> */}
            </div>
          }
        </div>
      </div>

      {/* {users && col && <UserTable rows={users} headcells={col} />}
      {users && col && nodata &&
        <Nodata rows={users} headcells={col} />
      } */}
      {load && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {!load && <ListComp role={role} data={users} />}
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     users: state.userStore

//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onfetchuser: () => dispatch(actions.fetchUsers()),
//     onfetchuser2: () => dispatch(actions.usersListAsync())

//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
