import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import Appbar from "../AppBar/Appbar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { baseApi } from "../../Utils/config";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5, 8),
    // marginTop: theme.spacing(25),
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [isLogin] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    console.log(event);
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
    // setPostData({
    //   [event.target.name]: event.target.value,
    // });
  };

  const Login = () => {
    var apiBaseUrl = baseApi;
    // var self = this; https://test.mai.family/cms-api/v1/user/authenticate

    if ("res") {
      var payload = {
        email: email,
        password: password,
      };

      axios(
        {
          method: "post",
          url: apiBaseUrl + "v1/user/authenticate",
          data: payload,
          headers: {
            "Content-Type": "application/json",
          },
        }
        // { withCredentials: true }
      )
        .then((response) => {
          if (response.data.statusCode === 201) {
            var date = Date.now();
            window.localStorage.setItem("apiBaseUrl", apiBaseUrl);
            window.localStorage.setItem(
              "user",
              JSON.stringify(response.data.data)
            );
            window.localStorage.setItem("timestamp", date);
            // this.props.handleSuccessfulAuth(response.data);
            // this.setState({
            //   toDashboard: true,
            // });
            history.push("/");
          } else if (response.data.statusCode === 400) {
            console.log("Username password do not match");
            alert("username password do not match");
          } else {
            alert("Wrong Credentials!! , Please try again");
          }
        })
        .catch((error) => {
          console.log("login error message", error.message);
          alert("User does not exist");
        });
    }
  };
  if (isLogin) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Appbar />
      <Container
        component="main"
        style={{
          maxWidth: "500px",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        <Paper>
          <div className={classes.paper}>
            <Typography
              component="h1"
              align="left"
              style={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "48px",
                color: "#33343D",
              }}
              variant="h5"
            >
              Login
            </Typography>
            <Typography
              style={{
                fontSize: "13px",
                lineHeight: "39px",
                color: "#565656",
                marginLeft: "0",
                marginRight: "0",
              }}
            >
              Please enter your email and password to continue
            </Typography>
            <form className={classes.form}>
              <input
                type="text"
                id="email"
                label="Email Address"
                name="email"
                required
                placeholder="User Name"
                onChange={handleChange}
                style={{
                  paddingLeft: "7px",
                  width: "100%",
                  height: "36px",
                  border: "1px solid #E7E8F2",
                  boxSizing: "border-box",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "3px",
                }}
              />
              <input
                name="password"
                label="Password"
                type="password"
                id="password"
                required
                placeholder="Password"
                onChange={handleChange}
                style={{
                  paddingLeft: "7px",
                  marginTop: "2em",
                  width: "100%",
                  height: "36px",
                  border: "1px solid #E7E8F2",
                  boxSizing: "border-box",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "3px",
                }}
              />
              <Button
                //type="submit"
                // variant="contained"
                disableRipple
                style={{
                  background: "#0E8050",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.05)",
                  borderRadius: "3px",
                  color: "#ffffff",
                  fontWeight: 600,
                  fontSize: "14px",
                  textTransform: "none",
                  lineHeight: "17px",
                  padding: "10px 20px",
                }}
                onClick={Login}
                onKeyPress={Login}
                className={classes.submit}
              >
                Submit
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleOpen}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <ResetPassword /> */}
            </form>
            <Modal
              aria-labelledby="simple-modal-title"
              open={open}
              onClose={handleClose}
            >
              <div style={modalStyle} className={classes.modalPaper}>
                <h2>Forgot Password</h2>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email "
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  variant="contained"
                  color="primary" /*onClick={handleOpen}*/
                >
                  Reset Password
                </Button>
              </div>
            </Modal>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
