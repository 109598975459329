import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import greenUpwardIcon from "../../assets/greenUpwardIcon.png";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#242F57",
  },
  container: {
    width: "330px",
    border: "1px solid #EAEDF7",
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    padding: "20px 25px",
    margin: "20px",
  },
  pointer: {
    height: "16px",
    width: "16px",
    background: "#FFC800",
    borderRadius: "4px",
    marginRight: "20px",
  },
  progressText: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "0.5px",
    color: "#9390F3",
  },
  progressBar: {
    marginTop: "2em",
    position: "relative",
    width: "100%",
    background: "#EAEDF7",
    borderRadius: "4px",
    height: "16px",
  },
}));

export default function LevelAnalytics(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState();
  const [data, setData] = useState(["I", "II", "III", "IV", "V"]);
  const { name } = props;

  return (
    <div className={classes.container}>
      <div className={classes.title}>{name} Level Analytics</div>
      {data.map((item) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "7em",
            }}
          >
            <p className={classes.listText}>Feature {item}</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={classes.listText} style={{ color: "#04B78A" }}>
                4100
              </div>
              <img
                src={greenUpwardIcon}
                style={{
                  marginLeft: "30px",
                  width: "12px",
                  height: "8px",
                }}
                alt=""
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
